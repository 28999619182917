/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import { Button, Col, Container, Row, Alert } from 'react-bootstrap';

import { Redirect } from 'react-router-dom';
import MyNavbar from '../../components/MyNavbar';
import { AppContext } from '../../states';

import { FaCheckCircle, FaChevronUp } from 'react-icons/fa';
import emptyGift from '../../assets/images/empty-gift.svg';

import MyFooter from '../../components/MyFooter';
import { AppConfig } from '../../config';
import { Helmet } from 'react-helmet';
import ScrollToTop from 'react-scroll-to-top';
import LoadECard from '../../components/LoadECard';
import Searchbar from './components/Searchbar';
import ViewByGift from './components/ViewByGift';
import ViewByBrand from './components/ViewByBrand';
import TabBarBottom from '../../components/TabBarBottom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import ListingSkeleton from './components/ListingSkeleton';
import ListingSkeletonMobile from './components/ListingSkeletonMobile';
import ListingSkeletonTablet from './components/ListingSkeletonTablet';
import {
  loadCatalog,
  loadFromServer,
} from '../../services/GiftCatalogServices';

const Search = () => {
  const { state, dispatch } = useContext(AppContext);
  const {
    giftItems,
    selectedToWhom,
    selectedCategory,
    customPrice,
    minPrice,
    maxPrice,
    isSortBy,
    viewBy,
    searchText,
  } = state.searchPage;

  // const [isLoading, setIsLoading] = useState(false);
  // const [startLoadGift, setStartLoadGift] = useState(false);
  // const [lastUpdate, setLastUpdate] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  const categoryParam = urlParams.get('category');

  useEffect(() => {
    if (categoryParam) {
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          selectedCategory: [categoryParam],
        },
      });
    }
  }, [categoryParam, dispatch]);

  //save filter info to local storage
  useEffect(() => {
    localStorage.setItem(
      'redeem-search-to-whom',
      JSON.stringify(selectedToWhom)
    );
    localStorage.setItem(
      'redeem-search-category',
      JSON.stringify(selectedCategory)
    );
    localStorage.setItem('redeem-search-sort-by', isSortBy);
    localStorage.setItem('redeem-search-view-by', viewBy);
  }, [selectedToWhom, selectedCategory, isSortBy, viewBy]);

  useEffect(() => {
    // console.log('effect get gifts');
    let tmpFilterCount = 0;
    if (state.gifts && state.ecard && state.ecard.balance) {
      let itemsResult = state.gifts;
      if (customPrice && customPrice.length === 2) {
        let minPrice = parseFloat(customPrice[0]);
        let maxPrice = parseFloat(customPrice[1]);
        if (!isNaN(minPrice) && !isNaN(maxPrice)) {
          itemsResult = itemsResult.filter(
            (gift) =>
              parseFloat(gift['min-price']) >= minPrice &&
              parseFloat(gift['min-price']) <= maxPrice
          );
          tmpFilterCount++;
        }
      }
      if (selectedToWhom.length > 0) {
        itemsResult = itemsResult.filter((gift) => {
          let found = false;
          gift.tags_v2.forEach((t) => {
            if (selectedToWhom.includes(t.id)) {
              found = true;
            }
          });
          return found;
        });
        tmpFilterCount += selectedToWhom.length;
      }
      if (selectedCategory.length > 0) {
        // console.log('selected category', selectedKategori, itemsResult);
        itemsResult = itemsResult.filter((gift) => {
          let found = false;
          if (viewBy === 'brand') {
            // found = selectedKategori.includes(gift.merchant.category.value);
            gift.merchant.tags.forEach((t) => {
              if (selectedCategory.includes(t.value)) {
                found = true;
              }
            });
          } else {
            gift.tags_v2.forEach((t) => {
              if (selectedCategory.includes(t.id)) {
                found = true;
              }
            });
          }
          return found;
        });
        tmpFilterCount += selectedCategory.length;
        // console.log('filtered', itemsResult);
      }

      if (searchText) {
        itemsResult = itemsResult.filter((item) => {
          if (viewBy === 'brand') {
            return (
              item.merchant &&
              item.merchant.name &&
              item.merchant.name
                .toLowerCase()
                .indexOf(searchText.toLowerCase().trim()) > -1
            );
          } else {
            if (
              item.name &&
              item.name.toLowerCase().indexOf(searchText.toLowerCase().trim()) >
                -1
            ) {
              return true;
            }
            if (
              item['popular-name'] &&
              item['popular-name']
                .toLowerCase()
                .indexOf(searchText.toLowerCase().trim()) > -1
            ) {
              return true;
            }
            if (
              item.merchant &&
              item.merchant.name &&
              item.merchant.name
                .toLowerCase()
                .indexOf(searchText.toLowerCase().trim()) > -1
            ) {
              return true;
            }
            if (item.tags) {
              let found = false;
              item.tags_v2.forEach((tgs) => {
                if (
                  tgs.name &&
                  tgs.name
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase().trim()) > -1
                ) {
                  found = true;
                }
              });
              if (found) return true;
            }
            return false;
          }
        });
      }

      if (isSortBy === 'price-a') {
        itemsResult = itemsResult.sort((a, b) => {
          return parseFloat(a['min-price']) - parseFloat(b['min-price']);
        });
      } else if (isSortBy === 'price-d') {
        itemsResult = itemsResult.sort((a, b) => {
          return parseFloat(b['min-price']) - parseFloat(a['min-price']);
        });
      } else if (isSortBy === 'name-a') {
        itemsResult = itemsResult.sort((a, b) => {
          if (a.merchant.name < b.merchant.name) return -1;
          if (a.merchant.name > b.merchant.name) return 1;
          return 0;
        });
      } else if (isSortBy === 'name-d') {
        itemsResult = itemsResult.sort((a, b) => {
          if (a.merchant.name > b.merchant.name) return -1;
          if (a.merchant.name < b.merchant.name) return 1;
          return 0;
        });
      }

      const topMerchants = ['MCZ1W3M121', 'MCPKC3WUHC', 'MCXZTVHW6X'];
      // itemsResult = itemsResult.sort((a, b) => {
      //   if (
      //     topMerchants.includes(a.merchant.id) &&
      //     !topMerchants.includes(b.merchant.id)
      //   )
      //     return -1;
      //   return 0;
      // });
      let sortedResult = [];
      topMerchants.forEach((m) => {
        sortedResult = [
          ...sortedResult,
          ...itemsResult.filter((i) => i.merchant.id === m),
        ];
      });
      sortedResult = [
        ...sortedResult,
        ...itemsResult.filter((i) => !topMerchants.includes(i.merchant.id)),
      ];
      itemsResult = sortedResult;

      let allMerchants = getMerchantsFromGifts(itemsResult);
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          merchants: allMerchants,
          giftItems: itemsResult,
          filterCount: tmpFilterCount,
        },
      });
    }
  }, [
    customPrice,
    dispatch,
    isSortBy,
    searchText,
    selectedCategory,
    selectedToWhom,
    state.ecard,
    state.gifts,
    viewBy,
  ]);

  useEffect(() => {
    //get lowest and highest price
    if (state.gifts) {
      let arrPrice = state.gifts.map((gift) => parseFloat(gift['min-price']));
      let min = Math.min(...arrPrice);
      let max = Math.max(...arrPrice);
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          minPrice: min,
          maxPrice: Math.ceil(max),
          customPrice: [min, max],
        },
      });
    }
  }, [dispatch, state.gifts]);

  // const SaveSearchResult = async (corporateId, gifts) => {
  //   gifts = gifts.map((i) => ({ ...i, _id: i.id }));
  //   try {
  //     let db = new PouchDB('redemption-gift-result-' + corporateId);
  //     await db.bulkDocs(gifts);
  //   } catch {
  //     // error save search result
  //   }
  // };

  // const GetSearchResult = async (corporateId) => {
  //   try {
  //     let db = new PouchDB('redemption-gift-result-' + corporateId);
  //     let allDocs = await db.allDocs({ include_docs: true });
  //     return allDocs.rows.map((i) => i.doc);
  //   } catch {
  //     return [];
  //   }
  // };

  const getMerchantsFromGifts = (arrGifts) => {
    return arrGifts
      .map((item) => item.merchant)
      .reduce((unique, item) => {
        let allMerchantId = unique.map((i) => i.id);
        return allMerchantId.includes(item.id) ? unique : [...unique, item];
      }, []);
  };

  useEffect(() => {
    if (
      state.ecard &&
      (state.giftsStatus === 'pending' || state.giftsStatus === 'reload')
    ) {
      // if (state.ecard && state.ecard.company && state.ecard.company.id) {
      // setStartLoadGift(true);
      loadCatalog({ state, dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ecard, state.giftsStatus]);

  // const loadFromServer = async () => {
  //   dispatch({
  //     type: 'SET_GIFTS',
  //     data: [],
  //   });
  //   //load from server
  //   try {
  //     let resp = await loadGifts(
  //       state.ecard.company && state.ecard.company.id
  //         ? state.ecard.company.id
  //         : '',
  //       0,
  //       8,
  //       state.ecard.is_gift_box == 1 ? state.ecard.vouchertypeid : null
  //     );
  //     if (resp.status === 1) {
  //       // console.log('response version 3', resp);

  //       // record category name on localstorage
  //       let tagsProductCategory = resp.data.tags.products_restructured
  //         .filter((i) => i.id === 'gift-category')[0]
  //         .tags.filter((i) => i.search_filter === '1')
  //         .map((i) => ({
  //           id: i.id,
  //           name: i.name,
  //           items: i.tags ? i.tags.map((t) => t.id) : [],
  //         }));
  //       localStorage.setItem(
  //         'tags-product-category',
  //         JSON.stringify(tagsProductCategory)
  //       );
  //       let tagsProductType = resp.data.tags.products_restructured
  //         .filter((i) => i.id === 'to-whom')[0]
  //         .tags.filter((i) => i.search_filter === '1')
  //         .map((i) => ({
  //           id: i.id,
  //           name: i.name,
  //           items: i.tags ? i.tags.map((t) => t.id) : [],
  //         }));
  //       localStorage.setItem(
  //         'tags-product-to-whom',
  //         JSON.stringify(tagsProductType)
  //       );
  //       let tagsMerchantCategory = Object.keys(
  //         resp.data.tags.merchants.category.items
  //       ).map((k) => {
  //         let item = resp.data.tags.merchants.category.items[k];
  //         return { id: k, name: item.extrasecond || item.text };
  //       });
  //       localStorage.setItem(
  //         'tags-merchant-category',
  //         JSON.stringify(tagsMerchantCategory)
  //       );

  //       // show gift only lower than balance
  //       let allMerchantTags = {};
  //       Object.keys(resp.data.tags.merchants).forEach((k) => {
  //         allMerchantTags = {
  //           ...allMerchantTags,
  //           ...resp.data.tags.merchants[k].items,
  //         };
  //       });
  //       let resGifts = resp.data.products.map((gift) => {
  //         let primary_delivery = 'email';
  //         let primary_extrafirst = 0;
  //         // check have email
  //         let haveEmail = false;
  //         for (let dy of gift.dymethods) {
  //           if (dy.value === 'email') {
  //             haveEmail = true;
  //           }
  //         }
  //         // if not have email
  //         if (!haveEmail) {
  //           for (let dy of gift.dymethods) {
  //             if (dy.value === 'mail') {
  //               primary_delivery = 'mail';
  //               // primary_extrafirst = dy.extrafirst
  //               break;
  //             } else if (
  //               dy.value === 'courier' &&
  //               primary_delivery === 'email'
  //             ) {
  //               primary_delivery = 'courier';
  //               // primary_extrafirst = dy.extrafirst
  //             }
  //           }
  //         }

  //         return {
  //           ...gift,
  //           primary_delivery,
  //           primary_extrafirst,
  //           tags_v2: gift.tags_v2
  //             .map((t) => resp.data.tags.products[t])
  //             .filter((t) => t),
  //           merchant: {
  //             ...resp.data.merchants[gift.merchant_id],
  //             tags: resp.data.merchants[gift.merchant_id].tags
  //               .map((t) => allMerchantTags[t])
  //               .filter((t) => t),
  //           },
  //         };
  //       });

  //       //remove giftano card  and capitaland
  //       resGifts = resGifts.filter(
  //         (item) =>
  //           item.merchant.id !== 'MCMDYGDWF9' &&
  //           item.merchant.id !== 'MCEFP9A7T3' &&
  //           item.self_fulfillment != 1
  //       );
  //       dispatch({
  //         type: 'SET_GIFTS',
  //         data: resGifts,
  //       });

  //       localStorage.setItem(
  //         'catalog-last-update',
  //         Math.floor(Date.now() / 1000)
  //       );

  //       //save to local db
  //       await SaveSearchResult(state.ecard.company.id, resGifts);
  //     } else {
  //       toast(resp.message || ErrorMessages.error_get_gifts, {
  //         type: 'warning',
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     toast(ErrorMessages.error_get_gifts, { type: 'error' });
  //   }
  // };

  // useEffect(() => {
  // const loadData = async () => {
  //   // if (state.gifts) {
  //   //   // console.log('already loaded, ')
  //   //   return;
  //   // }
  //   setStartLoadGift(false);
  //   setIsLoading(true);
  //   if (state.ecard) {
  //     let lastUpdateCatalog = parseInt(
  //       localStorage.getItem('catalog-last-update')
  //     );
  //     if (!lastUpdateCatalog || !lastUpdate) {
  //       await loadFromServer();
  //     } else {
  //       if (lastUpdate > lastUpdateCatalog) {
  //         await loadFromServer();
  //       } else {
  //         let savedGifts = await GetSearchResult(state.ecard.company.id);
  //         // console.log('prev saved gifts', savedGifts);
  //         if (savedGifts.length === 0) {
  //           await loadFromServer();
  //         } else {
  //           dispatch({
  //             type: 'SET_GIFTS',
  //             data: savedGifts,
  //           });
  //         }
  //       }
  //     }
  //   }
  //   setIsLoading(false);
  // };

  // if (startLoadGift && lastUpdate !== undefined) {
  //   loadCatalog();
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startLoadGift, lastUpdate]);

  // useEffect(() => {
  //   if (state.ecard && state.ecard.company && lastUpdate === undefined) {
  //     // get info last update
  //     GetCatalogLastUpdate(state.ecard.company.id || '')
  //       .then((lastResp) => {
  //         console.log('last update catalog', lastResp);
  //         setLastUpdate(lastResp && lastResp.unix ? lastResp.unix : null);
  //       })
  //       .catch((err) => {
  //         console.log('error last update', err);
  //         setLastUpdate(null);
  //       });
  //   }
  // }, [lastUpdate, state.ecard]);

  // map merchant from gift items
  useEffect(() => {
    let allMerchants = getMerchantsFromGifts(giftItems);
    dispatch({
      type: 'SEARCH_PAGE',
      payload: {
        merchants: allMerchants,
      },
    });
  }, [dispatch, giftItems]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (state.giftsStatus === 'success') {
      dispatch({ type: 'SET_GIFTS_STATUS', data: 'reload' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  if (hidePrice && (isSortBy === 'price-a' || isSortBy === 'price-d')) {
    dispatch({
      type: 'SEARCH_PAGE',
      payload: {
        isSortBy: 'name-a',
      },
    });
  }

  const handleRefresh = async () => {
    await loadFromServer({ state, dispatch });
  };

  if (!state.whitelabel || !state.gcardinfo) return <Redirect to='/' />;

  // console.log('search state', state.gifts, state.giftsStatus);
  return (
    <div className='page-search'>
      <Helmet>
        <title>Search Gift - {AppConfig.title}</title>
      </Helmet>

      <LoadECard>
        {state.ecard && (
          <div className='rectangle-header'>
            <Container>
              <MyNavbar />
              <ScrollToTop
                style={{
                  borderRadius: '20px',
                  bottom: window.innerWidth < 1024 ? '100px' : '40px',
                  right: window.innerWidth < 1024 ? '20px' : '40px',
                  boxShadow: '0 2px 6px 0 rgb(0 0 0 / 40%)',
                  padding: '0',
                }}
                smooth
                component={<FaChevronUp style={{ height: '1.5em' }} />}
              />
              <Searchbar />

              <Row className='page-search__list'>
                {state.giftsStatus === 'success' ? (
                  <PullToRefresh onRefresh={handleRefresh}>
                    <Col md='12' className='page-search__container'>
                      {hidePrice && parseFloat(state.ecard.total_usage) > 0 && (
                        <Row className='mb-2'>
                          <Col>
                            <Alert variant='success'>
                              <FaCheckCircle className='mr-2' />{' '}
                              <strong>Fully redeemed:</strong> Looks like you
                              have fully redeemed all your gifts.
                            </Alert>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        {viewBy === 'brand' ? <ViewByBrand /> : <ViewByGift />}
                      </Row>
                    </Col>
                  </PullToRefresh>
                ) : state.giftsStatus === 'loading' ? (
                  <Col md={12}>
                    <ListingSkeleton
                      className='listing-skeleton'
                      speed={2}
                      width={1200}
                      height={600}
                      viewBox='0 0 1200 600'
                      style={{ width: '100%' }}
                    />
                    <ListingSkeletonMobile
                      className='listing-skeleton-mobile'
                      speed={2}
                      width={'100%'}
                      height={600}
                      viewBox='0 0 400 600'
                      style={{ width: '100%' }}
                    />
                    <ListingSkeletonTablet
                      className='listing-skeleton-tablet'
                      speed={2}
                      width={'100%'}
                      height={800}
                      viewBox='0 0 800 800'
                      style={{ width: '100%' }}
                    />
                  </Col>
                ) : null}
              </Row>

              {state.gifts &&
                giftItems.length === 0 &&
                state.gifts.length > 0 && (
                  <div className='d-flex flex-column align-items-center my-3 image-not-found'>
                    <img
                      src={emptyGift}
                      width='200px'
                      alt='empty gifts'
                      title='empty gifts'
                    />
                    <span className='text-giftano my-3 text-uppercase'>
                      Gift not found
                    </span>
                    <Button
                      className='btn btn-not-found'
                      onClick={() => {
                        // setCustomPrice([minPrice, maxPrice])
                        dispatch({
                          type: 'SEARCH_PAGE',
                          payload: {
                            customPrice: [minPrice, maxPrice],
                            searchText: '',
                          },
                        });
                      }}
                    >
                      show all
                    </Button>
                  </div>
                )}

              {/* </InfiniteScroll> */}
            </Container>
            <MyFooter />
            <TabBarBottom />
          </div>
        )}
      </LoadECard>
    </div>
  );
};

export default Search;
