import React, { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import LoadECard from '../../components/LoadECard';
import MyNavbar from '../../components/MyNavbar';
import TabBarBottom from '../../components/TabBarBottom';
import { AppContext } from '../../states';
import About from '../get-started/components/About';

const AboutGiftanoCardPage = () => {
  const { state } = useContext(AppContext);
  const history = useHistory();
  return (
    <div className='about-giftano-card-page'>
      <LoadECard>
        {state.ecard && (
          <>
            <MyNavbar />
            <Container className='pt-4'>
              <Button
                variant='link'
                style={{
                  color: 'var(--gf-dark-gray-300)',
                  marginBottom: '20px',
                }}
                size={'sm'}
                onClick={() => history.push('/info')}
              >
                <FaArrowLeft /> Back
              </Button>
              <About />
            </Container>
            <TabBarBottom />
          </>
        )}
      </LoadECard>
    </div>
  );
};

export default AboutGiftanoCardPage;
