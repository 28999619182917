/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { AppContext } from '../../../states';
import Clamp from 'react-multiline-clamp';
import { useHistory } from 'react-router-dom';
import { CurrencyFormat } from '../../../helper/CurrencyFormat';

const MerchantProducts = ({ merchantId }) => {
  const {
    state,
    // dispatch
  } = useContext(AppContext);
  const gifts = state.gifts
    ? state.gifts.filter((g) => g.merchant && g.merchant.id === merchantId)
    : [];
  const history = useHistory();
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;

  return (
    <Row className='merchant-page-listing'>
      <Col md='12'>
        <Card className='voucher-card'>
          <Card.Title className='desc font-weight-bold card-title h5 text-center'>
            Gift(s)
          </Card.Title>
          <ListGroup className='voucher-list'>
            {gifts.map((gift, idx) => {
              return (
                <ListGroup.Item key={'gift_item_' + idx} className='my-3'>
                  <Row noGutters>
                    <Col md={3} sm={4} xs={4}>
                      <img
                        className='img-fluid'
                        src={gift.file}
                        alt={gift.name}
                      />
                    </Col>
                    <Col
                      md={9}
                      sm={8}
                      xs={8}
                      className='px-2 merchant-listing-actions'
                    >
                      <Row>
                        <Col md={7} sm={12}>
                          <h4 className='voucher-name'>{gift.name}</h4>
                          <Clamp withTooltip lines={3}>
                            <p
                              className='voucher-desc d-none d-md-block'
                              dangerouslySetInnerHTML={{
                                __html:
                                  gift.description &&
                                  gift.description
                                    .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                                    .replace(/\n/g, '<br/>'),
                              }}
                            ></p>
                          </Clamp>
                        </Col>
                        <Col md={5} sm={12}>
                          <Row className='listing-cta-container'>
                            <Col
                              md={12}
                              sm={5}
                              xs={5}
                              className='d-flex justify-content-start justify-content-md-center align-items-end  align-items-lg-center '
                            >
                              {!hidePrice && (
                                <h5 className='text-center text-orange listing-price'>
                                  {gift.is_cash_voucher == 1 ? (
                                    <>
                                      From{' '}
                                      <CurrencyFormat
                                        value={gift['min-price']}
                                      />
                                    </>
                                  ) : (
                                    <CurrencyFormat value={gift.price} />
                                  )}
                                </h5>
                              )}
                            </Col>
                            <Col
                              md={12}
                              sm={7}
                              xs={7}
                              className='text-center listing-cta'
                            >
                              <Button
                                onClick={() => {
                                  history.push('/gift/' + gift.id);
                                }}
                                className={'btn-list'}
                              >
                                View Product
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default MerchantProducts;
