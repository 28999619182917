import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalHowToRedeem = ({ show, toggle }) => {
  const history = useHistory();
  return (
    <Modal show={show} onHide={toggle} className='modal-how-to-redeem' centered>
      <div className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'>
        <h4
          style={{
            // marginBottom: '12px',
            fontWeight: 'bold',
            fontSize: '21px',
            marginTop: '1em',
            width: '100%',
          }}
        >
          How to redeem your gift
        </h4>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{
            fontSize: '20px',
            marginRight: '-25px',
            marginTop: '-10px',
          }}
          onClick={toggle}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      <Modal.Body>
        <p class='description my-4'>
          Browse through the amazing gifts inside this gift box &amp; select your favourite gift!
        </p>
        <img
          className='img-fluid'
          src={'https://static-cdn.giftano.com/assets/how-to-redeem.png'}
          alt='How to redeem'
        />
        <p class='description my-4'>
          Redeem your experience gift at the merchant establishment or choose to have your physical
          gift item delivered to you.
        </p>
        <Button
          onClick={() => {
            history.push('/how-to-redeem');
          }}
          className='btn-modal-cta mr-2'
        >
          Learn More
        </Button>
        <Button variant='outline-primary' className='btn-modal-cta' onClick={toggle}>
          Ok
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalHowToRedeem;
