import React from 'react';
import { Col, Modal, Row, Button } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalPin = (props) => {
  return (
    <Modal
      centered
      show={props.show}
      onHide={props.toggle}
      className='modal-pin'
    >
      <div className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'>
        <h4
          style={{
            // marginBottom: '12px',
            fontWeight: 'bold',
            fontSize: '21px',
            marginTop: '1em',
            width: '100%',
          }}
        >
          Scan and access your Giftano Card from your mobile device!
        </h4>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{
            fontSize: '20px',
            marginRight: '-25px',
            marginTop: '-10px',
          }}
          onClick={props.toggle}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      <Modal.Body className='text-center'>
        <div className='qr'>
          {props.url && <QRCode value={props.url} size={200} />}
        </div>
        <div className='box-pin'>
          <Row as={'dl'}>
            <Col as={'dt'} sm='6'>
              Giftano Card Number
            </Col>
            <Col as={'dd'} sm='6'>
              {props.number.substr(0, 4) +
                ' ' +
                props.number.substr(4, 4) +
                ' ' +
                props.number.substr(8, 4) +
                ' ' +
                props.number.substr(12)}
            </Col>
            {props.pin && (
              <React.Fragment>
                <Col as={'dt'} sm='6'>
                  Giftano Card Pin
                </Col>
                <Col as={'dd'} sm='6'>
                  {props.pin}
                </Col>
              </React.Fragment>
            )}
          </Row>
        </div>
        <Button className='mt-3' color='primary' onClick={props.toggle}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPin;
