/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../states';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { PostPublic } from '../../../services/Connection';
import ModalPin from './ModalPin';
import { FaSpinner } from 'react-icons/fa';
import { ImCopy } from 'react-icons/im';
import QRCode from 'react-qr-code';
import { Link, useHistory } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import { MdMailOutline } from 'react-icons/md';
// import Clamp from 'react-multiline-clamp'
import coolicon from '../../../assets/images/coolicon.png';
import { toast } from 'react-toastify';
import {
  CurrencyFormat,
  getFormattedCurrency,
} from '../../../helper/CurrencyFormat';

const Hero = () => {
  const [modalPin, setModalPin] = useState({ show: false });
  const modalPinToggle = () => setModalPin({ show: false });
  const [isFetchingPin, setIsFetchingPin] = useState(false);
  const { state } = useContext(AppContext);
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  const expiredState = state.ecard && state.ecard.status === 'expired';
  const fullyRedeemed = hidePrice && parseFloat(state.ecard.total_usage) > 0;
  const {
    number,
    merchantname,
    balance,
    expirydate,
    firstname,
    lastname,
    url,
  } = state.ecard || {};
  const titleWelcomeMessage =
    state.whitelabel &&
    state.whitelabel.settings &&
    state.whitelabel.settings['gift-box-title-welcome-message']
      ? state.whitelabel.settings['gift-box-title-welcome-message'].value
      : '';
  const history = useHistory();

  return (
    <>
      {modalPin.show && (
        <ModalPin
          show={true}
          toggle={modalPinToggle}
          url={modalPin.url}
          number={modalPin.number}
          pin={modalPin.pin}
        />
      )}
      <Container
        className={
          'container-gf-hr ' +
          (state.ecard.is_gift_box == 0 ? 'container-gf-hr2 ' : '') +
          (expiredState ? 'exp-gf' : '')
        }
      >
        <Row>
          <Col className='hero-image-container' md='7' sm='6'>
            <div className='hero-title d-block d-md-none'>
              <h1 className='title '>
                <span
                  style={{ display: 'block', width: '100%', fontSize: '29px' }}
                >
                  Greetings,
                </span>
                {state.ecard.is_gift_box == 1
                  ? ` You’ve received a ${state.ecard.product_name}, explore and enjoy!`
                  : ' You’ve received a Giftano Gift Card, explore and enjoy!'}
              </h1>
            </div>

            <div className='hero'>
              {state.ecard.is_gift_box == 1 ? (
                <>
                  {state.ecard.status === 'expired' && (
                    // <strong className='text-danger ml-2'>EXPIRED</strong>
                    <div className='expired-label'>
                      <img
                        width='21'
                        height='21'
                        style={{ marginRight: '4px' }}
                        src={coolicon}
                        alt='expired-icon'
                      />
                      EXPIRED
                    </div>
                  )}
                  <img
                    className='hero-image d-none d-sm-block'
                    src={
                      state.whitelabel.settings['gift-box-homepage-banner']
                        .value
                    }
                    alt='Giftano Gift Box'
                  />
                  <img
                    className='hero-image d-block d-sm-none'
                    src={
                      state.whitelabel &&
                      state.whitelabel.settings &&
                      state.whitelabel.settings[
                        'gift-box-homepage-banner-mobile'
                      ]
                        ? state.whitelabel.settings[
                            'gift-box-homepage-banner-mobile'
                          ].value
                        : ''
                    }
                    alt='Giftano Gift Box'
                  />
                </>
              ) : (
                <>
                  <img
                    className='hero-image d-none d-md-block'
                    src={
                      state.whitelabel.settings['giftano-card-homepage-banner']
                        .value
                    }
                    alt='Giftano Card'
                  />
                  <img
                    className='hero-image d-none d-sm-block d-md-none'
                    src={
                      state.whitelabel.settings[
                        'giftano-card-homepage-banner-tablet'
                      ].value
                    }
                    alt='Giftano Card'
                  />
                  <img
                    className='hero-image d-block d-sm-none'
                    src={
                      state.whitelabel.settings[
                        'giftano-card-homepage-banner-mobile'
                      ].value
                    }
                    alt='Giftano Card'
                  />
                </>
              )}
              <img
                className='plane'
                src='https://static-cdn.giftano.com/assets/gift-card/plane.png'
                alt='Giftano Card'
              />
              {state.ecard.is_gift_box == 1 ? (
                <div className='gift-card gift-box'>
                  <img
                    src={
                      state.whitelabel.settings['gift-box-product-icon']
                        ? state.whitelabel.settings['gift-box-product-icon']
                            .value
                        : ''
                    }
                    alt='Gift Box Icon'
                  />
                </div>
              ) : (
                <div
                  className='gift-card'
                  style={{
                    backgroundImage:
                      'url(' +
                      (state.whitelabel.settings['giftano-card-background']
                        ? state.whitelabel.settings['giftano-card-background']
                            .value
                        : 'https://giftano.com/img/giftano-card-email-output-email.png') +
                      ')',
                  }}
                >
                  {!hidePrice && !expiredState && (
                    <span
                      className='member-balance'
                      style={
                        getFormattedCurrency({
                          value: balance,
                        }).length >= 10
                          ? {
                              left: '19px',
                            }
                          : {}
                      }
                    >
                      {getFormattedCurrency({ value: balance }).length >=
                        10 && <br />}
                      <CurrencyFormat value={balance} />
                    </span>
                  )}
                  <span className='member-name'>
                    {merchantname}
                    <br />
                    {firstname} {lastname && lastname}
                  </span>
                  <span className='member-expired'>
                    Valid until{' '}
                    <Moment date={expirydate} format='DD-MMM-YYYY' />
                    {state.ecard.status === 'expired' && (
                      // <strong className='text-danger ml-2'>EXPIRED</strong>
                      <div className='expired-label' style={{ width: '160%' }}>
                        <img
                          width='21'
                          height='21'
                          style={{ marginRight: '4px' }}
                          src={coolicon}
                          alt='expired-icon'
                        />
                        EXPIRED
                      </div>
                    )}
                  </span>
                  <span className='label-not-for-resale'>NOT FOR RESALE</span>
                </div>
              )}
            </div>
          </Col>
          <Col className='hero-text-container' md='5' sm='6'>
            <div className='hero-title'>
              {state.ecard.is_gift_box == 1 ? (
                <>
                  <img
                    className='image-title-gift-box'
                    src={
                      state.whitelabel.settings['gift-box-product-icon']
                        ? state.whitelabel.settings['gift-box-product-icon']
                            .value
                        : ''
                    }
                    alt={'Gift Box Icon'}
                  />
                  <h5>YOU HAVE RECEIVED</h5>
                  <h1 className='title title-gift-box'>{state.ecard.value}</h1>
                </>
              ) : (
                <h1 className='title d-none d-md-block'>
                  Welcome to your <br /> <span>Giftano Gift Card</span>
                </h1>
              )}
              {/* <Clamp
                withTooltip
                withToggle
                lines={2}
                showMoreElement={({toggle})=>(
                  <Button size="sm" variant="link" style={{color: 'var(--gf-primary-300)', padding: '0'}} onClick={toggle}>More</Button>
                )}
                showLessElement={({toggle})=>(
                  <Button size="sm" variant="link" style={{color: 'var(--gf-primary-300)', padding: '0'}} onClick={toggle}>Less</Button>
                )}
                > */}
              <p className='mb-3'>{titleWelcomeMessage}</p>
              {/* </Clamp> */}
              <p className='get-started-label'>
                Click the button below to get started!
              </p>
              <div className='my-3'>
                <div className='mobile-text'>
                  <Button
                    disabled={expiredState || fullyRedeemed}
                    className='btn-get-started'
                    onClick={() => {
                      if (state.ecard.is_gift_box == 1) {
                        let element =
                          document.getElementById('inspiredSection');
                        if (!element) {
                          element = document.getElementById('findYourGift');
                        }
                        if (element) {
                          // element.scrollIntoView({ behavior: 'smooth' });
                          const headerOffset = 95;
                          const elementPosition =
                            element.getBoundingClientRect().top;
                          const offsetPosition =
                            elementPosition + window.pageYOffset - headerOffset;

                          window.scrollTo({
                            top: offsetPosition,
                            behavior: 'smooth',
                          });
                        }
                      } else {
                        history.push('/search');
                      }
                    }}
                  >
                    GET STARTED
                  </Button>
                </div>
                <Button
                  disabled={expiredState || fullyRedeemed}
                  className='btn-get-started desktop'
                  onClick={(e) => {
                    e.currentTarget.blur();
                    if (state.ecard.is_gift_box == 1) {
                      let element = document.getElementById('inspiredSection');
                      if (!element) {
                        element = document.getElementById('findYourGift');
                      }
                      if (element) {
                        // element.scrollIntoView({ behavior: 'smooth' });
                        const headerOffset = 95;
                        const elementPosition =
                          element.getBoundingClientRect().top;
                        const offsetPosition =
                          elementPosition + window.pageYOffset - headerOffset;

                        window.scrollTo({
                          top: offsetPosition,
                          behavior: 'smooth',
                        });
                      }
                    } else {
                      history.push('/search');
                    }
                  }}
                >
                  GET STARTED
                </Button>
                <Link
                  to='/start'
                  className='btn btn-env ml-3'
                  alt='replay animation'
                  title='replay animation'
                >
                  <MdMailOutline size='24px' className='mr-1' />
                </Link>
              </div>
              {state.ecard.is_gift_box == 1 && (
                <p className='validity'>
                  Valid until <Moment date={expirydate} format='DD-MMM-YYYY' />
                </p>
              )}
            </div>
          </Col>
        </Row>

        {/* {state.ecard.is_gift_box == 0 && (
          <div
            className='d-none d-md-flex pt-3 pb-3 justify-content-end'
            style={{
              borderTop: '1px solid var(--gf-gray-200, #ECECED)',
              borderBottom: '1px solid var(--gf-gray-200, #ECECED)',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <div
                style={{ display: 'inline-block', cursor: 'pointer' }}
                onClick={async () => {
                  setIsFetchingPin(true);
                  try {
                    let resp = await PostPublic('gcard/getpin/', {
                      ecard: number,
                      key: state.key,
                    });
                    // console.log('resp', resp);
                    if (resp.status === 1) {
                      setModalPin({
                        show: true,
                        url: url,
                        number: number,
                        pin: resp.data,
                      });
                    } else {
                      setModalPin({
                        show: true,
                        url: url,
                        number: number,
                        pin: '',
                      });
                    }
                  } catch {}
                  setIsFetchingPin(false);
                }}
              >
                {isFetchingPin ? (
                  <div
                    className='d-flex justify-content-center align-items-center'
                    style={{ width: '75px', height: '75px' }}
                  >
                    <FaSpinner className='icon-spin' />
                  </div>
                ) : (
                  <div
                    style={{
                      width: '75px',
                      height: '75px',
                      background: 'red',
                      display: 'inline-block',
                    }}
                  >
                    <QRCode value={url} size={75} />
                  </div>
                )}
              </div>
              <p className='mt-2 text-right' style={{ fontSize: '16px' }}>
                Scan the QR Code to access your <br />
                Giftano Card from your mobile.
              </p>
            </div>
          </div>
        )} */}

        <div
          className={
            'position-absolute d-flex pt-3 pb-3 justify-content-end qrcd-gift ' +
            (state.ecard.is_gift_box == 0 ? 'qrcd-gift2' : '')
          }
        >
          <div
            style={{
              textAlign: 'right',
              marginTop: '22px',
              marginRight: '71px',
            }}
          >
            <div
              style={{ display: 'inline-block', cursor: 'pointer' }}
              onClick={async () => {
                setIsFetchingPin(true);
                try {
                  let resp = await PostPublic('gcard/getpin/', {
                    ecard: number,
                    key: state.key,
                  });
                  // console.log('resp', resp);
                  if (resp.status === 1) {
                    setModalPin({
                      show: true,
                      url: url,
                      number: number,
                      pin: resp.data,
                    });
                  } else {
                    setModalPin({
                      show: true,
                      url: url,
                      number: number,
                      pin: '',
                    });
                  }
                } catch {}
                setIsFetchingPin(false);
              }}
            >
              {isFetchingPin ? (
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ width: '50px', height: '50px' }}
                >
                  <FaSpinner className='icon-spin' />
                </div>
              ) : (
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    background: 'white',
                    display: 'inline-block',
                    padding: '2px',
                    borderRadius: '2px',
                  }}
                >
                  <QRCode value={url} size={46} />
                </div>
              )}
            </div>
            <p
              className='text-right mb-0'
              style={{
                letterSpacing: '.02px',
                fontWeight: '700',
                marginTop: '4px',
                color: '' + (state.ecard.is_gift_box == 1 ? 'white' : 'black'),
              }}
            >
              Scan the QR Code to access your <br />
              Giftano Card from your mobile.
            </p>
            <p
              className='text-right mb-0 qrcd-gift__serial'
              style={{
                fontSize: '12px',
                letterSpacing: '.05px',
                lineHeight: '14px',
                fontWeight: '400',
                marginTop: '8px',
              }}
            >
              Serial Number :<br />
              {state.ecard.number
                ? state.ecard.number.substr(0, 4) +
                  '-' +
                  state.ecard.number.substr(4, 4) +
                  '-' +
                  state.ecard.number.substr(8, 4) +
                  '-' +
                  state.ecard.number.substr(12, 4)
                : '-'}
              <ImCopy
                title='Copy to clipboard'
                className='ml-2 qrcd-gift__clipb'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(state.ecard.number);
                  toast.success('Copy success.');
                }}
              />
            </p>
          </div>
        </div>
      </Container>

      <div
        style={{
          width: '100vw',
          border: '1px solid #E6F4F2',
          display: '' + (state.ecard.is_gift_box == 1 ? 'none' : 'block'),
        }}
        className='d-none d-md-block'
      ></div>
    </>
  );
};

export default Hero;
