import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../states';
import QRCode from 'react-qr-code';
import { PostPublic } from '../../../services/Connection';
import { CurrencyFormat } from '../../../helper/CurrencyFormat';

const GiftanoCard = () => {
  const { state } = useContext(AppContext);
  const [showCard, setShowCard] = useState('front');
  const [pinStatus, setPinStatus] = useState('pending');
  const [pin, setPin] = useState('');
  // console.log(state.ecard);

  const loadPin = useCallback(async () => {
    try {
      let resp = await PostPublic('gcard/getpin/', {
        ecard: state.ecard.number,
        key: state.key,
      });
      // console.log('resp', resp);
      if (resp.status === 1) {
        setPin(resp.data);
        setPinStatus('success');
      } else {
        setPin('');
        setPinStatus('error');
      }
    } catch {}
  }, [state.ecard, state.key]);

  useEffect(() => {
    if (showCard === 'back') {
      if (pinStatus === 'pending') {
        setPinStatus('loading');
        loadPin();
      }
    }
  }, [showCard, pinStatus, loadPin]);

  return (
    <div
      className='giftano-card'
      onClick={() => setShowCard(showCard === 'front' ? 'back' : 'front')}
    >
      {showCard === 'front' ? (
        <>
          <img
            src='https://giftano.com/fls/whitelabel_theme/GS9QUJSM8HA8_giftano-card-background.png'
            className='img-fluid'
            alt={'Giftano Card'}
          />
          <div className='giftano-card-content'>
            <span className='card-type'>
              {state.ecard && state.ecard.is_gift_box === 1
                ? 'Ultimate Gift Card'
                : 'Giftano Card'}
            </span>
            <span className='card-balance'>
              <CurrencyFormat value={state.ecard.balance} />
            </span>
            {state.ecard.firstname && (
              <span className='card-name'>{state.ecard.firstname}</span>
            )}
            <span className='card-expiry-date'>
              Valid until {moment(state.ecard.expirydate).format('DD-MMM-YYYY')}
            </span>
            <span className='card-notes'>NOT FOR RESALE</span>
          </div>
        </>
      ) : (
        <>
          <img
            src='https://giftano.imgix.net/assets/redemption/card-background-flip.png?auto=format'
            className='img-fluid'
            alt={'Giftano Card'}
          />
          <div className='giftano-card-content d-flex justify-content-center align-items-center'>
            <div>
              <div className='qr'>
                <div style={{ border: '5px solid white' }}>
                  <QRCode value={state.ecard.url} size={100} />
                </div>
              </div>
              <div className='serial'>
                {state.ecard && state.ecard.number
                  ? state.ecard.number.substr(0, 4) +
                    '-' +
                    state.ecard.number.substr(4, 4) +
                    '-' +
                    state.ecard.number.substr(8, 4) +
                    '-' +
                    state.ecard.number.substr(12, 4)
                  : ''}
              </div>
              {pin && <div className='pin'>PIN : {pin}</div>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GiftanoCard;
