import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../../states';
import ProductPictures from './ProductPictures';
import HowGiftWork from './HowGiftWork';

const ProductInfo = () => {
  const { state, dispatch } = useContext(AppContext);
  const { selectedGift } = state.merchantPage;

  if (!selectedGift) return null;

  // console.log('how it work', state.strapiData);
  return (
    <Row className='merchant-product__info'>
      <Col md='12' xs='12' lg='6'>
        <ProductPictures gift={selectedGift} isMulti={true} />
      </Col>
      <Col md='12' xs='12' lg='6' className='pl-3 text-left'>
        <h4
          className={
            'merchant-name font-weight-bold text-uppercase ' +
            (selectedGift.name && selectedGift.name.length > 60
              ? 'merchant-name-long'
              : '')
          }
        >
          {selectedGift.name}
        </h4>

        <p
          className='merchant-desc'
          dangerouslySetInnerHTML={{
            __html:
              selectedGift.description &&
              selectedGift.description
                .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                .replace(/\n/g, '<br/>'),
          }}
        ></p>

        <HowGiftWork gift={selectedGift} />

        {selectedGift.tnc && (
          <div>
            <Button
              size='sm'
              variant='link'
              className='links'
              onClick={() => {
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    showModalTnc: true,
                  },
                });
              }}
            >
              Read Gift Terms &amp; Conditions
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ProductInfo;
