import React, { useContext } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import './ModalVoucher.scss';

import { AppContext } from '../states';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalVoucherDesc = () => {
  const { state, dispatch } = useContext(AppContext);
  const { modalVoucherDesc } = state;
  const toggle = () => dispatch({ type: 'SET_MODAL_VOUCHER_DESC' });

  return (
    <Modal
      centered
      show={modalVoucherDesc.show}
      onHide={toggle}
      className='modal-terms'
    >
      <div
        className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'
        style={{ borderBottom: '1px solid #ECECED' }}
      >
        <h5
          className='modal-title'
          style={{
            fontSize: '21px',
            fontWeight: '700',
            color: '#000000',
            marginBottom: '0',
            marginTop: '1em',
          }}
        >
          <h4
            style={{
              marginBottom: '12px',
              fontWeight: 'bold',
              fontSize: '21px',
            }}
          >
            {modalVoucherDesc.voucher.name}
          </h4>
          {modalVoucherDesc.voucher && modalVoucherDesc.voucher.merchant && (
            <div style={{ fontSize: '16px' }} className='terms-merchant'>
              {modalVoucherDesc.voucher.merchant.name}
            </div>
          )}
        </h5>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{ fontSize: '20px', marginRight: '-25px', marginTop: '-10px' }}
          onClick={toggle}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      {/* <Modal.Header closeButton>
        <Modal.Title>
          <h4
            style={{
              marginBottom: '12px',
              marginTop: '1em',
              fontWeight: 'bold',
              fontSize: '21px',
            }}
          >
            {modalVoucherDesc.voucher.name}
          </h4>
          {modalVoucherDesc.voucher && modalVoucherDesc.voucher.merchant && (
            <div style={{ fontSize: '16px' }} className='terms-merchant'>
              {modalVoucherDesc.voucher.merchant.name}
            </div>
          )}
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <Row>
          <Col className='text-center pt-4' md='12'>
            {modalVoucherDesc.voucher.merchant && (
              <img
                src={
                  modalVoucherDesc.voucher.file ||
                  modalVoucherDesc.voucher.image ||
                  modalVoucherDesc.voucher.merchant.logo
                }
                style={{ maxWidth: '200px', borderRadius: '8px' }}
                alt='Logo'
              />
            )}
          </Col>
          <Col md='12'>
            <div
              style={{ marginTop: '1.2em', marginBottom: '1.2em' }}
              dangerouslySetInnerHTML={{
                __html:
                  modalVoucherDesc.voucher.description &&
                  modalVoucherDesc.voucher.description
                    .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                    .replace(/\n/g, '<br/>'),
              }}
            ></div>
            <h5
              style={{
                marginTop: '1.5em',
                marginBottom: '12px',
                fontWeight: 'bold',
              }}
            >
              Terms &amp; Condition
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html: modalVoucherDesc.voucher.tnc,
              }}
            ></div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVoucherDesc;
