/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import Clamp from 'react-multiline-clamp';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalGift from '../../../components/ModalGift';
import MyImg from '../../../components/MyImg';
import { loadGifts } from '../../../services/LegacyService';
import { AppContext } from '../../../states';
import ModalPin from './ModalPin';
import CurrencySymbol from '../../../components/CurrencySymbol';

const GetInspired = () => {
  const [searchText, setSearchText] = useState('');
  const [modalPin, setModalPin] = useState({ show: false });
  const modalPinToggle = () => setModalPin({ show: false });
  const { state, dispatch } = useContext(AppContext);
  const { inspiredData } = state.getStartedPage;
  const [modalGiftShow, setModalGiftShow] = useState(false);
  const toggleModalGift = () => setModalGiftShow(false);
  const [modalGiftSelected, setModalGiftSelected] = useState(0);
  const history = useHistory();
  const expiredState = state.ecard && state.ecard.status === 'expired';
  const fullyRedeemed = parseFloat(state.ecard.total_usage) > 0;

  useEffect(() => {
    const loadInspiredData = async () => {
      let res = await loadGifts(
        state.ecard &&
          state.ecard.company &&
          state.ecard.company &&
          state.ecard.company.id
          ? state.ecard.company.id
          : '',
        state.ecard.is_gift_box == 1 ? 0 : 1,
        8,
        state.ecard.is_gift_box == 1 ? state.ecard.vouchertypeid : null,
        state.ecard.country || 'SG'
      );
      if (res.status === 1) {
        const inspiredData = res.data.products.map((prod) => ({
          ...prod,
          merchant: res.data.merchants[prod.merchant_id],
        }));
        dispatch({
          type: 'GETSTARTED_PAGE',
          payload: { inspiredData: inspiredData },
        });
      } else {
        toast.warning(res.message || 'Oops, something wrong while load gifts');
      }
    };
    if (state.ecard && inspiredData.length === 0) {
      loadInspiredData();
    }
  }, [state.ecard, inspiredData, dispatch]);

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  const gifts = searchText
    ? inspiredData.filter(
        (i) =>
          (i.name &&
            i.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
          (i.merchant &&
            i.merchant.name &&
            i.merchant.name.toLowerCase().indexOf(searchText.toLowerCase()) >
              -1)
      )
    : inspiredData;

  return (
    <div className='inspired accent'>
      {modalPin.show && (
        <ModalPin
          show={true}
          toggle={modalPinToggle}
          url={modalPin.url}
          number={modalPin.number}
          pin={modalPin.pin}
        />
      )}
      {modalGiftShow && (
        <ModalGift
          show={modalGiftShow}
          toggle={toggleModalGift}
          gifts={gifts}
          activeIndex={modalGiftSelected}
          setIndex={setModalGiftSelected}
        />
      )}
      <Container>
        <div id='inspiredSection' className=' mb-2'>
          <div className='text-left'>
            {state.ecard.is_gift_box == 1 ? (
              <h4 className='subtitle'>
                {state.whitelabel.settings['gift-box-homepage-listing-title']
                  ? state.whitelabel.settings['gift-box-homepage-listing-title']
                      .value
                  : 'Choose Your Gift Below'}
              </h4>
            ) : (
              <h4 className='subtitle'>Browse All Gifts</h4>
            )}
            {state.ecard.is_gift_box == 1 ? (
              <p className='desc'>
                {state.whitelabel.settings[
                  'gift-box-homepage-listing-description'
                ]
                  ? state.whitelabel.settings[
                      'gift-box-homepage-listing-description'
                    ].value
                  : 'Browse and select your gift of choice from below. After you have selected your gift you can head to the merchant to redeem it or choose to have it shipped to you for some physical gift products'}
              </p>
            ) : (
              <p className='desc'>
                Explore Singapore's top gifts and select your favourite one!
              </p>
            )}
          </div>
          {/* <div className='text-right d-none d-lg-block'>
            <p className='text-orange font-16-light'>
              Scan the QR Code to access your Ultimate Gift from your mobile.{' '}
            </p>
            <Button
              variant='clear'
              className='d-none d-md-inline-block mb-4'
              style={{ padding: '2px', background: 'white' }}
              onClick={async () => {
                setIsFetchingPin(true);
                try {
                  let resp = await PostPublic('gcard/getpin/', {
                    ecard: number,
                    key: state.key,
                  });
                  // console.log('resp', resp);
                  if (resp.status === 1) {
                    setModalPin({
                      show: true,
                      url: url,
                      number: number,
                      pin: resp.data,
                    });
                  } else {
                    setModalPin({
                      show: true,
                      url: url,
                      number: number,
                      pin: '',
                    });
                  }
                } catch {}
                setIsFetchingPin(false);
              }}
            >
              {isFetchingPin ? (
                <FaSpinner
                  className='icon-spin'
                  style={{ width: '75px', height: '75px' }}
                />
              ) : (
                <QRCode value={url} size={75} />
              )}
            </Button>
          </div> */}
        </div>

        {state.ecard.is_gift_box == 1 &&
          inspiredData &&
          inspiredData.length > 20 && (
            <Row className='mb-4'>
              <Col md='12'>
                <InputGroup>
                  <Form.Control
                    className='input--icon'
                    type='search'
                    placeholder='Search'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  ></Form.Control>
                </InputGroup>
              </Col>
            </Row>
          )}
        <Row className='ismerge'>
          {gifts.map((item, itemIdx) => (
            <Col
              className='product-box-container'
              key={'inpired_item_' + item.id}
              xs='9'
              sm='3'
              md='3'
            >
              <div
                className='product-box'
                onClick={() => {
                  // localStorage.setItem('get-inspired-selected-data', JSON.stringify(item))
                  !expiredState &&
                    !fullyRedeemed &&
                    history.push('/gift/' + item.id);
                  // setModalGiftSelected(itemIdx)
                  // setModalGiftShow(true)
                }}
              >
                {/* <img
                  className="product-image"
                  src={item.file}
                  alt="Product Name"
                /> */}
                <MyImg
                  height='80px'
                  bgcolor={item.is_cash_voucher === 1 ? '#ffffff' : '#02afa1'}
                  spinnervariant='light'
                  alt={item.name}
                  src={
                    item.file
                      .replace(
                        'https://web-sandbox.giftano.io/fls',
                        'https://giftano.imgix.net/fls'
                      )
                      .replace(
                        'https://static-cdn.giftano.com/fls',
                        'https://giftano.imgix.net/fls'
                      ) + '?auto=format&width=400'
                  }
                  rounded
                  fluid
                  className={
                    'product-image ' +
                    (item.is_cash_voucher === 1 ? 'cash-voucher-images' : '')
                  }
                />
                <Clamp withTooltip lines={1}>
                  <p className='product-merchant'>
                    {item.merchant && item.merchant.name}
                  </p>
                </Clamp>
                <h5 className='product-name'>{item.name}</h5>
                <h5 className='product-description'>{item.description}</h5>
                {!hidePrice ? (
                  <p className='product-price'>
                    {item.is_cash_voucher === 1
                      ? 'From ' + CurrencySymbol() + item['min-price']
                      : CurrencySymbol() + item['price']}
                  </p>
                ) : (
                  <div className='p-2 mb-3'>
                    <Button
                      disabled={expiredState || fullyRedeemed}
                      className='product-button'
                    >
                      View Gift
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default GetInspired;
