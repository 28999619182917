import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './ModalVoucher.scss';
import { AppContext } from '../states';
import { replaceString } from '../helper';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalHowTo = () => {
  const { state, dispatch } = useContext(AppContext);
  const { modalVoucherHowTo } = state;
  const toggle = () => dispatch({ type: 'SET_MODAL_VOUCHER_HOW_TO' });
  let howTo = modalVoucherHowTo.voucher['how_to_use'] || '';
  howTo = replaceString(
    howTo,
    'src="//giftano.imgix.net',
    'src="https://giftano.imgix.net'
  );
  howTo = replaceString(
    howTo,
    "src='//giftano.imgix.net",
    "src='https://giftano.imgix.net"
  );
  howTo = replaceString(howTo, '\n', '<br/>');
  //   console.log('log how to', modalVoucherHowTo.voucher);

  return (
    <Modal
      centered
      show={modalVoucherHowTo.show}
      onHide={toggle}
      className='modal-terms'
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>
          <h4
            style={{
              marginBottom: '12px',
              marginTop: '1em',
              fontWeight: 'bold',
              fontSize: '21px',
            }}
          >
            How to use your gift
          </h4>
        </Modal.Title>
      </Modal.Header> */}
      <div
        className='modal-header d-flex justify-content-between align-items-start mx-4 mb-3 p-2'
        style={{ borderBottom: '1px solid #ECECED' }}
      >
        <h5
          className='modal-title'
          style={{
            fontSize: '21px',
            fontWeight: '700',
            color: '#000000',
            marginBottom: '0',
            marginTop: '1em',
          }}
        >
          <h4
            style={{
              marginBottom: '12px',
              fontWeight: 'bold',
              fontSize: '21px',
            }}
          >
            How to use your gift
          </h4>
        </h5>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{ fontSize: '20px', marginRight: '-25px', marginTop: '-10px' }}
          onClick={toggle}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      <Modal.Body>
        {modalVoucherHowTo.voucher && modalVoucherHowTo.voucher.merchant && (
          <p style={{ fontSize: '16px' }} className='terms-merchant'>
            {modalVoucherHowTo.voucher.merchant.name}
          </p>
        )}
        <p className='merchant'>
          {modalVoucherHowTo.voucher && modalVoucherHowTo.voucher.value}
        </p>
        {howTo && (
          <div
            dangerouslySetInnerHTML={{
              __html: howTo,
            }}
          ></div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalHowTo;
