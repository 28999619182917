/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { AppContext } from '../../../states';
import { toast } from 'react-toastify';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CurrencyFormat } from '../../../helper/CurrencyFormat';

const ModalConfirm = ({ confirmAction }) => {
  const { state, dispatch } = useContext(AppContext);
  const { showConfirm, isRedeem, gifts, activeMerchant, activeGift } =
    state.merchantPage;
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  const grand_total =
    gifts.length > 0
      ? gifts
          .map(
            (gift) =>
              gift.quantity * parseFloat(gift.price) +
              (gift.quantity ? parseFloat(gift.primary_extrafirst) : 0)
          )
          .reduce((total, price) => total + price)
      : 0;
  const [doubleConfirm, setDoubleConfirm] = useState(false);
  useEffect(() => {
    setDoubleConfirm(false);
  }, [showConfirm]);

  let maxTopup = 1;
  if (state.ecard && state.ecard.company && state.ecard.company.id) {
    maxTopup = 2;
  }

  return (
    <Modal
      animation={false}
      show={showConfirm}
      onHide={() => {
        dispatch({
          type: 'MERCHANT_PAGE',
          payload: {
            showConfirm: false,
          },
        });
      }}
      centered
      className='modal-confirm'
      backdrop='static'
    >
      {doubleConfirm ? (
        <>
          {/* <Modal.Header closeButton={isRedeem}></Modal.Header> */}
          <div className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'>
            <h4
              style={{
                // marginBottom: '12px',
                fontWeight: 'bold',
                fontSize: '21px',
                marginTop: '1em',
                width: '100%',
              }}
            >
              Are you sure ?
            </h4>
            <Button
              variant={'link'}
              className={'gf-text-primary p-0 btn-modal-close'}
              style={{
                fontSize: '20px',
                marginRight: '-25px',
                marginTop: '-10px',
              }}
              onClick={() => {
                // setShowConfirm(false)
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    showConfirm: false,
                  },
                });
              }}
            >
              <AiOutlineCloseCircle color='var(--primary)' />
            </Button>
          </div>
          <Modal.Body>
            <p className='text-center'>
              Strictly no exchange or refund allowed
            </p>
            <Row>
              <Col>
                <Button
                  variant='neutral'
                  onClick={() => {
                    // setShowConfirm(false)
                    dispatch({
                      type: 'MERCHANT_PAGE',
                      payload: {
                        showConfirm: false,
                      },
                    });
                  }}
                  className='btn-modal-cta btn-invert'
                  style={{ width: '100%' }}
                  disabled={isRedeem || state.ecard.is_test_gift == 1}
                >
                  NO
                </Button>
              </Col>
              <Col>
                <Button
                  block
                  onClick={() => {
                    confirmAction();
                  }}
                  disabled={isRedeem || state.ecard.is_test_gift == 1}
                  className='btn-modal-cta'
                  style={{ width: '100%' }}
                  variant='primary'
                >
                  {isRedeem && <FaSpinner className='mr-2 icon-spin' />}
                  YES
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </>
      ) : (
        <>
          <div className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'>
            <h4
              style={{
                // marginBottom: '12px',
                fontWeight: 'bold',
                fontSize: '21px',
                marginTop: '1em',
                width: '100%',
              }}
            >
              Are you sure want to purchase{' '}
              {state.ecard.hide_price == 1 && gifts[activeGift]
                ? gifts[activeGift].name
                : gifts.filter((g) => g.quantity > 0).length === 1
                ? gifts
                    .filter((g) => g.quantity > 0)
                    .map((g) => g.name)
                    .join()
                : 'this gifts'}
              ?
              {hidePrice ? (
                <p className='description  mt-4 mb-0'>
                  Strictly no exchange or refund allowed.
                </p>
              ) : (
                <p className='description  mt-4 mb-0'>
                  Strictly no exchange or refund allowed once purchased.
                </p>
              )}
            </h4>
            <Button
              variant={'link'}
              className={'gf-text-primary p-0 btn-modal-close'}
              style={{
                fontSize: '20px',
                marginRight: '-25px',
                marginTop: '-10px',
              }}
              onClick={() => {
                // setShowConfirm(false)
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    showConfirm: false,
                  },
                });
              }}
            >
              <AiOutlineCloseCircle color='var(--primary)' />
            </Button>
          </div>

          {!hidePrice && (
            <Modal.Body
              className='m-3'
              style={{
                boxShadow: '0px 4px 20px rgba(92, 92, 92, 0.1)',
                borderRadius: '4px',
              }}
            >
              {gifts
                .filter((gift) => gift.quantity > 0)
                .map((item, idx) => (
                  <Row key={'confirm_item_' + idx} style={{ padding: '15px' }}>
                    <Col md='3' xs='4'>
                      <img
                        src={item.file}
                        alt={item.name}
                        style={{ height: '40px' }}
                      />
                    </Col>
                    <Col xs='6' md='4'>
                      <p
                        style={{
                          margin: '0',
                          color: 'var(--primary)',
                          fontSize: '14px',
                        }}
                      >
                        {activeMerchant && activeMerchant.name}
                      </p>
                      <p style={{ margin: '0', fontSize: '14px' }}>
                        {item.name}
                      </p>
                    </Col>
                    <Col
                      xs='2'
                      md='2'
                      className='text-right d-flex align-items-end'
                      style={{ fontWeight: '700' }}
                    >
                      x {item.quantity}
                    </Col>
                    <Col
                      xs='3'
                      md='3'
                      className='text-right d-none d-md-flex align-items-end '
                      style={{ fontWeight: '700' }}
                    >
                      <CurrencyFormat value={item.quantity * item.price} />
                    </Col>
                  </Row>
                ))}
              {/* <Row>
                <Col className='mt-3 mb-3'>
                  <div className='font-weight-bold d-flex justify-content-between merchant__total-order'>
                    Total Order:
                    <span className='attention-quantity ml-3'>
                      <CurrencySymbol/>
                      {grand_total.toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                </Col>
              </Row> */}
            </Modal.Body>
          )}

          {grand_total > state.ecard.balance && (
            <div className='text-center text-warning mt-4 mb-4'>
              Not enough balance
            </div>
          )}

          {grand_total > state.ecard.balance &&
          state.ecard.total_topup > maxTopup ? null : (
            <Modal.Footer className='mb-1 text-center'>
              <Button
                variant='neutral'
                onClick={() => {
                  // setShowConfirm(false)
                  dispatch({
                    type: 'MERCHANT_PAGE',
                    payload: {
                      showConfirm: false,
                    },
                  });
                }}
                className='btn-modal-cta btn-invert'
                disabled={isRedeem || state.ecard.is_test_gift == 1}
              >
                NO
              </Button>
              <Button
                onClick={() => {
                  if (grand_total > state.ecard.balance) {
                    if (state.ecard.country !== 'SG') {
                      toast.warning(
                        'Topup not available for non Singapore Giftano Card.'
                      );
                    } else {
                      if (
                        !state.ecard.total_topup ||
                        state.ecard.total_topup < maxTopup
                      ) {
                        let topupValue =
                          grand_total - parseFloat(state.ecard.balance);
                        //round up 2 digit
                        topupValue =
                          Math.ceil((topupValue + Number.EPSILON) * 100) / 100;
                        if (topupValue <= 200) {
                          dispatch({
                            type: 'MERCHANT_PAGE',
                            payload: {
                              showConfirm: false,
                            },
                          });
                          dispatch({
                            type: 'SHOW_TOPUP',
                            topupAmount: topupValue,
                            topupAction: () => confirmAction(),
                          });
                        } else {
                          toast.warning(
                            'Your purchase exceeding maximum amount, please change to another product or contact us.'
                          );
                        }
                      } else {
                        toast.warning(
                          'You have exceeding maximum top-up limit.'
                        );
                      }
                    }
                  } else {
                    setDoubleConfirm(true);
                  }
                }}
                disabled={isRedeem || state.ecard.is_test_gift == 1}
                className='btn-modal-cta'
                variant='primary'
              >
                {isRedeem && <FaSpinner className='mr-2 icon-spin' />}
                {grand_total > state.ecard.balance ? 'TOPUP' : 'CONTINUE'}
              </Button>
              {state.ecard.is_test_gift == 1 && (
                <p
                  className='p-2 bg-warning'
                  style={{ borderRadius: '8px', marginTop: '20px' }}
                >
                  Purchase feature is not available for Demo Giftano Card.
                  Please contact our team at{' '}
                  <a
                    href={'mailto:grow@giftano.com'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    grow@giftano.com
                  </a>{' '}
                  for more information.
                </p>
              )}
            </Modal.Footer>
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalConfirm;
