import { Capacitor } from '@capacitor/core';
import React, { useContext } from 'react';
import { Button, Container, ListGroup, ListGroupItem } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import LoadECard from '../../components/LoadECard';
import MyNavbar from '../../components/MyNavbar';
import TabBarBottom from '../../components/TabBarBottom';
import { Connection } from '../../services/Connection';
import { AppContext } from '../../states';
import StayInTouch from '../faqs/components/StayInTouch';
import GiftanoCard from './components/GiftanoCard';

const InfoPage = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const logout = async () => {
    if (state.session_id) {
      await Connection({
        url: 'auth/logout',
        method: 'POST',
        headers: { session_id: state.session_id },
      });
    }

    window.localStorage.clear();
    if (Capacitor.isNativePlatform()) {
      await dispatch({ type: 'SET_LOGOUT' });
      history.push('/');
    } else {
      window.location = 'https://giftano.com';
    }
  };

  // console.log('state', state.ecard);
  return (
    <div className='info-page'>
      <LoadECard>
        {state.ecard && (
          <>
            <MyNavbar />
            <Container>
              {state.ecard.is_gift_box === '1' ? (
                <div className='gift-card gift-box mb-2'>
                  <img
                    src={
                      state.whitelabel.settings['gift-box-product-icon']
                        ? state.whitelabel.settings['gift-box-product-icon']
                            .value
                        : ''
                    }
                    className={'img-fluid'}
                    alt='Gift Box Icon'
                  />
                </div>
              ) : (
                <GiftanoCard />
              )}

              <div className='text-center' style={{ marginBottom: '30px' }}>
                <Button
                  onClick={logout}
                  variant={'light'}
                  style={{
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    borderRadius: '20px',
                    border: '1px solid #C4C4C4',
                  }}
                >
                  Log Out
                </Button>
              </div>

              <ListGroup>
                <NavLink to={'/info/giftano-card'}>
                  <ListGroupItem>
                    {state.ecard
                      ? state.ecard.is_gift_box === 0
                        ? 'About Giftano Card'
                        : 'About Ultimate Gift'
                      : ''}
                  </ListGroupItem>
                </NavLink>
                <NavLink to={'/info/giftano'}>
                  <ListGroupItem>About Giftano</ListGroupItem>
                </NavLink>
                <NavLink to={'/faqs'}>
                  <ListGroupItem>FAQs</ListGroupItem>
                </NavLink>
                <a
                  href={'https://giftano.com/docs/privacy-policy.pdf'}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  <ListGroupItem>Privacy Prolicy</ListGroupItem>
                </a>
                <a
                  href={'https://giftano.com/docs/Terms.pdf'}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  <ListGroupItem>Term of Use</ListGroupItem>
                </a>
                {/* <ListGroupItem onClick={logout}>Logout</ListGroupItem> */}
              </ListGroup>
              <div style={{ paddingBottom: '30px' }}>
                <StayInTouch />
              </div>
            </Container>
            <TabBarBottom />
          </>
        )}
      </LoadECard>
    </div>
  );
};

export default InfoPage;
