import React, { useContext } from 'react';
import {
  Button,
  Col,
  FormCheck,
  FormControl,
  Modal,
  Row,
} from 'react-bootstrap';
import ReactSlider from 'react-slider';
import { AppContext } from '../../../states';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const ModalFilter = ({ show, onHide }) => {
  const { state, dispatch } = useContext(AppContext);
  const {
    customPrice,
    minPrice,
    maxPrice,
    viewBy,
    selectedCategory,
    selectedToWhom,
  } = state.searchPage;
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;

  const handleToWhomChange = (e) => {
    if (selectedToWhom.includes(e.target.value)) {
      //remove
      let newSelected = [...selectedToWhom];
      newSelected = newSelected.filter((t) => t !== e.target.value);
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          selectedToWhom: newSelected,
        },
      });
    } else {
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          selectedToWhom: [...selectedToWhom, e.target.value],
        },
      });
    }
  };
  const handleKategoriChange = (e) => {
    //try to get items
    let childTags = [];
    let tags = [];
    if (viewBy === 'brand') {
      tags = JSON.parse(localStorage.getItem('tags-merchant-category'));
    } else {
      tags = JSON.parse(localStorage.getItem('tags-product-category'));
    }
    let search = tags.filter((t) => t.id === e.target.value)[0];
    if (search && search.items) {
      childTags = search.items;
    }
    if (selectedCategory.includes(e.target.value)) {
      //remove
      let newSelected = [...selectedCategory];
      newSelected = newSelected.filter((t) => t !== e.target.value);
      for (let child of childTags) {
        newSelected = newSelected.filter((t) => t !== child);
      }
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          selectedCategory: newSelected,
        },
      });
    } else {
      dispatch({
        type: 'SEARCH_PAGE',
        payload: {
          selectedCategory: [...selectedCategory, e.target.value, ...childTags],
        },
      });
    }
  };

  let kategori = [];
  let kategori2 = [];
  if (viewBy === 'brand') {
    kategori = JSON.parse(localStorage.getItem('tags-merchant-category'));
  } else {
    kategori = JSON.parse(localStorage.getItem('tags-product-category'));
    kategori2 = JSON.parse(localStorage.getItem('tags-product-to-whom'));
  }
  if (!kategori) {
    kategori = [];
  }
  if (!kategori2) {
    kategori2 = [];
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      style={{ padding: '0px' }}
      className='filter-modal'
    >
      {/* <Modal.Header closeButton>Filters</Modal.Header> */}
      <div className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'>
        <h4
          className='modal-title'
          style={{
            fontSize: '21px',
            fontWeight: '700',
            color: '#000000',
            marginBottom: '0',
            marginTop: '1em',
          }}
        >
          Filters
        </h4>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{ fontSize: '20px', marginRight: '-25px', marginTop: '-10px' }}
          onClick={onHide}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      {!hidePrice && customPrice && customPrice.length === 2 && (
        <React.Fragment>
          <p className='filter-header'>Price</p>
          <Row className='px-4 py-1 my-4'>
            <Col md='12' sm='12'>
              <ReactSlider
                className='price-slider'
                thumbClassName='price-thumb'
                trackClassName='price-track'
                value={customPrice}
                ariaLabel={['Min Price', 'Max Price']}
                ariaValuetext={(state) => `Price ${state.valueNow}`}
                renderThumb={(props, state) => <div {...props}>||</div>}
                pearling
                minDistance={10}
                step={10}
                min={minPrice}
                max={maxPrice}
                onAfterChange={(val) => {
                  dispatch({
                    type: 'SEARCH_PAGE',
                    payload: {
                      customPrice: val,
                    },
                  });
                }}
              />
            </Col>
            <Col md='5' sm='5' xs='5'>
              <FormControl
                type='number'
                className='text-center slider-input'
                value={customPrice[0]}
                onChange={(e) => {
                  let newPrice = [...customPrice];
                  newPrice[0] = e.target.value;
                  if (newPrice[0] && newPrice[1] && newPrice[0] > newPrice[1])
                    newPrice[0] = newPrice[1];
                  dispatch({
                    type: 'SEARCH_PAGE',
                    payload: {
                      customPrice: newPrice,
                    },
                  });
                }}
              />
              <p className='text-center'>Min</p>
            </Col>
            <Col
              md='2'
              sm='2'
              xs='2'
              style={{ marginTop: '15px', textAlign: 'center' }}
            >
              To
            </Col>
            <Col md='5' sm='5' xs='5'>
              <FormControl
                type='number'
                className='text-center slider-input'
                value={customPrice[1]}
                onChange={(e) => {
                  let newPrice = [...customPrice];
                  newPrice[1] = e.target.value;
                  if (newPrice[0] && newPrice[1] && newPrice[0] > newPrice[1])
                    newPrice[1] = newPrice[0];
                  dispatch({
                    type: 'SEARCH_PAGE',
                    payload: {
                      customPrice: newPrice,
                    },
                  });
                }}
              />
              <p className='text-center'>Max</p>
            </Col>
          </Row>
        </React.Fragment>
      )}

      <p className='filter-header'>
        {viewBy === 'brand' ? 'Brand' : ''}Categories
      </p>
      <div className='p-4'>
        <Row>
          {kategori.map((itemKat, idx) => (
            <Col md='6' sm='12' xs='12' key={'kat_' + idx}>
              <div className='py-1 '>
                <FormCheck>
                  <FormCheck.Input
                    id={'label_kat_' + idx}
                    value={itemKat.id}
                    checked={selectedCategory.includes(itemKat.id)}
                    onChange={handleKategoriChange}
                  />
                  <FormCheck.Label htmlFor={'label_kat_' + idx}>
                    {itemKat.name}
                  </FormCheck.Label>
                  <span className='checkmark'></span>
                </FormCheck>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <p className='filter-header'>For Whom</p>

      <div className='p-4'>
        <Row>
          {kategori2.map((itemKat, idx) => (
            <Col md='6' sm='12' xs='12' key={'kat_' + idx}>
              <div className='py-1 '>
                <FormCheck>
                  <FormCheck.Input
                    id={'label_kat2_' + idx}
                    value={itemKat.id}
                    checked={selectedToWhom.includes(itemKat.id)}
                    onChange={handleToWhomChange}
                  />
                  <FormCheck.Label htmlFor={'label_kat2_' + idx}>
                    {itemKat.name}
                  </FormCheck.Label>
                  <span className='checkmark'></span>
                </FormCheck>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className='p-4'>
        <Row
          className='mt-2 mb-2 pt-4'
          style={{ borderTop: '1px solid #ececed' }}
        >
          <Col xs='6' className='text-center'>
            <Button
              variant='light'
              className='px-4'
              block
              onClick={() => {
                dispatch({
                  type: 'SEARCH_PAGE',
                  payload: {
                    customPrice: [minPrice, maxPrice],
                    selectedToWhom: [],
                    selectedCategory: [],
                  },
                });
                onHide();
              }}
            >
              Clear
            </Button>
          </Col>
          <Col xs='6' className='text-center'>
            <Button
              variant='primary'
              className='btn-filter-action pr-4 pl-4'
              block
              onClick={onHide}
            >
              Apply Filters
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalFilter;
