/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { strapiImageUrl } from '../../../helper';
import { AppContext } from '../../../states';

const About = () => {
  const { state } = useContext(AppContext);
  const { about_giftano_card, about_gift_box } = state.strapiData;

  return (
    <div
      className={
        'about ' +
        (state.ecard.is_gift_box == 1 ? 'about-giftbox' : 'about-giftcard')
      }
    >
      <Container>
        {about_giftano_card && about_gift_box && (
          <Row className='px-3 px-sm-0'>
            <Col md='12'>
              <Row className='about-giftano'>
                {state.ecard.is_gift_box == 1 ? (
                  <>
                    <Col md='5' className='text-center'>
                      <img
                        style={{ maxWidth: '100%' }}
                        src={strapiImageUrl(about_gift_box.image.url)}
                        alt='About Giftano Gift Box'
                      />
                    </Col>
                    <Col
                      md='7'
                      className='d-flex justify-content-center flex-column'
                    >
                      <h4 className='about-heading subtitle'>
                        {about_gift_box.title}
                      </h4>
                      <div
                        className='about-content '
                        dangerouslySetInnerHTML={{
                          __html: about_gift_box.text,
                        }}
                      ></div>
                      <Button
                        onClick={() => {
                          window.open(
                            'https://giftano.com/ultimate-gifts',
                            '_blank'
                          );
                        }}
                      >
                        Learn more
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      md='7'
                      className='d-flex justify-content-center flex-column'
                    >
                      <h4 className='about-heading'>
                        {about_giftano_card.title}
                      </h4>
                      <div
                        className='about-content '
                        dangerouslySetInnerHTML={{
                          __html: about_giftano_card.text,
                        }}
                      ></div>
                    </Col>
                    <Col md='5' className='text-center'>
                      <img
                        style={{ maxWidth: '100%' }}
                        src={strapiImageUrl(about_giftano_card.image[0].url)}
                        alt='About Giftano Card'
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default About;
