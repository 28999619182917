import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AppContext } from '../states';
import { Capacitor } from '@capacitor/core';

function ModalDisableGiftanoApps() {
  const { state } = useContext(AppContext);
  const { strapiData, ecard } = state;
  let enableMobileApps = true;
  if (
    strapiData &&
    strapiData.enable_mobile_apps != null &&
    strapiData.enable_mobile_apps !== undefined
  ) {
    enableMobileApps = strapiData.enable_mobile_apps;
  }
  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  return (
    <Modal style={{ top: '20vh' }} show={!enableMobileApps}>
      <Modal.Body>
        <p>
          Thank you for your continued support. We want to inform you that this app is no longer
          supported. To ensure you get a better experience with our services, we will redirect you
          to our web application.
        </p>
        <p>Thank you for your understanding!</p>
        <div className='text-center' style={{ paddingTop: '20px' }}>
          <Button
            type='button'
            onClick={() => {
              let url =
                process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                  ? 'https://cards.giftano.com'
                  : 'https://sandbox-cards.giftano.dev';
              if (ecard && ecard.url) {
                url = ecard.url;
              }
              window.open(url);
            }}
          >
            Open
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDisableGiftanoApps;
