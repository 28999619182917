import React, { useContext } from 'react';
import { Col, Modal, Row, Button } from 'react-bootstrap';
import { AppContext } from '../../../states';
import { AiOutlineCloseCircle } from 'react-icons/ai';
const ModalMerchantTnc = () => {
  const { state, dispatch } = useContext(AppContext);
  const { showModalTnc, activeMerchant } = state.merchantPage;
  const onHide = () => {
    dispatch({
      type: 'MERCHANT_PAGE',
      payload: {
        showModalTnc: false,
      },
    });
  };

  return (
    <Modal centered show={showModalTnc} onHide={onHide} className='modal-terms' size='lg'>
      <div
        className='modal-header d-flex justify-content-between align-items-start mx-4 p-2'
        style={{ borderBottom: '1px solid #ECECED' }}
      >
        <h5
          className='modal-title'
          style={{
            fontSize: '21px',
            fontWeight: '700',
            color: '#000000',
            marginBottom: '0',
            marginTop: '1em',
          }}
        >
          <span
            style={{
              marginBottom: '12px',
              fontWeight: 'bold',
              fontSize: '21px',
            }}
          >
            {activeMerchant.name}
          </span>
        </h5>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{ fontSize: '20px', marginRight: '-25px', marginTop: '-10px' }}
          onClick={onHide}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      <Modal.Body>
        <Row>
          <Col md='12'>
            <div style={{ color: 'var(--gf-warning-300)', fontSize: '18px', paddingTop: '15px' }}>
              ** This gift is valid for {activeMerchant ? activeMerchant.expiration : ''} months
              from the purchased date **
            </div>
            <h5
              style={{
                marginTop: '1.5em',
                marginBottom: '12px',
                fontWeight: 'bold',
              }}
            >
              Terms &amp; Condition
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html: activeMerchant['tnc-original'],
              }}
            ></div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMerchantTnc;
