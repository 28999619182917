import Axios from 'axios';
import qs from 'qs';
import { Capacitor, CapacitorHttp } from '@capacitor/core';

let apiUrl = process.env.REACT_APP_API_BASEURL;
let apiInternal = process.env.REACT_APP_API_INTERNAL;
let baseUrlPublic = apiInternal + '/public/';
let baseUrlMerchant = apiInternal + '/merchant/';
let baseUrlVCR = apiInternal + '/vcr/';
let baseUrlMisc = apiInternal + '/misc/';
let baseUrlPayment = apiUrl + '/' + process.env.REACT_APP_PATH_PAYMENT;

let headersAuth = {};
if (process.env.REACT_APP_BUILD_MODE === 'PRODUCTION') {
  headersAuth = {
    Authorization:
      'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD),
    apikey: process.env.REACT_APP_API_KEY,
  };
}
let headersProd = {};
if (process.env.REACT_APP_BUILD_MODE === 'PRODUCTION') {
  if (Capacitor.isNativePlatform()) {
    headersProd['User-Agent'] = process.env.REACT_APP_AGENT;
  }
}

export const PostPublic = async (url, data = null) => {
  //try to acces via proxy cloudflare
  try {
    if (Capacitor.isNativePlatform()) {
      let resp = await CapacitorHttp.post({
        url: baseUrlPublic + url,
        data: qs.stringify({ custom_id: 'internal_redemption', ...data }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headersProd,
        },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } else {
      let resp = await Axios.post(
        baseUrlPublic + url,
        qs.stringify({ custom_id: 'internal_redemption', ...data })
      );
      if (resp.status === 200) {
        return resp.data;
      }
    }
  } catch (err) {
    //error access cloudflare logs here
    console.log('error connection', err);
    throw err;
  }
};

export const PostMerchant = async (url, data = null) => {
  //try to acces via proxy cloudflare
  try {
    if (Capacitor.isNativePlatform()) {
      let resp = await CapacitorHttp.post({
        url: baseUrlMerchant + url,
        data: qs.stringify({ custom_id: 'internal_redemption', ...data }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headersProd,
        },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } else {
      let resp = await Axios.post(
        baseUrlMerchant + url,
        qs.stringify({ custom_id: 'internal_redemption', ...data })
      );
      if (resp.status === 200) {
        return resp.data;
      }
    }
  } catch (err) {
    //error access cloudflare logs here
    console.log('error connection', err);
    throw err;
  }
};

export const PostVCR = async (url, data = null) => {
  //try to acces via proxy cloudflare
  try {
    if (Capacitor.isNativePlatform()) {
      let resp = await CapacitorHttp.post({
        url: baseUrlVCR + url,
        data: qs.stringify({ custom_id: 'internal_redemption', ...data }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headersProd,
        },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } else {
      let resp = await Axios.post(
        baseUrlVCR + url,
        qs.stringify({ custom_id: 'internal_redemption', ...data })
      );
      if (resp.status === 200) {
        return resp.data;
      }
    }
  } catch (err) {
    //error access cloudflare logs here
    console.log('error connection', err);
    throw err;
  }
};

export const PostMISC = async (url, data = null) => {
  //try to acces via proxy cloudflare
  try {
    if (Capacitor.isNativePlatform()) {
      let resp = await CapacitorHttp.post({
        url: baseUrlMisc + url,
        data: qs.stringify({ custom_id: 'internal_redemption', ...data }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headersProd,
        },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } else {
      let resp = await Axios.post(
        baseUrlMisc + url,
        qs.stringify({ custom_id: 'internal_redemption', ...data })
      );
      if (resp.status === 200) {
        return resp.data;
      }
    }
  } catch (err) {
    //error access cloudflare logs here
    console.log('error connection', err);
    throw err;
  }
};

export const RequestPayment = async (url, method = 'GET', data = {}, headers = {}) => {
  try {
    if (Capacitor.isNativePlatform()) {
      let resp = await CapacitorHttp.request({
        url: baseUrlPayment + url,
        method: method,
        data: data,
        headers: {
          ...headers,
          ...headersAuth,
          ...headersProd,
        },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    } else {
      let resp = await Axios({
        url: baseUrlPayment + url,
        method: method,
        data: data,
        headers: {
          ...headers,
          ...headersAuth,
        },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    }
  } catch (err) {
    console.log('error connection', err);
    throw err;
  }
};

export const Connection = async ({ url, method = 'GET', data = {}, headers = {} }) => {
  try {
    if (Capacitor.isNativePlatform()) {
      const reqConfig = {
        url: apiUrl + '/' + url,
        method: method,
        data: data,
        headers: {
          'User-Agent': process.env.REACT_APP_AGENT,
          'Content-Type': 'application/json',
          ...headers,
          ...headersAuth,
          ...headersProd,
        },
      };
      if (method === 'GET') delete reqConfig.data;
      let resp = await CapacitorHttp.request(reqConfig);
      if (resp.status === 200) {
        return resp.data;
      }
    } else {
      let resp = await Axios({
        url: apiUrl + '/' + url,
        method: method,
        data: data,
        headers: { ...headers, ...headersAuth },
      });
      if (resp.status === 200) {
        return resp.data;
      }
    }
  } catch (err) {
    console.log('error connection', err);
  }
  return null;
};

const service = 'gift-product';
export const getMerchantbyIDs = async ({ size, IDs }) => {
  let resp = await Connection({
    url: `${service}/elastic-search/get-merchant`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      size: size,
      from: 0,
      query: {
        ids: {
          values: IDs,
        },
      },
    }),
  });
  if (resp.code === 200) {
    if (resp.data && resp.data.length > 0) {
      resp.data = resp.data.map((item) => {
        return {
          ...item,
          ...item._source,
        };
      });
    }
  }
  return resp;
};

//giftano-card-perks/get/all?pagination[page]=0&pagination[pageSize]=20
export const getPerks = async ({ size, page }) => {
  let resp = await Connection({
    url: `giftano-card-perks/get/all?pagination[page]=${page}&pagination[pageSize]=${size}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return resp;
};

// export const RecordLog = async (notes, gn = '') => {
//   // const host = window.location.hostname;
//   // let logurl = '';
//   // if(host === 'cards.giftano.com'){
//   //     logurl = 'https://cards.giftano.com/recordlogs';
//   // }else{
//   //     logurl = 'https://cards-sandbox.giftano.io/recordlogs'
//   // }
//   let logurl = '/api/recordlogs';
//   Axios.post(
//     logurl,
//     qs.stringify({
//       notes: notes,
//       gn: gn,
//     })
//   )
//     .then((res) => {
//       console.log('error log sent', res);
//     })
//     .catch((errLog) => {
//       console.log('error log failed to sent', errLog);
//     });
// };
