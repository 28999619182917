import React, { useContext } from 'react';
import { AppContext } from '../states';
import CurrencySymbol from '../components/CurrencySymbol';
import clm from 'country-locale-map';

const getFormattedCurrency = ({ value, locale }) => {
  let localeValue = locale || 'SG';

  let options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (locale === 'ID') options = {};
  let tolocale = clm.getLocaleByAlpha2(localeValue);
  const lang = tolocale && tolocale.substring(0, tolocale.indexOf('_'));
  return parseFloat(value).toLocaleString(lang || 'en', options);
};

const CurrencyFormat = ({ value, locale }) => {
  const { state } = useContext(AppContext);
  const { ecard } = state;
  return (
    <>
      <CurrencySymbol />
      {getFormattedCurrency({ value: value, locale: locale || ecard.country })}
    </>
  );
};

export { CurrencyFormat, getFormattedCurrency };
