/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Image,
  ListGroup,
  FormControl,
  InputGroup,
  FormCheck,
  FormGroup,
} from 'react-bootstrap';
import MyNavbar from '../../components/MyNavbar';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { PostPublic } from '../../services/Connection';
import { AppContext } from '../../states';
import Moment from 'react-moment';
import SwipeButton from 'react-swipezor';
import { toast } from 'react-toastify';
// import { AiOutlineGift } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi';
import { HiArrowRight } from 'react-icons/hi';
import MyFooter from '../../components/MyFooter';
import { Helmet } from 'react-helmet';
import { AppConfig } from '../../config';
import moment from 'moment';
import ModalVoucherDesc from '../ModalVoucherDesc';
// import ModalAddToAccount from '../ModalAddToAccount';
import LoadECard from '../../components/LoadECard';
import { isMembershipActive } from '../../helper';
import ModalConfirm from './components/ModalConfirm';
import ModalShowCode from './components/ModalShowCode';
import ModalRedemptionForm from './components/ModalRedemptionForm';
import ModalRedemptionSuccess from './components/ModalRedemptionSuccess';
import ModalDetailCode from './components/ModalDetailCode';
import EmptyGift from '../../assets/images/empty-gift.png';
import CardAddAccount from './components/CardAddAccount';
import MoreGifts from './components/MoreGifts';
import TabBarBottom from '../../components/TabBarBottom';
import ModalHowTo from '../ModalHowTo';

const params = new URLSearchParams(window.location.search);

const MyGifts = () => {
  const { state, dispatch } = useContext(AppContext);

  const { dataConfirm } = state.myGiftPage;

  const [itemsVoucher, setItemsVoucher] = useState([]);
  const [filterText, setFilterText] = useState('');

  // const [needToShowModalAddAccount, setNeedToShowModalAddAccount] =
  //   useState(false);
  // const [modalAddToAccount, setModalAddToAccount] = useState({ show: false });
  // const modalAddToAccountToggle = () => setModalAddToAccount({ show: false });

  const [hideExpired, setHideExpired] = useState(true);

  const { order_id } = useParams();

  const history = useHistory();

  const loadGifts = async () => {
    try {
      let resp = await PostPublic('ecard/', {
        ecard: state.ecard.number,
        key: state.key,
        details: 1,
      });
      // console.log('resp ecard more ', resp)
      if (resp.status === 1) {
        dispatch({ type: 'SET_ECARD', data: resp.data });
      } else {
        history.push('/');
      }
    } catch (err) {
      toast.error('Oops something wrong while loading your card data, please reload the page!', {
        onClose: () => window.location.reload(),
      });
    }
  };

  useEffect(() => {
    const existingScript = document.getElementById('giftanoeVoucher');
    if (!existingScript) {
      const script = document.createElement('script');
      if (process.env.REACT_APP_BUILD_MODE === 'PRODUCTION') {
        script.src = 'https://static-cdn.giftano.com/weco/giftano-evoucher.js';
      } else {
        script.src = 'https://static-cdn.giftano.com/weco-sandbox/giftano-evoucher.js';
      }
      script.id = 'giftanoeVoucher';
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 700);
  }, [order_id]);

  // useEffect(() => {
  //   if (needToShowModalAddAccount) {
  //     setTimeout(() => {
  //       setModalAddToAccount({ show: true });
  //     }, 3000);
  //   }
  // }, [needToShowModalAddAccount]);

  useEffect(() => {
    let vouchers = [];
    if (state.ecard) {
      // let db = new PouchDB('redemption-gift-result-' + state.ecard.company.id);

      state.ecard.transactions.forEach((transaction) => {
        if (!order_id || (order_id && transaction.id == order_id)) {
          transaction.vouchers.forEach((voucher) => {
            vouchers.push({ ...voucher });
          });
        }
      });

      // if (order_id && !state.ecard.memberid && isMembershipActive(state)) {
      //   setNeedToShowModalAddAccount(true);
      // }
    }

    //filter voucher here
    if (filterText !== '') {
      vouchers = vouchers.filter(
        (i) =>
          (i.merchant && i.merchant.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1) ||
          (i.code && i.code.toLowerCase().indexOf(filterText.toLowerCase()) > -1) ||
          (i.status && i.status.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
      );
    }

    vouchers = vouchers.map((item) => {
      const isExpired = moment(`${item['expiry-date']} 23:59:59`).isSameOrBefore(moment())
        ? true
        : false;
      return { ...item, isExpired };
    });

    // console.log(vouchers, 'voucher e bos');

    setItemsVoucher(vouchers);
  }, [filterText, order_id, state]);

  // useEffect(() => {
  //   if (state.gcardinfo && !state.ecard) {
  //     const loadData = async () => {
  //       try {
  //         let resp = await PostPublic('ecard/', {
  //           ecard: state.gcardinfo.number,
  //           key: state.key,
  //           details: '1',
  //         });
  //         // console.log('resp ecard/', resp)
  //         if (resp.status === 1) {
  //           dispatch({ type: 'SET_ECARD', data: resp.data });
  //         } else {
  //           toast.warning(resp.message);
  //           history.push('/');
  //         }
  //       } catch (err) {
  //         toast.error('Error load your card data, please reload the page!', {
  //           onClose: () => window.location.reload(),
  //         });
  //       }
  //     };
  //     loadData();
  //   }
  // }, [dispatch, history, state.ecard, state.gcardinfo, state.key]);

  // useEffect(() => {
  //   if (
  //     !params.has('savecard') &&
  //     !order_id &&
  //     state.ecard &&
  //     state.strapiData &&
  //     !state.ecard.memberid &&
  //     isMembershipActive(state)
  //   ) {
  //     setTimeout(() => {
  //       // check local storeage
  //       const isInvited = localStorage.getItem('invitedMember');
  //       if (!isInvited && state.ecard.is_gift_box == 0) {
  //         setModalAddToAccount({ show: true });
  //       }
  //     }, 3000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state]);

  if (!state.whitelabel || !state.gcardinfo) {
    return <Redirect to='/' />;
  }

  const resetSlide = (voucher_code) => {
    let newVouchers = [...itemsVoucher];
    newVouchers = newVouchers.map((voucher) => {
      // console.log('voucher', voucher, dataConfirm)
      if (voucher.code === voucher_code) {
        voucher._reset = 1;
      }
      return voucher;
    });
    // console.log('new Voucher', newVouchers)
    setItemsVoucher(newVouchers);
  };

  const searchChange = async (e) => {
    setFilterText(e.target.value);
  };

  const redeemConfirmNoAction = async () => {
    resetSlide(dataConfirm.code);
    // setShowConfirm(false)
    dispatch({ type: 'MYGIFT_PAGE', payload: { showConfirm: false } });
  };

  const redeemConfirmAction = async (voucher) => {
    // setDataConfirmSend(true)
    dispatch({ type: 'MYGIFT_PAGE', payload: { dataConfirmSend: true } });
    try {
      let resp = await PostPublic('get_voucher/code/', {
        merchantid: voucher.merchant.id,
        code: voucher.code,
      });
      // setDataConfirmSend(false)
      dispatch({ type: 'MYGIFT_PAGE', payload: { dataConfirmSend: false } });
      // console.log('resp', resp)
      if (resp.status == 1) {
        resetSlide(voucher.code);
        // setShowConfirm(false)
        // setDataCode(resp.data)
        dispatch({
          type: 'MYGIFT_PAGE',
          payload: {
            showConfirm: false,
            dataCode: resp.data,
          },
        });
        if (resp.data.useowncode == 1) {
          if (
            resp.data.code &&
            (resp.data.code.substr(0, 7) === 'http://' ||
              resp.data.code.substr(0, 8) === 'https://')
          ) {
            // window.open(resp.data.code)
            setTimeout(() => {
              window.open(resp.data.code);
            }, 3000);
            // setShowCode(true)
          } else {
            // setShowCode(true)
          }
          dispatch({
            type: 'MYGIFT_PAGE',
            payload: {
              showCode: true,
            },
          });
        } else {
          if (voucher.status.value === 'created' || voucher.status.value === 'valid') {
            // setShowRedemptionForm(true)
            // setDataRedemptionForm({
            //   customer_name: '',
            //   customer_details: '',
            //   staff_pin: '',
            // })
            // setDataRedemptionFormErrorMessage('')
            // setExpandRedemptionForm(false)
            dispatch({
              type: 'MYGIFT_PAGE',
              payload: {
                showRedemptionForm: true,
                dataRedemptionForm: {
                  customer_name: '',
                  customer_details: '',
                  staff_pin: '',
                },
                dataRedemptionFormErrorMessage: '',
                expandRedemptionForm: false,
              },
            });
          } else {
            // setShowVoucherDetails(true)
            dispatch({
              type: 'MYGIFT_PAGE',
              payload: {
                showVoucherDetails: true,
              },
            });
          }
        }
      } else {
        toast(
          'We are really sorry but we are currently facing some technical issues and we are working hard to resolve them. This issue should be resolved by within the next 24-48h. Sorry for any inconvenience caused.',
          { type: 'warning' }
        );
      }
    } catch (err) {
      console.log(err);
      toast(
        'We are really sorry but we are currently facing some technical issues and we are working hard to resolve them. This issue should be resolved by within the next 24-48h. Sorry for any inconvenience caused.',
        { type: 'error' }
      );
    }
  };

  const IsShowSlide = (voucher) => {
    // console.log(voucher);
    let retVal = false;
    if (voucher['is_physical']) return true;
    if (voucher['is-evoucher'] === '0') {
      retVal = false;
    } else {
      let status = voucher.status.value;
      if (voucher['use-merchant-code'] === '1') {
        if (status === 'created' || status === 'valid' || status === 'redeemed') {
          retVal = true;
        } else {
          retVal = false;
        }
      } else {
        // use merchant code 0
        if (status === 'created' || status === 'valid') {
          retVal = true;
        } else {
          retVal = false;
        }
      }
      if (voucher.isExpired && !(status === 'redeemed' && voucher['merchant_code_is_url'] == 1)) {
        retVal = false;
      }
    }
    // console.log('isShow', retVal)
    return retVal;
  };

  const getSlideText = (voucher) => {
    if (voucher.is_physical == 1) {
      if (voucher.is_request_sent == 1) {
        return 'View Delivery Request';
      } else {
        return 'Request Delivery';
      }
    } else {
      if (state.ecard.is_gift_box == 1) {
        if (voucher.status.value === 'redeemed') {
          return 'View Code';
        } else {
          return 'Slide to use at store';
        }
      } else {
        if (voucher.status.value === 'redeemed') {
          return 'View Code';
        } else if (voucher.status.value === 'valid' && voucher.use_merchant_code == 1) {
          return 'Reveal Code';
        } else {
          return 'Slide to redeem';
        }
      }
    }
  };

  let merchantName = '';
  if (order_id && state.ecard && state.ecard.transactions) {
    state.ecard.transactions
      .filter((t) => t.id === order_id)
      .forEach((t) => {
        t.vouchers.forEach((v) => {
          if (v.merchant && v.merchant.name) {
            merchantName = v.merchant.name;
          }
        });
      });
  }
  let totalMoreVouchers =
    order_id && state.ecard && state.ecard.transactions
      ? state.ecard.transactions
          .filter((t) => t.id !== order_id)
          .reduce((total, t) => (total += t.vouchers.length), 0)
      : 0;

  // console.log('ecard', state.ecard);

  return (
    <div className='page-mygifts'>
      <Helmet>
        <title>My Gift(s) - {AppConfig.title}</title>
      </Helmet>
      <div className='rectangle-header'>
        <LoadECard>
          {state.ecard && (
            <>
              {/* <div className='mygift__banner banner'>
                <Container>
                  <Button
                    as={Link}
                    to='/getstarted'
                    variant='link'
                    className='navbar-back btn-back'
                  >
                    <HiArrowLeft />
                  </Button>
                </Container>
                <h4 className='floating-text'>Enjoy your experience!</h4>
                {state.strapiData && state.strapiData.my_gift_banner && (
                  <img
                    className='landing__banner'
                    src={strapiImageUrl(state.strapiData.my_gift_banner.url)}
                    alt='My Gift'
                  />
                )}
              </div> */}
              <Container className='min-height-alter'>
                <MyNavbar />
                {/* {modalAddToAccount.show && (
                  <ModalAddToAccount
                    show={true}
                    toggle={modalAddToAccountToggle}
                    // mode={order_id ? 'purchase' : 'congrats'}
                    mode={'purchase'}
                  />
                )} */}

                {!order_id &&
                !params.has('savecard') &&
                !state.ecard.memberid &&
                isMembershipActive(state) ? (
                  <div className='d-flex justify-content-center mt-4 mb-4'>
                    <CardAddAccount />
                  </div>
                ) : null}

                <Row className='mb-2'>
                  <Col md='12' className='title'>
                    {order_id ? (
                      <>
                        <div className='text-center mb-4'>
                          <img
                            src={'https://static-cdn.giftano.com/assets/redemption/check-mark.png'}
                            alt={'Successful'}
                          />
                        </div>
                        <h5 className='text-black font-weight-bold text-center'>
                          Your purchase was successful!
                        </h5>
                        <p className='text-center mb-5'>
                          Your purchase of {merchantName} gift(s) was successful and added to your
                          list of gifts below.
                        </p>
                      </>
                    ) : (
                      <h5 className='text-black font-weight-bold text-center text-md-left  mb-5'>
                        My Gift(s)
                      </h5>
                    )}

                    {!order_id && state.ecard.is_gift_box == 0 && (
                      <Row>
                        <Col md='7' xs='7'>
                          <InputGroup className='nav-input mb-3'>
                            <FormControl
                              placeholder='Find Gift'
                              value={filterText}
                              onChange={searchChange}
                            />
                            <InputGroup.Append>
                              <Button variant='success'>
                                <FiSearch color={'gray'} />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Col>
                        <Col xs='5' md='3' lg='2' className='offset-md-1 offset-lg-3'>
                          <FormCheck.Label className='toggle-label'>Hide Expired</FormCheck.Label>
                          <FormGroup
                            className='mb-3 button r'
                            controlId='formBasicCheckbox'
                            id='toggle-expired'
                          >
                            <FormCheck>
                              <FormCheck.Input
                                value={hideExpired}
                                onChange={() => setHideExpired(!hideExpired)}
                                type='checkbox'
                                className='checkbox'
                              />
                              <div className='knobs'></div>
                              <div className='layer'></div>
                            </FormCheck>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md='12' className=' d-flex flex-column-reverse voucher-list-container'>
                    {itemsVoucher.length === 0 && (
                      <div className='empty-gift'>
                        <img className='empty-image' src={EmptyGift} alt='Gift Box' />
                        <p>Looks like you haven’t added any gifts yet.</p>
                      </div>
                    )}
                    {itemsVoucher
                      .filter((voucher) => {
                        if (state.ecard.is_gift_box == 1) return true; // show all for giftbox
                        if (
                          hideExpired === true &&
                          voucher.isExpired === true &&
                          !(
                            voucher.status.value === 'redeemed' &&
                            voucher['merchant_code_is_url'] == 1
                          )
                        ) {
                          return false;
                        }
                        return true;
                      })
                      .map((voucher) => (
                        <ListGroup
                          key={voucher.code}
                          className={`voucher-list my-3 ${
                            IsShowSlide(voucher) == false ? 'voucher-list-disabled-container' : ''
                          }`}
                        >
                          <ListGroup.Item
                            className={`${
                              IsShowSlide(voucher) == false ? 'voucher-list-disabled-color' : ''
                            }`}
                          >
                            <Row>
                              <Col className='voucher-image' md='2' xs='4'>
                                <Image
                                  src={voucher.file || voucher.merchant.logo}
                                  rounded
                                  fluid
                                  className='merchant-images'
                                />
                              </Col>
                              <Col md='5' xs='8' className='text-left'>
                                {voucher['is_physical'] == '1' || voucher['is-evoucher'] == '1' ? (
                                  ''
                                ) : (
                                  <label className='voucher-tag'>
                                    Physical Voucher send via Mail
                                  </label>
                                )}
                                <p className='voucher-merchant-name'>{voucher.merchant.name}</p>
                                <h4 className='voucher-price'>{voucher.value}</h4>

                                {voucher.description && voucher.description.trim() !== '' ? (
                                  <React.Fragment>
                                    {/* <div className='d-flex justify-content-between'>
                                      <p className='voucher-description'>
                                        {voucher.description
                                          .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                                          .replace(/\n/g, '<br/>')}
                                      </p>
                                      <Button
                                        variant='link'
                                        size='sm'
                                        onClick={() => {
                                          dispatch({
                                            type: 'SET_MODAL_VOUCHER_DESC',
                                            data: {
                                              show: true,
                                              voucher: voucher,
                                            },
                                          });
                                        }}
                                      >
                                        More
                                      </Button>
                                    </div> */}
                                    <Button
                                      size='sm'
                                      className='d-none d-md-block'
                                      variant='link'
                                      onClick={() => {
                                        dispatch({
                                          type: 'SET_MODAL_VOUCHER_DESC',
                                          data: {
                                            show: true,
                                            voucher: voucher,
                                          },
                                        });
                                      }}
                                    >
                                      Read Gift Terms &amp; Conditions &gt;&gt;
                                    </Button>
                                    <Button
                                      size='sm'
                                      className='d-md-none'
                                      variant='link'
                                      onClick={() => {
                                        dispatch({
                                          type: 'SET_MODAL_VOUCHER_DESC',
                                          data: {
                                            show: true,
                                            voucher: voucher,
                                          },
                                        });
                                      }}
                                    >
                                      Read Gift T&amp;C's &gt;&gt;
                                    </Button>
                                    {voucher['use-merchant-code'] != 0 &&
                                      voucher.how_to_use != 0 && (
                                        <Button
                                          size='sm'
                                          variant='link'
                                          onClick={() => {
                                            dispatch({
                                              type: 'SET_MODAL_VOUCHER_HOW_TO',
                                              data: {
                                                show: true,
                                                voucher: voucher,
                                              },
                                            });
                                          }}
                                        >
                                          How to use &gt;&gt;
                                        </Button>
                                      )}
                                  </React.Fragment>
                                ) : (
                                  <>
                                    <Button
                                      size='sm'
                                      className='d-none d-md-block'
                                      variant='link'
                                      onClick={() => {
                                        dispatch({
                                          type: 'SET_MODAL_VOUCHER_DESC',
                                          data: {
                                            show: true,
                                            voucher: voucher,
                                          },
                                        });
                                      }}
                                    >
                                      Read Gift Terms &amp; Conditions &gt;&gt;
                                    </Button>
                                    <Button
                                      size='sm'
                                      className='d-md-none'
                                      variant='link'
                                      onClick={() => {
                                        dispatch({
                                          type: 'SET_MODAL_VOUCHER_DESC',
                                          data: {
                                            show: true,
                                            voucher: voucher,
                                          },
                                        });
                                      }}
                                    >
                                      Read Gift T&amp;C's &gt;&gt;
                                    </Button>
                                    {voucher['use-merchant-code'] != 0 &&
                                      voucher.how_to_use != 0 && (
                                        <Button
                                          size='sm'
                                          variant='link'
                                          onClick={() => {
                                            dispatch({
                                              type: 'SET_MODAL_VOUCHER_HOW_TO',
                                              data: {
                                                show: true,
                                                voucher: voucher,
                                              },
                                            });
                                          }}
                                        >
                                          How to use &gt;&gt;
                                        </Button>
                                      )}
                                  </>
                                )}
                              </Col>
                              <Col md='5' className='voucher-info'>
                                {voucher.delivery_datetime_unix > Math.floor(Date.now() / 1000) &&
                                voucher.delivery_method === 'email' &&
                                voucher.delivery_email_status !== 'sent' ? (
                                  <div
                                    style={{
                                      padding: '15px 10px',
                                      borderRadius: '8px',
                                      backgroundColor: 'var(--gf-light-gray-300)',
                                      textAlign: 'center',
                                      fontSize: '14px',
                                    }}
                                  >
                                    Order has been received
                                    <br />
                                    <span style={{ fontWeight: '700' }}>
                                      Delivery Schedule:{' '}
                                      {moment(voucher.delivery_datetime_unix * 1000).format(
                                        'DD MMM YYYY HH:mm A'
                                      )}
                                    </span>
                                  </div>
                                ) : IsShowSlide(voucher) ? (
                                  <Row className='justify-content-center' noGutters>
                                    <Col md='12'>
                                      <SwipeButton
                                        mainText={getSlideText(voucher)}
                                        overlayText='Unlock'
                                        classList='my-class'
                                        caretClassList='swipe-caret'
                                        caret={<HiArrowRight color='#d5e1e0' />}
                                        overlayClassList='my-overlay-class'
                                        onSwipeDone={() => {
                                          delete voucher._reset;
                                          // setDataConfirm(voucher)
                                          dispatch({
                                            type: 'MYGIFT_PAGE',
                                            payload: {
                                              dataConfirm: voucher,
                                            },
                                          });
                                          // console.log('voucher data', voucher)
                                          if (voucher.is_physical == 1) {
                                            // redeemConfirmAction(voucher);
                                            resetSlide(voucher.code);
                                            // setShowRedemptionForm(true)
                                            dispatch({
                                              type: 'MYGIFT_PAGE',
                                              payload: {
                                                showRedemptionForm: true,
                                              },
                                            });
                                            setTimeout(() => {
                                              const gcode = Buffer.from(voucher.code).toString(
                                                'base64'
                                              );
                                              const mid = Buffer.from(voucher.merchant.id).toString(
                                                'base64'
                                              );
                                              const hostname = window.location.hostname;
                                              if (
                                                hostname === 'cards.giftano.com' ||
                                                hostname === 'ultimate-gift.giftano.com'
                                              ) {
                                                window.open(
                                                  'https://giftano.com/claim_my_gift?gcode=' +
                                                    gcode +
                                                    '&mid=' +
                                                    mid +
                                                    '&skip_animation=1&ref=' +
                                                    window.location.href,
                                                  '_self'
                                                );
                                              } else {
                                                window.open(
                                                  'https://web-sandbox.giftano.io/claim_my_gift?gcode=' +
                                                    gcode +
                                                    '&mid=' +
                                                    mid +
                                                    '&skip_animation=1&ref=' +
                                                    window.location.href,
                                                  '_self'
                                                );
                                              }
                                            }, 2000);
                                          } else {
                                            if (
                                              voucher['use-merchant-code'] == 0 ||
                                              voucher.status.value === 'redeemed'
                                            ) {
                                              redeemConfirmAction(voucher);
                                            } else {
                                              // setShowConfirm(true)
                                              dispatch({
                                                type: 'MYGIFT_PAGE',
                                                payload: {
                                                  showConfirm: true,
                                                },
                                              });
                                            }
                                          }
                                        }}
                                        minSwipeWidth={0.6}
                                        delta={40}
                                        reset={voucher._reset}
                                      />
                                      {voucher.status.value === 'valid' &&
                                      voucher.use_merchant_code === '1' ? (
                                        <div className='voucher-expiry'>
                                          Reveal your gift before:
                                          <Moment
                                            className='ml-1 font-weight-bold'
                                            format='DD-MMM-YYYY'
                                            date={voucher['expiry-date']}
                                          />
                                        </div>
                                      ) : (
                                        <div className='voucher-expiry'>
                                          Valid until:
                                          <Moment
                                            className='ml-1 font-weight-bold'
                                            date={voucher['expiry-date']}
                                            format='DD-MMM-YYYY'
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <label className='voucher-status '>
                                      {voucher.isExpired ? 'EXPIRED' : voucher.status.text}
                                    </label>
                                    <Col md='12'>
                                      <div className='voucher-expiry'>
                                        Valid until:
                                        <Moment
                                          className='ml-1 font-weight-bold'
                                          date={voucher['expiry-date']}
                                          format='DD-MMM-YYYY'
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                      ))}
                  </Col>
                  {order_id && state.ecard && totalMoreVouchers > 0 && (
                    // <Col
                    //   md='10'
                    //   className='offset-md-1 d-flex flex-column-reverse'
                    // >
                    //   <div className='mt-4 mb-4 text-center'>
                    //     <Button
                    //       variant='giftano'
                    //       onClick={() => history.push('/mygifts')}
                    //     >
                    //       View All My Gifts
                    //     </Button>
                    //   </div>
                    // </Col>
                    <MoreGifts />
                  )}
                </Row>

                {order_id &&
                !params.has('savecard') &&
                !state.ecard.memberid &&
                isMembershipActive(state) ? (
                  <div
                    className='d-flex justify-content-center '
                    style={{ paddingBottom: '100px' }}
                  >
                    <CardAddAccount />
                  </div>
                ) : null}

                <ModalConfirm
                  redeemConfirmAction={redeemConfirmAction}
                  redeemConfirmNoAction={redeemConfirmNoAction}
                />

                <ModalShowCode
                  onHide={() => {
                    resetSlide(dataConfirm.code);
                    loadGifts();
                  }}
                />

                <ModalRedemptionForm
                  onHide={() => {
                    resetSlide(dataConfirm.code);
                    loadGifts();
                  }}
                />

                <ModalRedemptionSuccess
                  onHide={() => {
                    resetSlide(dataConfirm.code);
                    loadGifts();
                  }}
                />

                <ModalDetailCode
                  onHide={() => {
                    resetSlide(dataConfirm.code);
                    loadGifts();
                  }}
                />

                <ModalVoucherDesc />
                <ModalHowTo />
              </Container>
              <MyFooter />
              <TabBarBottom />
            </>
          )}
        </LoadECard>
      </div>
    </div>
  );
};

export default MyGifts;
