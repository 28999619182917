/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ReactMarkdown from 'react-markdown';
import Clamp from 'react-multiline-clamp';

const ModalDetailPerk = ({ show, setShow, data }) => {
  const [logo, setLogo] = React.useState('');
  useEffect(() => {
    // setLogo(data.logo.url || '');
    // if (merchant && merchant.logo && merchant.logo.cc_size && merchant.logo.cc_size.filename) {
    let image = data?.merchant?.logo?.cc_size?.filename || '';
    setLogo(data?.logo?.url || image);
    // }
  }, [data]);
  useEffect(() => {
    if (!show) {
      setLogo('');
    }
  }, [show]);
  return (
    <Modal centered show={show} onHide={setShow} backdrop='static' className='modal-perk-detail'>
      <div className='modal-header d-flex justify-content-between align-items-center mx-4 px-2 py-0'>
        <h4
          style={{
            fontWeight: 'bold',
            fontSize: '21px',
            width: '100%',
            textAlign: 'start',
            marginBottom: '0',
          }}
        >
          {data.title}
        </h4>
        <Button
          variant={'link'}
          className={'gf-text-primary p-0 btn-modal-close'}
          style={{
            fontSize: '20px',
          }}
          onClick={setShow}
        >
          <AiOutlineCloseCircle color='var(--primary)' />
        </Button>
      </div>
      <Modal.Body className='p-2 mx-4'>
        <img
          className='img-fluid img'
          src={`https://giftano.com/cdn-cgi/image/format=auto,height=450/${data?.image?.url}`}
          alt={data.title}
        />
        <div className='text-left'>
          {data.merchant && (
            <div className='d-flex align-items-center merchant-description'>
              <div
                className='merchant-logo'
                style={
                  logo !== ''
                    ? // ? {
                      //     backgroundImage: `url(${logo})`,
                      //     backgroundSize: 'cover',
                      //     backgroundPosition: 'center',
                      //   }
                      {
                        width: 'auto',
                        height: '40px',
                        background: 'transparent',
                      }
                    : {}
                }
              >
                {logo === '' ? (
                  data.merchant?.name?.substring(0, 1)
                ) : (
                  <img src={`${logo}`} alt={data.merchant?.name || ''} style={{ height: '100%' }} />
                )}
              </div>
              <div className='merchant-name'>{data.merchant?.name}</div>
            </div>
          )}
          <div className='content'>
            <div className='title-content'>{data.short_description}</div>
            <ReactMarkdown className='description'>{data.description}</ReactMarkdown>
            <div className='cta-content'>
              <a
                href={data?.url || '#'}
                target='_blank'
                rel='noopener noreferrer'
                className='d-flex justify-content-between align-items-center'
              >
                <div className='url'>
                  <Clamp withTooltips lines={1} maxLines={1}>
                    {data?.url}
                  </Clamp>
                </div>
                <Button variant='primary' onClick={setShow} className='flex-shrink-0'>
                  Go To Site
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className='mb-4 text-center'>
        <Button variant='outline-primary' onClick={setShow}>
          Close
        </Button>
        <a href={data?.url || '#'} target='_blank' rel='noopener noreferrer'>
          <Button variant='primary' onClick={setShow}>
            Go To Site
          </Button>
        </a>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalDetailPerk;
