import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import MyImg from '../../../components/MyImg';
import { AppContext } from '../../../states';
import { Carousel } from 'react-responsive-carousel';

const MerchantInfo = () => {
  const { state } = useContext(AppContext);
  const {
    activeMerchant,
    // gifts
  } = state.merchantPage;
  // let howItWork = '';
  // const arr = [
  //   ...new Set(gifts.map((i) => (i.is_physical ? 'physical' : 'default'))),
  // ];
  // if (arr.length === 1) {
  //   state.strapiData.how_it_work
  //     .filter((how) => how.slug === arr[0])
  //     .forEach((how) => {
  //       howItWork = how.text;
  //     });
  // }

  // console.log('merchant info', activeMerchant)
  return (
    <Row className='merchant-product__info'>
      <Col md='12' xs='12' lg='6'>
        {activeMerchant.images && (
          <Carousel swipeable={false} showThumbs={false} showStatus={false}>
            {activeMerchant.images.map((imgSrc, imgIdx) => (
              <div key={'carrousel_item_' + imgIdx}>
                <MyImg src={imgSrc} rounded fluid className='merchant-images' />
              </div>
            ))}
          </Carousel>
        )}
      </Col>
      <Col md='12' xs='12' lg='6' className='pl-3 text-left'>
        <h4
          className={
            'merchant-name font-weight-bold text-uppercase ' +
            (activeMerchant.name && activeMerchant.name.length > 60
              ? 'merchant-name-long'
              : '')
          }
        >
          {activeMerchant.name}
        </h4>

        <p
          className='merchant-desc'
          dangerouslySetInnerHTML={{
            __html:
              activeMerchant.desc &&
              activeMerchant.desc
                .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                .replace(/\n/g, '<br/>'),
          }}
        ></p>

        {/* {howItWork && (
          <>
            <h4 className='desc font-weight-bold'>How it works</h4>
            <p>{howItWork}</p>
          </>
        )} */}
        {/* {activeMerchant.tnc && (
          <div>
            <Button
              size='sm'
              variant='link'
              className='links'
              onClick={() => {
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    showModalTnc: true,
                  },
                });
              }}
            >
              Read Gift Terms &amp; Conditions
            </Button>
             <Button
              size='sm'
              variant='link'
              className='links'
              onClick={() => {
                dispatch({
                  type: 'MERCHANT_PAGE',
                  payload: {
                    showModalTnc: true,
                  },
                });
              }}
            >
              How to use >>
            </Button>
          </div>
        )} */}
      </Col>
    </Row>
  );
};

export default MerchantInfo;
