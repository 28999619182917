import React, { createContext, useReducer, useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';

// define initial state here
const initialState = {
  domContentLoaded: false,
  strapiData: {},
  howToRedeemGiftBox: {},
  modalTopupShow: false,
  modalGiftType: {
    show: false,
    type: '',
  },
  ipaddress: '',
  modalVoucherDesc: {
    show: false,
    voucher: {},
  },
  modalVoucherHowTo: {
    show: false,
    voucher: {},
  },
  session_id: '',
  redacted_email: '',
  giftsStatus: 'pending',
  getStartedPage: {
    giftBoxAnimation: false,
    inspiredData: [],
  },
  searchPage: {
    giftItems: [],
    merchants: [],
    selectedToWhom: localStorage.getItem('redeem-search-to-whom')
      ? JSON.parse(localStorage.getItem('redeem-search-to-whom'))
      : [],
    selectedCategory: localStorage.getItem('redeem-search-category')
      ? JSON.parse(localStorage.getItem('redeem-search-category'))
      : [],
    filterCount: 1,
    customPrice: [0, 0],
    minPrice: 0,
    maxPrice: 100,
    isSortBy: localStorage.getItem('redeem-search-sort-by') || 'price-a',
    viewBy: 'gift',
    searchText: '',
  },
  merchantPage: {
    gifts: [],
    activeMerchant: {},
    showConfirm: false,
    titlePage: '',
    showModalShipment: false,
    isRedeem: false,
    showModalTnc: false,
    activeGift: -1,
    // showFloatBar: false,
  },
  myGiftPage: {
    filterText: '',
    itemsVoucher: [],
    showConfirm: false,
    dataConfirm: {},
    dataConfirmSend: false,
    showRedemptionForm: false,
    dataRedemptionForm: {
      customer_name: '',
      customer_details: '',
      staff_pin: '',
    },
    dataRedemptionFormErrorMessage: '',
    expandRedemptionForm: false,
    showCode: false,
    dataCode: {},
    showVoucherDetails: false,
    showRedemptionSuccess: false,
    dataRedemptionSuccess: {},
  },
  perksPage: {
    perksList: [],
    perksMeta: {},
    perksStatus: 'pending',
    perksMerchantDetail: {},
  },
};

let AppContext = createContext();

// add reducer for change state using dispatch
let reducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case 'SET_GCARD_INFO':
      newState.gcardinfo = action.data;
      newState.whitelabel = action.data ? action.data['whitelabel-theme'] : undefined;
      break;
    case 'SET_ECARD':
      newState.ecard = action.data;
      newState.whitelabel = action.data['whitelabel-theme'];
      break;
    case 'SET_REDACTED_EMAIL':
      newState.redacted_email = action.data;
      break;
    case 'SET_ECARD_MEMBER':
      newState.ecard.memberid = action.memberid || '';
      break;
    case 'SET_KEY':
      newState.key = action.data;
      break;
    case 'SET_OTP':
      newState.otp = action.data;
      break;
    case 'SET_WHITELABEL':
      newState.whitelabel = action.data;
      delete newState.gcardinfo;
      break;
    case 'SET_GIFTS_STATUS':
      newState.giftsStatus = action.data;
      break;
    case 'SET_GIFTS':
      newState.gifts = action.data;
      break;
    case 'SET_DOMLOADED':
      newState.domContentLoaded = true;
      break;
    case 'SHOW_TOPUP':
      newState.modalTopupShow = true;
      newState.topupAmount = action.topupAmount || 0;
      newState.topupAction = action.topupAction || null;
      break;
    case 'HIDE_TOPUP':
      newState.modalTopupShow = false;
      break;
    case 'SHOW_GREETING':
      newState.modalGreetingCardShow = true;
      break;
    case 'HIDE_GREETING':
      newState.modalGreetingCardShow = false;
      break;
    case 'SHOW_HELP':
      newState.modalHelpShow = true;
      break;
    case 'SET_FAQS':
      newState.faqs = action.data || [];
      break;
    case 'HIDE_HELP':
      newState.modalHelpShow = false;
      break;
    case 'SET_LOGOUT':
      // newState = initialState;
      delete newState.gcardinfo;
      delete newState.ecard;
      delete newState.key;
      delete newState.session_id;
      delete newState.member;
      break;
    case 'SET_MODAL_GIFT_TYPE':
      newState.modalGiftType = action.data || { show: false, type: '' };
      break;
    case 'SET_STRAPI_DATA':
      newState.strapiData = action.data || {};
      break;
    case 'SET_IPADDRESS':
      newState.ipaddress = action.data || '';
      break;
    case 'SET_MODAL_VOUCHER_DESC':
      newState.modalVoucherDesc = action.data || { show: false, voucher: {} };
      break;
    case 'SET_MODAL_VOUCHER_HOW_TO':
      newState.modalVoucherHowTo = action.data || { show: false, voucher: {} };
      break;
    case 'SET_SESSION_ID':
      newState.session_id = action.data || '';
      newState.member = action.member || {};
      break;
    case 'GETSTARTED_PAGE':
      newState.getStartedPage = {
        ...state.getStartedPage,
        ...action.payload,
      };
      // console.log('new get started page', newState);
      break;
    case 'SEARCH_PAGE':
      newState.searchPage = {
        ...state.searchPage,
        ...action.payload,
      };
      break;
    case 'MERCHANT_PAGE':
      newState.merchantPage = {
        ...state.merchantPage,
        ...action.payload,
      };
      break;
    case 'MYGIFT_PAGE':
      newState.myGiftPage = {
        ...state.myGiftPage,
        ...action.payload,
      };
      break;
    case 'MYGIFT_PAGE_RESET_SLIDE':
      let newVouchers = [...state.myGiftPage.itemsVoucher];
      newVouchers = newVouchers.map((voucher) => {
        // console.log('voucher', voucher, dataConfirm)
        if (voucher.code === action.payload.voucherCode) {
          voucher._reset = 1;
        }
        return voucher;
      });
      newState.myGiftPage.itemsVoucher = newVouchers;
      break;
    case 'SET_HOW_TO_REDEEM_GIFT_BOX':
      newState.howToRedeemGiftBox = action.payload;
      break;
    case 'SET_PERKS_LIST':
      newState.perksPage = {
        ...state.perksPage,
        perksList:
          action.data.meta.pagination.page > 1
            ? [...state.perksPage.perksList, ...action.data?.data]
            : action.data?.data,
        perksMeta: action.data?.meta,
        perksStatus: 'loaded',
      };
      break;
    case 'SET_PERKS_MERCHANT_DETAIL':
      newState.perksPage = {
        ...state.perksPage,
        perksMerchantDetail: action.data,
      };
      break;
    default:
  }
  // console.log('New State', newState)
  return newState;
};

function AppContextProvider(props) {
  const [whitelabel, setWhitelabel] = useLocalStorage(
    'redemption-data-whitelabel',
    initialState.whitelabel
  );
  const [gcardinfo, setGcardInfo] = useLocalStorage(
    'redemption-data-gcardinfo',
    initialState.gcardinfo
  );
  const [strapiData, setStrapiData] = useLocalStorage(
    'redemption-strapi-data',
    initialState.strapiData
  );
  const gcardKey = localStorage.getItem('redemption-data-key');
  const session_id = localStorage.getItem('session_id') || '';
  const otpCode = localStorage.getItem('redemption-data-otp-code');

  let [state, dispatch] = useReducer(reducer, {
    ...initialState,
    whitelabel,
    gcardinfo,
    strapiData,
    key: gcardKey,
    session_id,
    otp: otpCode ? { code: otpCode } : null,
  });
  let value = { state, dispatch };
  useEffect(() => {
    if (state.strapiData) {
      setStrapiData(state.strapiData);
    }
  }, [setStrapiData, state.strapiData]);
  useEffect(() => {
    if (state.whitelabel) {
      setWhitelabel(state.whitelabel);
    }
  }, [setWhitelabel, state.whitelabel]);
  useEffect(() => {
    if (state.gcardinfo) {
      setGcardInfo(state.gcardinfo);
    }
  }, [setGcardInfo, state.gcardinfo]);
  useEffect(() => {
    if (state.key) {
      localStorage.setItem('redemption-data-key', state.key);
    } else {
      localStorage.removeItem('redemption-data-key');
    }
  }, [state.key]);
  useEffect(() => {
    if (state.otp) {
      // alert('set otp ' + state.otp.code);
      localStorage.setItem('redemption-data-otp-code', state.otp.code);
    }
  }, [state.otp]);
  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}

export { AppContext, AppContextProvider };
