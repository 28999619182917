import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FaMailBulk, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { PostMISC } from '../../../services/Connection';
import { AppContext } from '../../../states';

const initialFormData = {
  name: '',
  email: '',
  phonenumber: '',
  message: '',
};

const StayInTouch = () => {
  const { state } = useContext(AppContext);

  const [formData, setFormData] = useState(initialFormData);
  const [isSendForm, setIsSendForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleFormChange = (e) => {
    let newState = { ...formData };
    newState[e.target.name] = e.target.value;
    setFormData(newState);
  };

  useEffect(() => {
    if (state.modalHelpShow) {
      setSuccessMessage('');
      setErrorMessage('');
      setFormData(initialFormData);
    }
  }, [state.modalHelpShow]);

  const enquirySubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    let dataToSend = {
      senderEmail: 'support@giftano.com',
      recipientEmails: 'support@giftano.com',
      subject: 'Enquiry from Giftano Card Redemption ' + formData.name,
      contenthtml: `<strong>Name :</strong> ${formData.name}<br/>
                    <strong>Email :</strong> ${formData.email}<br/>
                    <strong>Phone Number :</strong> ${formData.phonenumber}<br/>
                    <strong>Card number :</strong> ${state.ecard.number}<br/>
                    <strong>Message :</strong> <br/>
                    ${formData.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
    };
    setIsSendForm(true);
    try {
      let res = await PostMISC('sendemail/', dataToSend);
      if (res.status === 1) {
        setSuccessMessage(
          'Thank You<br/>Your enquiry has been sent. <br/>We will get back to you as soon as we can.'
        );
        setErrorMessage('');
        setFormData(initialFormData);
      } else {
        setSuccessMessage('');
        setErrorMessage("Your enquiry wasn't sent. Please try again.");
      }
    } catch (err) {
      toast.error(ErrorMessages.error_network);
    }
    setIsSendForm(false);
  };
  return (
    <Row className='my-4'>
      <Col md='6'>
        <h4 className='title'>Need to get in touch?</h4>
        <p>Send us a message and we will get in touch as soon as possible. </p>
      </Col>
      <Col md='6'>
        <Form onSubmit={enquirySubmit}>
          <Card className='card-form-email mt-4'>
            <Card.Body>
              {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}

              {successMessage ? (
                <div>
                  <p
                    className='text-center'
                    style={{
                      fontSize: '48px',
                      margin: '8px auto 4px',
                    }}
                  >
                    <FaMailBulk />
                  </p>
                  <p
                    className='text-center'
                    dangerouslySetInnerHTML={{
                      __html: successMessage,
                    }}
                  ></p>
                </div>
              ) : (
                <React.Fragment>
                  <Form.Group>
                    <Form.Label>Name *</Form.Label>
                    <Form.Control
                      type='text'
                      required
                      name='name'
                      placeholder='Enter your name'
                      value={formData.name}
                      onChange={handleFormChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      type='email'
                      required
                      name='email'
                      placeholder='Enter your email address'
                      value={formData.email}
                      onChange={handleFormChange}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone Number *</Form.Label>
                    <Form.Control
                      type='tel'
                      name='phonenumber'
                      placeholder='Enter your phone number'
                      value={formData.phonenumber}
                      onChange={handleFormChange}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message *</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      required
                      name='message'
                      placeholder='How can we help you?'
                      value={formData.message}
                      onChange={handleFormChange}
                    ></Form.Control>
                  </Form.Group>
                  <div className='mt-3'>
                    <Button block variant='primary' type='submit' disabled={isSendForm}>
                      {isSendForm && <FaSpinner className='icon-spin mr-2' />} SUBMIT
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default StayInTouch;
