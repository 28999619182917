/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { AppContext } from '../../../states';
import { useHistory } from 'react-router-dom';
import GiftBoxCarousel from './GiftBoxCarousel';
import ProductPictures from './ProductPictures';
import HowGiftWork from './HowGiftWork';
import VoucherQty from './VoucherQty';
import { CurrencyFormat } from '../../../helper/CurrencyFormat';

const Gifts = () => {
  const { state, dispatch } = useContext(AppContext);
  const { gifts, isRedeem, activeGift } = state.merchantPage;
  const [selectedIdx, setSelectedIdx] = useState();
  const [timeoutMs, setTimeoutMs] = useState(700);
  const history = useHistory();

  useEffect(() => {
    if (activeGift) {
      setTimeout(() => {
        const activeSlide = document.querySelector(
          '#carousel_item_gift_' + activeGift
        );
        if (activeSlide) {
          const height = activeSlide.clientHeight;
          activeSlide.closest('.slider-wrapper').style.height = height + 'px';
          setTimeoutMs(200);
        }
      }, timeoutMs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGift]);

  if (state.ecard.is_gift_box == 1 && activeGift === -1) return '';

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;

  let desktop;
  if (hidePrice && gifts.length > 1) {
    desktop = '3';
  } else if (!hidePrice && gifts.length > 1) {
    desktop = '4';
  } else if (hidePrice && gifts.length == 1) {
    desktop = '6';
  } else {
    desktop = '6';
  }
  let tablet;
  if (hidePrice && gifts.length > 1) {
    tablet = '3';
  } else if (!hidePrice && gifts.length > 1) {
    tablet = '6';
  } else if (hidePrice && gifts.length == 1) {
    tablet = '6';
  } else {
    tablet = '12';
  }

  let mobileImage;
  if (hidePrice && gifts.length == 1) {
    mobileImage = '12';
  } else if (!hidePrice && gifts.length > 1) {
    mobileImage = '5';
  } else if (hidePrice && gifts.length > 1) {
    mobileImage = '5';
  } else {
    mobileImage = '12';
  }

  let mobileActions;
  if (hidePrice && gifts.length == 1) {
    mobileActions = '12';
  } else if (!hidePrice && gifts.length > 1) {
    mobileActions = '7';
  } else if (hidePrice && gifts.length > 1) {
    mobileActions = '7';
  } else {
    mobileActions = '12';
  }

  return (
    <>
      {state.ecard.is_gift_box == 1 ? (
        <GiftBoxCarousel />
      ) : (
        <Card className='voucher-card'>
          {gifts.length > 1 && (
            <Card.Title className='desc font-weight-bold'>
              {hidePrice ? 'What does it include' : 'Gift(s)'}
            </Card.Title>
          )}
          <ListGroup className='voucher-list'>
            {gifts.map((gift, idx) => {
              if (
                hidePrice &&
                state.ecard.total_usage > 0 &&
                parseFloat(gift.price) > parseFloat(state.ecard.balance)
              ) {
                return '';
              }
              const deliveryFee = gift.primary_extrafirst || 0;
              return (
                <ListGroup.Item
                  key={'gift_item_' + idx}
                  className={`mb-3 ${
                    gifts.length > 1 ? 'product-listing ' : 'product-featured'
                  } `}
                >
                  <Row noGutters>
                    <Col
                      lg={desktop}
                      md={tablet}
                      xs={mobileImage}
                      className='list-image'
                    >
                      <ProductPictures
                        gift={gift}
                        isMulti={gifts.length === 1 ? true : false}
                      />
                    </Col>
                    <Col
                      // md={
                      //   hidePrice &&
                      //   state.ecard.total_usage == 0 &&
                      //   gifts.length > 1
                      //     ? 6
                      //     : 7
                      // }
                      lg={
                        hidePrice &&
                        state.ecard.total_usage == 0 &&
                        gifts.length > 1
                          ? 7
                          : !hidePrice
                          ? 6
                          : 6
                      }
                      md={tablet}
                      xs={mobileActions}
                      className={
                        'px-md-2 voucher-actions-multi ' +
                        (gifts.length == 1 && ' voucher-action-single')
                      }
                    >
                      {gifts.length === 1 && gift.merchant && (
                        <p
                          className='text-primary brand-name'
                          onClick={() => {
                            history.push('/merchant/' + gift.merchant.id);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {gift.merchant.name}
                        </p>
                      )}
                      <h4 className='voucher-name'>{gift.name}</h4>
                      {gifts.length === 1 && hidePrice && (
                        <Button
                          onClick={async () => {
                            setSelectedIdx(idx);
                            // let newGifts = [...gifts]
                            // newGifts[idx].quantity = 1
                            // newGifts[idx].primary_extrafirst =
                            //   await GetShippingCost(newGifts[idx], state.ecard.company.id)
                            dispatch({
                              type: 'MERCHANT_PAGE',
                              payload: {
                                // gifts: newGifts,
                                activeGift: idx,
                                showConfirm: true,
                              },
                            });
                          }}
                          disabled={isRedeem}
                          className={'btn-select--single mb-3'}
                        >
                          {isRedeem && selectedIdx === idx && (
                            <FaSpinner className='mr-2 icon-spin' />
                          )}
                          SELECT
                        </Button>
                      )}
                      {gifts.length === 1 && !hidePrice && (
                        <>
                          <Col
                            lg={{ span: '2', offset: '0' }}
                            md={{ span: '2', offset: '0' }}
                            xs={{ span: '6', offset: '0' }}
                            className=' voucher-actions'
                          >
                            <h4
                              className='voucher-price text-center'
                              style={{ color: 'var(--gf-warning-300)' }}
                            >
                              <CurrencyFormat value={gift.price} />
                            </h4>
                            {deliveryFee > 0 && (
                              <p>
                                +<CurrencyFormat value={deliveryFee} /> Delivery
                                Fee
                              </p>
                            )}
                          </Col>
                          <Row className=' voucher-actions__bottom mb-4'>
                            <Col md={3} xs={4}>
                              <h5>Quantity</h5>
                            </Col>
                            <Col md={3} xs={4}>
                              <VoucherQty gift={gift} idx={idx} />
                            </Col>
                            <Col md={6} xs={4}>
                              <Button
                                onClick={async () => {
                                  setSelectedIdx(idx);
                                  // let newGifts = [...gifts]
                                  // newGifts[idx].quantity = 1
                                  // newGifts[idx].primary_extrafirst =
                                  //   await GetShippingCost(newGifts[idx], state.ecard.company.id)
                                  dispatch({
                                    type: 'MERCHANT_PAGE',
                                    payload: {
                                      // gifts: newGifts,
                                      activeGift: idx,
                                      showConfirm: true,
                                    },
                                  });
                                }}
                                disabled={isRedeem}
                                className={'btn-list__product '}
                              >
                                {isRedeem && selectedIdx === idx && (
                                  <FaSpinner className='mr-2 icon-spin' />
                                )}
                                BUY
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      {gifts.length == 1 && (
                        <p
                          className='voucher-desc'
                          dangerouslySetInnerHTML={{
                            __html:
                              gift.description &&
                              gift.description
                                .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                                .replace(/\n/g, '<br/>'),
                          }}
                        ></p>
                      )}

                      {gifts.length === 1 && (
                        <div className='mt-4'>
                          <HowGiftWork gift={gift} />
                          {gift.tnc && (
                            <div>
                              <Button
                                size='sm'
                                variant='link'
                                className='links'
                                onClick={() => {
                                  dispatch({
                                    type: 'MERCHANT_PAGE',
                                    payload: {
                                      showModalTnc: true,
                                    },
                                  });
                                }}
                              >
                                Read Gift Terms &amp; Conditions
                              </Button>
                            </div>
                          )}
                        </div>
                      )}

                      {hidePrice && state.ecard.total_usage == 0 ? (
                        <Col
                          lg={{ span: '2', offset: '0' }}
                          md={{ span: '2', offset: '0' }}
                          xs={{ span: '4', offset: '0' }}
                          className='voucher-actions d-md-none'
                        >
                          <Button
                            onClick={async () => {
                              setSelectedIdx(idx);
                              // let newGifts = [...gifts]
                              // newGifts[idx].quantity = 1
                              // newGifts[idx].primary_extrafirst =
                              //   await GetShippingCost(newGifts[idx], state.ecard.company.id)
                              dispatch({
                                type: 'MERCHANT_PAGE',
                                payload: {
                                  // gifts: newGifts,
                                  activeGift: idx,
                                  showConfirm: true,
                                },
                              });
                            }}
                            disabled={isRedeem}
                            className={
                              'btn-list' +
                              (gifts.length > 1 ? ' multi-gift' : ' d-none')
                            }
                          >
                            {isRedeem && selectedIdx === idx && (
                              <FaSpinner className='mr-2 icon-spin' />
                            )}
                            SELECT
                          </Button>
                        </Col>
                      ) : !hidePrice ? (
                        <>
                          {gifts.length > 1 ? (
                            <Col
                              lg={{ span: '2', offset: '0' }}
                              md={{ span: '2', offset: '0' }}
                              xs={{ span: '12', offset: '0' }}
                              className=' voucher-actions d-md-none'
                            >
                              <h4 className='voucher-price text-center'>
                                <CurrencyFormat value={gift.price} />
                              </h4>
                              {deliveryFee > 0 && (
                                <p>
                                  +<CurrencyFormat value={deliveryFee} />{' '}
                                  Delivery Fee
                                </p>
                              )}

                              <VoucherQty gift={gift} idx={idx} />
                            </Col>
                          ) : null}
                        </>
                      ) : (
                        ''
                      )}
                    </Col>
                    {hidePrice && state.ecard.total_usage == 0 ? (
                      <Col
                        lg={{ span: '2', offset: '0' }}
                        md={{ span: '2', offset: '0' }}
                        xs={{ span: '4', offset: '0' }}
                        className='voucher-actions d-none d-md-flex'
                      >
                        <Button
                          onClick={async () => {
                            setSelectedIdx(idx);
                            // let newGifts = [...gifts]
                            // newGifts[idx].quantity = 1
                            // newGifts[idx].primary_extrafirst =
                            //   await GetShippingCost(newGifts[idx], state.ecard.company.id)
                            dispatch({
                              type: 'MERCHANT_PAGE',
                              payload: {
                                // gifts: newGifts,
                                activeGift: idx,
                                showConfirm: true,
                              },
                            });
                          }}
                          disabled={isRedeem}
                          className={
                            'btn-list' +
                            (gifts.length > 1 ? ' multi-gift' : ' d-none')
                          }
                        >
                          {isRedeem && selectedIdx === idx && (
                            <FaSpinner className='mr-2 icon-spin' />
                          )}
                          SELECT
                        </Button>
                      </Col>
                    ) : !hidePrice ? (
                      <>
                        {gifts.length > 1 ? (
                          <Col
                            lg={{ span: '2', offset: '0' }}
                            md={{ span: '2', offset: '0' }}
                            xs={{ span: '4', offset: '0' }}
                            className=' voucher-actions d-none d-md-block'
                          >
                            <h4 className='voucher-price text-center'>
                              <CurrencyFormat value={gift.price} />
                            </h4>
                            {deliveryFee > 0 && (
                              <p>
                                +<CurrencyFormat value={deliveryFee} /> Delivery
                                Fee
                              </p>
                            )}

                            <VoucherQty gift={gift} idx={idx} />
                          </Col>
                        ) : null}
                      </>
                    ) : (
                      ''
                    )}
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
      )}
    </>
  );
};

export default Gifts;
