import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AppContext } from '../../../states';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import CardListSkeleton from './CardListSkeleton';
// import CardListSkeletonMobile from './CardListSkeletonMobile';
import Gifts from './Gifts';
import OtherGifts from './OtherGifts';
import ScrollToTop from 'react-scroll-to-top';
import { BiArrowToTop } from 'react-icons/bi';

const GiftBoxView = () => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <div className='gift-box'>
      <div className='mt-2 mb-4 d-flex justify-content-between'>
        <Button
          className='btn-gift-control'
          variant='clear'
          disabled={state.merchantPage.activeGift === 0}
          onClick={() => {
            let nextIdx = state.merchantPage.activeGift - 1;
            if (nextIdx < 0) nextIdx = 0;
            dispatch({
              type: 'MERCHANT_PAGE',
              payload: {
                activeGift: nextIdx,
              },
            });
          }}
        >
          <FaArrowLeft className='mr-2' />{' '}
          <span className='d-inline-block d-sm-none'>Previous gift</span>
          <span className='d-none d-sm-inline-block'>View previous gift</span>
        </Button>
        <Button
          className='btn-gift-control'
          variant='clear'
          disabled={
            state.merchantPage.activeGift ===
            state.merchantPage.gifts.length - 1
          }
          onClick={() => {
            let nextIdx = state.merchantPage.activeGift + 1;
            if (nextIdx > state.merchantPage.gifts.length - 1)
              nextIdx = state.merchantPage.gifts.length - 1;
            dispatch({
              type: 'MERCHANT_PAGE',
              payload: {
                activeGift: nextIdx,
              },
            });
          }}
        >
          <span className='d-inline-block d-sm-none'>Next gift</span>
          <span className='d-none d-sm-inline-block'>View next gift</span>{' '}
          <FaArrowRight className='ml-2' />
        </Button>
      </div>

      {/* <CardListSkeleton
        className='card-skeleton'
        speed={2}
        width={1200}
        height={600}
        viewBox='0 0 1200 600'
        style={{ width: '100%' }}
      />
      <CardListSkeletonMobile
        className='card-skeleton-mobile'
        speed={2}
        width={'100%'}
        height={600}
        viewBox='0 0 400 600'
        style={{ width: '100%' }}
      /> */}

      <Gifts />
      <OtherGifts />
      <ScrollToTop smooth component={<BiArrowToTop />} />
    </div>
  );
};

export default GiftBoxView;
