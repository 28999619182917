import { PostPublic } from './Connection';

export const loadGifts = async (
  corporateId,
  sample = 0,
  totalSample = 6,
  voucherId = null,
  marketCountry = 'SG'
) => {
  let postValues = {
    marketCountry: marketCountry,
    // country: marketCountry,
    'no-discount': 1,
    version: 3,
    corporateid: corporateId,
    combine_cash_voucher: 1,
  };
  if (sample) {
    postValues = {
      ...postValues,
      sample: sample,
      total_sample: totalSample,
    };
  }
  if (voucherId) {
    postValues.vouchertypeid = voucherId;
  }
  let resp = await PostPublic('gift-catalogue/', postValues);
  return resp;
};

export const loadGiftById = async (corporateId, voucherId, country) => {
  let postValues = {
    'no-discount': 1,
    version: 3,
    corporateid: corporateId,
    combine_cash_voucher: 1,
    vouchertypeid: voucherId,
    marketCountry: country,
  };
  let resp = await PostPublic('gift-catalogue/', postValues);
  return resp;
};

export const GetShippingCost = async (gift, companyid = '') => {
  const res = await PostPublic('shipping-cost/', {
    companyid: companyid,
    vouchertype: gift.id,
    quantity: gift.quantity,
    dymethod: gift.primary_delivery,
  });
  // console.log('result shipping cost', res);
  if (res.status === 1) {
    return res.data;
  }
  return 0;
};

export const GetCatalogLastUpdate = async (corporateId, country) => {
  try {
    let res = await PostPublic('gift-catalogue-last-update/', {
      'no-discount': 1,
      version: 3,
      corporateid: corporateId,
      combine_cash_voucher: 1,
      marketCountry: country,
    });
    if (res.status === 1) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const GetProductPictures = async (productId) => {
  try {
    let res = await PostPublic('gift/photo_profiles/', {
      id: productId,
    });
    if (res.status === 1) {
      return res.data;
    }
  } catch (err) {
    console.log('error fetch profile pictures', err.message || err);
  }
  return null;
};

export const GetMerchantBasic = async (merchantId) => {
  try {
    let res = await PostPublic('merchantbasic/', {
      id: merchantId,
    });
    if (res.status === 1) {
      return res.data;
    }
  } catch (err) {
    console.log('error fetch merchant detail', err.message || err);
  }
  return null;
};
