/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import {
  Button,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../states';

const MoreGifts = () => {
  const { order_id } = useParams();
  const { state } = useContext(AppContext);
  const history = useHistory();

  let voucher = {};
  if (state.ecard && state.ecard.transactions) {
    let found = false;
    state.ecard.transactions.forEach((t) => {
      if (t.id !== order_id && found === false) {
        if (t.vouchers && t.vouchers.length > 0) {
          found = true;
          voucher = t.vouchers[0];
        }
      }
    });
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Col>
        <ListGroup className='voucher-list'>
          <ListGroupItem>
            <Row>
              <Col className='voucher-image' md='2' xs='4'>
                <Image
                  src={voucher.file || voucher.merchant.logo}
                  rounded
                  fluid
                  className='merchant-images'
                />
              </Col>
              <Col md='5' xs='8' className='text-left'>
                {voucher['is_physical'] == '1' ||
                voucher['is-evoucher'] == '1' ? (
                  ''
                ) : (
                  <label className='voucher-tag'>
                    Physical Voucher send via Mail
                  </label>
                )}

                <h4 className='voucher-price'>{voucher.value}</h4>
                <p className='voucher-merchant-name'>{voucher.merchant.name}</p>
                {voucher.description && voucher.description.trim() !== '' ? (
                  <React.Fragment>
                    <div className='d-flex justify-content-between'>
                      <p className='voucher-description'>
                        {voucher.description
                          .replace(/(<([^>]+)>)|&nbsp;/gi, '')
                          .replace(/\n/g, '<br/>')}
                      </p>
                    </div>
                    <Button size='sm' variant='link' onClick={() => {}}>
                      Read Gift Terms &amp; Conditions
                    </Button>
                    <Button size='sm' variant='link' onClick={() => {}}>
                      How to use &gt;&gt;
                    </Button>
                  </React.Fragment>
                ) : (
                  <>
                    <Button size='sm' variant='link' onClick={() => {}}>
                      Read Gift Terms &amp; Conditions
                    </Button>
                    <Button size='sm' variant='link' onClick={() => {}}>
                      How to use &gt;&gt;
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Col>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{
          position: 'absolute',
          //   background: 'rgb(255,255,255)',
          background:
            'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)',
          top: '0',
          width: '100%',
          height: '110%',
          cursor: 'pointer',
        }}
        onClick={() => {
          history.push('/mygifts');
        }}
      >
        <div className='text-center'>
          <img
            src={
              'https://giftano.imgix.net/assets/Animation-V1.gif?auto=format'
            }
            alt={'More Gift'}
            width={60}
            height={60}
          />
          <p
            style={{
              fontWeight: '700',
              fontSize: '18px',
              letterSpacing: '0.1px',
            }}
          >
            View All Gift(s)
          </p>
        </div>
      </div>
    </div>
  );
};
export default MoreGifts;
