/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import MyNavbar from '../../components/MyNavbar';
import { AppContext } from '../../states';
import MyFooter from '../../components/MyFooter';
import { Helmet } from 'react-helmet';
import LoadECard from '../../components/LoadECard';
import TabBarBottom from '../../components/TabBarBottom';
import { flattenObj } from '../../helper/FlattenObject';
import Clamp from 'react-multiline-clamp';
import ModalDetailPerk from './components/ModalDetailPerk';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaSpinner } from 'react-icons/fa';
import { getPerks } from '../../services/Connection';

const PerksPage = () => {
  const { state, dispatch } = useContext(AppContext);
  const [modalDetail, setModalDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loadingGet, setLoadingGet] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      try {
        let res = await getPerks({ size: 20, page: 0 });
        if (res.code === 200) {
          let flattenedData = flattenObj(res);
          dispatch({
            type: 'SET_PERKS_LIST',
            data: {
              data: flattenedData,
              meta: res.meta,
            },
          });
        }
      } catch (err) {
        console.log('error get content', err);
      }
    };
    if (state?.ecard?.country === 'SG' && state.perksPage.perksStatus === 'pending') {
      loadContent();
    }
  }, [dispatch, state.perksPage.perksStatus, state.ecard]);

  useEffect(() => {
    if (state.perksPage?.perksList?.length > 0) {
      setData(state.perksPage.perksList);
      setPagination(state.perksPage?.perksMeta?.pagination);
    }
  }, [state.perksPage]);

  // useEffect(() => {
  //   if (data.length > 0) {
  //     let loadMerchant = async () => {
  //       try {
  //         let listMcId = data.map((perk) => perk.merchant_id);
  //         // const uniqueElements = [...listMcId.difference(set2)];
  //         let res = await getMerchantbyIDs({
  //           size: 20,
  //           IDs: listMcId,
  //         });
  //         if (res.data.length > 0) {
  //           let storeMerchant = {};
  //           listMcId = listMcId.forEach((mcId) => {
  //             let merchant = res.data.find((mc) => mc.id === mcId);
  //             storeMerchant[mcId] = merchant || 'no-merchant';
  //             // return { [mcId]: merchant || 'no-merchant' };
  //           });
  //           dispatch({ type: 'SET_PERKS_MERCHANT_DETAIL', data: storeMerchant });
  //         }
  //       } catch (err) {
  //         console.log('error get merchant', err);
  //       }
  //     };
  //     loadMerchant();
  //   }
  // }, [data, dispatch]);

  // useEffect(() => {
  //   console.log(
  //     'state.perksPage.perksMerchantDetail',
  //     state.perksPage.perksMerchantDetail,
  //     state.perksPage.perksMerchantDetail['MCVUA39MAT']
  //   );
  //   setDataMerchant(state.perksPage.perksMerchantDetail);
  // }, [state.perksPage.perksMerchantDetail]);

  return (
    <div className='page-perks'>
      <Helmet>
        <title>Perks</title>
      </Helmet>
      <div className='rectangle-header'>
        <LoadECard>
          <Container className='min-height-alter'>
            <MyNavbar />

            <Row>
              <Col md='12' className='title'>
                <h5 className='text-black font-weight-bold text-center text-md-left  mb-5'>
                  Giftano Perks
                </h5>
              </Col>
            </Row>
            {state?.ecard?.country === 'SG' && (
              <>
                {/* <Row className='mb-5 box-list'> */}
                <InfiniteScroll
                  dataLength={data.length}
                  next={async () => {
                    if (!loadingGet) {
                      setLoadingGet(true);
                      try {
                        let res = await getPerks({
                          size: pagination?.pageSize,
                          page: pagination?.page + 1,
                        });
                        if (res.code === 200) {
                          let flattenedData = flattenObj(res);
                          if (res.data) {
                            dispatch({
                              type: 'SET_PERKS_LIST',
                              data: {
                                data: flattenedData,
                                meta: res.meta,
                              },
                            });
                          }
                        }
                      } catch (err) {
                        console.log('error get content', err);
                      }
                      setLoadingGet(false);
                    }
                  }}
                  hasMore={data.length < pagination?.total ? true : false}
                  loader={
                    <Col className='text-center col-12'>
                      <FaSpinner className='icon-spin mr-2' /> Loading...
                    </Col>
                  }
                  className='row mb-5 box-list overflow-hidden'
                >
                  {data.map((perk, index) => {
                    return (
                      <Col md='3' key={index}>
                        <Card
                          className='box-perk'
                          onClick={() => {
                            setDataDetail(perk);
                            setModalDetail(true);
                          }}
                        >
                          <Card.Img
                            variant='top'
                            src={`https://giftano.com/cdn-cgi/image/format=auto,height=300/${perk.image.url}`}
                            className='img-perk'
                          />
                          <Card.Body>
                            {/* {dataMerchant[perk.merchant_id] &&
                          dataMerchant[perk.merchant_id] !== 'no-merchant' && ( */}
                            {perk.merchant && (
                              <div className='d-flex align-items-center merchant-description'>
                                <div
                                  className='merchant-logo flex-shrink-0'
                                  style={
                                    perk?.logo || perk?.merchant?.logo?.cc_size?.filename
                                      ? // ? {
                                        //     backgroundImage: `url(${logo})`,
                                        //     backgroundSize: 'cover',
                                        //     backgroundPosition: 'center',
                                        //   }
                                        {
                                          width: 'auto',
                                          height: '24px',
                                          background: 'transparent',
                                        }
                                      : {}
                                  }
                                >
                                  {perk.logo ? (
                                    <img
                                      src={perk.logo.url}
                                      alt={perk.merchant?.name || 'Logo'}
                                      style={{ height: '100%' }}
                                    />
                                  ) : perk.merchant?.logo?.cc_size?.filename ? (
                                    <img
                                      src={perk.merchant?.logo?.cc_size?.filename}
                                      alt={perk.merchant?.name || 'Logo'}
                                      style={{ height: '100%' }}
                                    />
                                  ) : (
                                    perk.merchant?.name?.substring(0, 1)
                                  )}
                                </div>
                                <div className='merchant-name'>
                                  <Clamp withTooltips lines={1} maxLines={1}>
                                    {perk.merchant?.name}
                                  </Clamp>
                                </div>
                              </div>
                            )}
                            <Card.Title className='title-perk'>
                              <Clamp withTooltips lines={1} maxLines={1}>
                                {perk.title}
                              </Clamp>
                            </Card.Title>
                            <Clamp withTooltips lines={2} maxLines={2}>
                              <div className='short-description'>{perk.short_description}</div>
                            </Clamp>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </InfiniteScroll>
                {/* </Row> */}
                {modalDetail && (
                  <ModalDetailPerk
                    show={modalDetail}
                    setShow={() => {
                      setModalDetail(!modalDetail);
                    }}
                    data={dataDetail}
                  />
                )}
              </>
            )}
          </Container>
          <MyFooter />
          <TabBarBottom />
        </LoadECard>
      </div>
    </div>
  );
};

export default PerksPage;
