/* eslint-disable eqeqeq */
import React, { useContext, useRef, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { BiChevronDown, BiChevronLeft } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../states';
import ModalFilter from './ModalFilter';

const Searchbar = () => {
  const { state, dispatch } = useContext(AppContext);
  const { searchText, isSortBy, filterCount } = state.searchPage;
  const [modalFilterShow, setModalFilterShow] = useState(false);
  const closeModalFilter = () => setModalFilterShow(false);
  const dropdownTogglePriceRef = useRef();
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  return (
    <div className='float'>
      <Row className='mb-2'>
        {hidePrice && parseFloat(state.ecard.total_usage) > 0 ? (
          <Col md='12' lg='12'>
            <Button
              as={Link}
              to='/getstarted'
              variant='link'
              className='btn-back text-white'
            >
              <BiChevronLeft className='mr-2' />
              Back
            </Button>
          </Col>
        ) : (
          <>
            {/* <Col md='2' lg='1'>
              <Button
                as={Link}
                to='/getstarted'
                variant='link'
                className='btn-back text-white'
              >
                <BiChevronLeft className='mr-2' />
                Back
              </Button>
            </Col> */}
            <Col
              md={state.ecard.is_gift_box == 0 ? 7 : 10}
              lg={state.ecard.is_gift_box == 0 ? 8 : 11}
              className={`page-search__bar ${
                state.ecard.is_gift_box == 0 ? '' : 'hide'
              }`}
            >
              <InputGroup className='nav-input mb-3'>
                <FormControl
                  placeholder='Search for a gift'
                  aria-label='Search for a gift'
                  value={searchText}
                  onChange={(e) =>
                    dispatch({
                      type: 'SEARCH_PAGE',
                      payload: { searchText: e.target.value },
                    })
                  }
                />

                <InputGroup.Append>
                  <Button variant='success'>
                    <FiSearch color={'gray'} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            {state.ecard.is_gift_box == 0 && (
              <Col md='5' lg='4'>
                <Row
                  className='page-search__utility'
                  style={{ right: '0', position: 'absolute' }}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      ref={dropdownTogglePriceRef}
                      variant='link'
                      className='mr-1'
                    >
                      Sort By
                      <BiChevronDown className='ml-3' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {!hidePrice && (
                        <React.Fragment>
                          <Dropdown.Item
                            onClick={() =>
                              dispatch({
                                type: 'SEARCH_PAGE',
                                payload: { isSortBy: 'price-a' },
                              })
                            }
                          >
                            <FaCheck
                              className={
                                'mr-2 ' +
                                (isSortBy !== 'price-a' ? 'invisible' : '')
                              }
                            />
                            Price Low to High
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            onClick={() =>
                              dispatch({
                                type: 'SEARCH_PAGE',
                                payload: { isSortBy: 'price-d' },
                              })
                            }
                          >
                            <FaCheck
                              className={
                                'mr-2 ' +
                                (isSortBy !== 'price-d' ? 'invisible' : '')
                              }
                            />
                            Price High to Low
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </React.Fragment>
                      )}
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({
                            type: 'SEARCH_PAGE',
                            payload: { isSortBy: 'name-a' },
                          })
                        }
                      >
                        <FaCheck
                          className={
                            'mr-2 ' + (isSortBy !== 'name-a' ? 'invisible' : '')
                          }
                        />
                        Order A-Z
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() =>
                          dispatch({
                            type: 'SEARCH_PAGE',
                            payload: { isSortBy: 'name-d' },
                          })
                        }
                      >
                        <FaCheck
                          className={
                            'mr-2 ' + (isSortBy !== 'name-d' ? 'invisible' : '')
                          }
                        />
                        Order Z-A
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Button
                                    variant="link"
                                    onClick={() => {
                                    setViewBy(viewBy === 'brand' ? 'gift' : 'brand')
                                    if (viewBy === 'brand') {
                                        //clear filter category
                                        setSelectedKategori([
                                        "TAG754E83T9",
                                        "TAGMHJI1BD6"
                                        ]);
                                    } else {
                                        setSelectedKategori([]);
                                    }
                                    }}
                                >
                                    <FaEye className="mr-1" />
                                    {viewBy === 'brand' ? 'View By Gift' : 'View By Brand'}
                                </Button> */}

                  <Button
                    variant='link'
                    className='dropdown--filter'
                    onClick={() => setModalFilterShow(true)}
                  >
                    Other Filters
                    {filterCount > 0 && (
                      <span className='filter-count ml-1'>{filterCount}</span>
                    )}
                    <BiChevronDown />
                  </Button>

                  <ModalFilter
                    show={modalFilterShow}
                    onHide={closeModalFilter}
                  />
                </Row>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default Searchbar;
