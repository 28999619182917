import React from 'react';
import ContentLoader from 'react-content-loader';

const ListingSkeletonMobile = (props) => (
  <ContentLoader
    backgroundColor='var(--gf-neutral-300)'
    foregroundColor='var(--gf-neutral-300)'
    {...props}
  >
    <rect x='3%' y='0' rx='4' ry='4' width='46%' height='211' />
    <rect x='52%' y='0' rx='4' ry='4' width='46%' height='211' />
    <rect x='3%' y='220' rx='4' ry='4' width='46%' height='211' />
    <rect x='52%' y='220' rx='4' ry='4' width='46%' height='211' />
    <rect x='3%' y='440' rx='4' ry='4' width='46%' height='211' />
    <rect x='52%' y='440' rx='4' ry='4' width='46%' height='211' />
  </ContentLoader>
);

export default ListingSkeletonMobile;
