import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import MyImg from '../../../components/MyImg';
import { GetProductPictures } from '../../../services/LegacyService';

const ProductPictures = ({ gift, isMulti }) => {
  const [productImages, setProductImages] = useState();
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedIdx, setSelectedIdx] = useState(0);

  useEffect(() => {
    setSelectedImage(isMulti ? '' : gift.file);
    setProductImages(null);
  }, [gift, isMulti]);
  useEffect(() => {
    if (productImages && productImages.length > 0) {
      setSelectedImage(productImages[0]);
      setSelectedIdx(0);
    } else {
      setSelectedImage(gift.file);
    }
  }, [gift.file, productImages]);

  // console.log('selected image', selectedImage);
  useEffect(() => {
    if (gift.id && isMulti && productImages === null) {
      GetProductPictures(gift.id).then((res) => {
        if (res && res.length > 0) {
          setProductImages(res.map((i) => i.url).filter((i) => i));
        } else {
          setProductImages([]);
        }
      });
    }
  }, [gift.id, isMulti, productImages]);

  return (
    <>
      <MyImg src={selectedImage} rounded fluid className='merchant-images' />
      {isMulti && productImages && productImages.length > 1 && (
        <Row
          style={{
            flexWrap: 'nowrap',
            overflow: 'scroll',
            marginBottom: '20px',
            marginTop: '20px',
          }}
        >
          {productImages.map((image, idx) => (
            <Col
              xs={3}
              key={'image_item_' + idx}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedImage(image);
                setSelectedIdx(idx);
              }}
            >
              <MyImg
                src={image}
                className={`img-fluid ${
                  selectedIdx === idx ? 'active-image' : 'inactive-image'
                }`}
                onError={() => {
                  setProductImages((images) =>
                    images.filter((i) => i !== image)
                  );
                }}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default ProductPictures;
