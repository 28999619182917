import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { AppContext } from '../states';
import './ModalGreetingCard.scss';

const ModalGreetingCard = (props) => {
  const { state, dispatch } = useContext(AppContext);

  if (!state.whitelabel || !state.gcardinfo) return '';

  const { modalGreetingCardShow } = state;
  let { company, from, message, to } = state.gcardinfo;

  return (
    <Modal
      show={modalGreetingCardShow}
      centered
      onHide={() => dispatch({ type: 'HIDE_GREETING' })}
      className='top-up-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title className='top-up-title'>Your Greeting Card</Modal.Title>
      </Modal.Header>
      <Modal.Body className='greeting-card__modal'>
        <div className='section--cards greeting-card__sections d-block'>
          <div className='env-card relative greeting-card'>
            <div id='envelope' className='env-card--frontside'>
              <picture>
                <source
                  media='(max-width: 428px)'
                  srcSet={
                    state.whitelabel.settings['animation-mobile-giftcard-front']
                      .value
                  }
                />
                <source
                  media='(min-width: 429px)'
                  srcSet={
                    state.whitelabel.settings[
                      'animation-desktop-giftcard-front'
                    ].value
                  }
                />
                <img
                  src={
                    state.whitelabel.settings[
                      'animation-desktop-giftcard-front'
                    ].value
                  }
                  alt='Giftano'
                />
              </picture>
              <div id='envelope-logo' className='input-heading animation-logo'>
                <img src={company.logo} alt='Giftano' />
              </div>
              <div id='envelope-sender' className='input-heading front-sender'>
                {' '}
                {from}
              </div>
              <div
                id='envelope-recipient'
                className='input-heading front-recipient'
              >
                {' '}
                {to}
              </div>
              <p
                id='envelope-content'
                className={`input-heading front-content ${
                  message.length > 500
                    ? 'long'
                    : message.length > 300
                    ? 'mid'
                    : 'default'
                } `}
              >
                {message}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalGreetingCard;
