import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AppContext } from '../states';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import outdatedImage from '../assets/images/outdated.png';

const ModalCheckVersion = (props) => {
  const { state } = useContext(AppContext);
  const strapi = state.strapiData;
  const [modalshow, setModalshow] = useState(false);
  const [linkUpdate, setLinkUpdate] = useState('');

  // console.log(App.getInfo());
  // console.log(strapi);
  // console.log(strapi);

  useEffect(() => {
    if (Capacitor.isNativePlatform) {
      // console.log(min_version, Capacitor.getPlatform());

      App.getInfo()
        .then((res) => {
          if (Capacitor.getPlatform() === 'android') {
            // console.log('android ', res.build, strapi.min_version_android);
            // console.log('android ', res.build, strapi.min_version_android);
            setLinkUpdate(
              'https://play.google.com/store/apps/details?id=com.giftano.redemptionapp'
            );
            if (res.build < strapi.min_version_android) {
              setModalshow(true);
            }
          } else if (Capacitor.getPlatform() === 'ios') {
            // console.log('ios ', res.build, strapi.min_version_ios);
            // console.log('ios ', res.build, strapi.min_version_ios);
            setLinkUpdate('https://apps.apple.com/app/id1670043279');
            // setLinkUpdate('https://apps.apple.com/lb/app/id1670043279');
            if (res.build < strapi.min_version_ios) {
              setModalshow(true);
            }
          }
        })
        .catch((err) => {});
    }
  }, [strapi]);

  return (
    <Modal show={modalshow} fullscreen={true} className='modal-check-version'>
      <Modal.Header style={{ paddingBottom: '.5rem' }}>
        <Modal.Title>
          <h4
            style={{
              marginBottom: '12px',
              marginTop: '1em',
              fontWeight: 'bold',
              fontSize: '32px',
            }}
          >
            Update Required
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={outdatedImage} alt='Outdated' srcset='' />
        <div className='text-outdated'>
          We’ve got some cool improvements and <br></br>fixes in the latest
          update.
          <br></br>
          Tap <b>‘Update’</b> now to experience <br></br>the best version of our
          app!
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={() => {
            window.open(linkUpdate);
          }}
        >
          UPDATE APP
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCheckVersion;
