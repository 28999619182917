import React, { useContext, useState } from 'react';
import { Button, ButtonGroup, FormControl } from 'react-bootstrap';
import { AppContext } from '../../../states';
import { GetShippingCost } from '../../../services/LegacyService';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus, FaSpinner } from 'react-icons/fa';

const VoucherQty = ({ gift, idx }) => {
  const { state, dispatch } = useContext(AppContext);
  const { gifts } = state.merchantPage;
  const [minusProgress, setMinusProgress] = useState(false);
  const [plusProgress, setPlusProgress] = useState(false);

  return (
    <ButtonGroup className='voucher-qty'>
      <Button
        disabled={minusProgress}
        variant='light'
        onClick={async () => {
          let newGifts = [...gifts];
          newGifts[idx].quantity--;
          if (newGifts[idx].quantity < 0) {
            newGifts[idx].quantity = 0;
          }
          if (newGifts[idx].quantity > 0) {
            setMinusProgress(true);
            try {
              newGifts[idx].primary_extrafirst = await GetShippingCost(
                newGifts[idx],
                state.ecard.company.id
              );
            } catch {
              toast.warning('Something went wrong while calculating extra first');
              newGifts[idx].primary_extrafirst = 0;
            }
            setMinusProgress(false);
          } else {
            newGifts[idx].primary_extrafirst = 0;
          }
          // setGifts(newGifts)
          dispatch({
            type: 'MERCHANT_PAGE',
            payload: {
              gifts: newGifts,
            },
          });
        }}
      >
        {minusProgress ? <FaSpinner className='icon-spin' /> : <FaMinus />}
      </Button>
      <FormControl
        as='button'
        className='text-center'
        value={gift.quantity}
        onChange={(e) => {
          let newGifts = [...gifts];
          newGifts[idx].quantity = parseInt(e.target.value);
          // setGifts(newGifts)
          dispatch({
            type: 'MERCHANT_PAGE',
            payload: {
              gifts: newGifts,
            },
          });
        }}
      >
        {gift.quantity}
      </FormControl>
      <Button
        disabled={plusProgress || gifts[idx].quantity >= 100}
        variant='light'
        onClick={async () => {
          let newGifts = [...gifts];
          newGifts[idx].quantity++;
          if (newGifts[idx].quantity > 100) newGifts[idx].quantity = 100;
          if (newGifts[idx].quantity > 0) {
            setPlusProgress(true);
            try {
              newGifts[idx].primary_extrafirst = await GetShippingCost(
                newGifts[idx],
                state.ecard.company.id
              );
            } catch {
              toast.warning('Something went wrong while calculate extra first');
              newGifts[idx].primary_extrafirst = 0;
            }
            setPlusProgress(false);
          } else {
            newGifts[idx].primary_extrafirst = 0;
          }
          // setGifts(newGifts)
          dispatch({
            type: 'MERCHANT_PAGE',
            payload: {
              gifts: newGifts,
            },
          });
        }}
      >
        {plusProgress ? <FaSpinner className='icon-spin' /> : <FaPlus />}
      </Button>
    </ButtonGroup>
  );
};

export default VoucherQty;
