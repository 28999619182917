import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Tabs,
  Tab,
  Accordion,
  Alert,
} from 'react-bootstrap';
import { FaMailBulk, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ErrorMessages } from '../components/ErrorMessages';
import { PostMISC } from '../services/Connection';
import { AppContext } from '../states';
import './ModalHelp.scss';

const initialFormData = {
  name: '',
  email: '',
  phonenumber: '',
  message: '',
};

//belum ada yang pake
const ModalHelp = () => {
  const { state, dispatch } = useContext(AppContext);
  const { modalHelpShow } = state;

  const [formData, setFormData] = useState(initialFormData);
  const [isSendForm, setIsSendForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleFormChange = (e) => {
    let newState = { ...formData };
    newState[e.target.name] = e.target.value;
    setFormData(newState);
  };

  useEffect(() => {
    const loadFAQ = async () => {
      let res = await Axios.get(
        'https://content.giftano.com/faq-categories?Name=Giftano Card'
      );
      // console.log('res faqs', res);
      if (res.status === 200) {
        let faqs = res.data[0].faqs;
        if (faqs) {
          //filter only not hide
          faqs = faqs.filter((f) => !f.hide);
        }
        // console.log('faqs', faqs);
        dispatch({ type: 'SET_FAQS', data: faqs });
      }
    };
    if (modalHelpShow) {
      loadFAQ();
    }
  }, [dispatch, modalHelpShow]);

  useEffect(() => {
    if (state.modalHelpShow) {
      setSuccessMessage('');
      setErrorMessage('');
      setFormData(initialFormData);
    }
  }, [state.modalHelpShow]);

  const enquirySubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    let dataToSend = {
      senderEmail: 'support@giftano.com',
      recipientEmails: 'support@giftano.com',
      subject: 'Enquiry from Giftano Card Redemption ' + formData.name,
      contenthtml: `<strong>Name :</strong> ${formData.name}<br/>
      <strong>Email :</strong> ${formData.email}<br/>
      <strong>Phone Number :</strong> ${formData.phonenumber}<br/>
      <strong>Card number :</strong> ${state.ecard.number}<br/>
      <strong>Message :</strong> <br/>
      ${formData.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
    };
    setIsSendForm(true);
    try {
      let res = await PostMISC('sendemail/', dataToSend);
      if (res.status === 1) {
        setSuccessMessage(
          'Thank You<br/>Your enquiry has been sent. <br/>We will get back to you as soon as we can.'
        );
        setErrorMessage('');
        setFormData(initialFormData);
      } else {
        setSuccessMessage('');
        setErrorMessage("Your enquiry wasn't sent. Please try again.");
      }
    } catch (err) {
      toast.error(ErrorMessages.error_network);
    }
    setIsSendForm(false);
  };

  return (
    <Modal
      show={modalHelpShow}
      centered
      onHide={() => dispatch({ type: 'HIDE_HELP' })}
      className='modal-help'
    >
      <Modal.Header closeButton>
        <Modal.Title className='top-up-title'>Help & Support</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey='faq'>
          <Tab eventKey='faq' title='FAQ'>
            <Accordion>
              <Card className='panel'>
                {state.faqs &&
                  state.faqs.map((faq, idx) => (
                    <React.Fragment key={'faq_' + idx}>
                      <Card.Header className='panel-heading'>
                        <Accordion.Toggle
                          as={Button}
                          variant='link'
                          eventKey={'faq_key_' + idx}
                          className='panel-title'
                        >
                          {faq.question}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={'faq_key_' + idx}>
                        <Card.Body className='panel-body'>
                          <div
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          ></div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </React.Fragment>
                  ))}
              </Card>
            </Accordion>
          </Tab>
          <Tab eventKey='enquiry' title='Enquiry'>
            <Form onSubmit={enquirySubmit}>
              <Card className='mt-4'>
                <Card.Body>
                  <h4 className='font-weight-bold'>Send Enquiry</h4>
                  {errorMessage && (
                    <Alert variant='danger'>{errorMessage}</Alert>
                  )}
                  <hr />
                  {successMessage ? (
                    <div>
                      <p
                        className='text-center'
                        style={{ fontSize: '48px', margin: '8px auto 4px' }}
                      >
                        <FaMailBulk />
                      </p>
                      <p
                        className='text-center'
                        dangerouslySetInnerHTML={{ __html: successMessage }}
                      ></p>
                    </div>
                  ) : (
                    <React.Fragment>
                      <Form.Group as={Row}>
                        <Form.Label column sm='4'>
                          Name *
                        </Form.Label>
                        <Col sm='8'>
                          <Form.Control
                            type='text'
                            required
                            name='name'
                            value={formData.name}
                            onChange={handleFormChange}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm='4'>
                          Email *
                        </Form.Label>
                        <Col sm='8'>
                          <Form.Control
                            type='email'
                            required
                            name='email'
                            value={formData.email}
                            onChange={handleFormChange}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm='4'>
                          Phone Number
                        </Form.Label>
                        <Col sm='8'>
                          <Form.Control
                            type='tel'
                            name='phonenumber'
                            value={formData.phonenumber}
                            onChange={handleFormChange}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm='4'>
                          Message *
                        </Form.Label>
                        <Col sm='8'>
                          <Form.Control
                            as='textarea'
                            rows='3'
                            required
                            name='message'
                            value={formData.message}
                            onChange={handleFormChange}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </React.Fragment>
                  )}
                </Card.Body>
              </Card>
              {!successMessage && (
                <Col className='mt-3' xs={{ offset: 1, span: 10 }}>
                  <Button variant='primary' type='submit' disabled={isSendForm}>
                    {isSendForm && <FaSpinner className='icon-spin mr-2' />}{' '}
                    SUBMIT
                  </Button>
                </Col>
              )}
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ModalHelp;
