import { useContext } from 'react';
import { AppContext } from '../states';
import getSymbolFromCurrency from 'currency-symbol-map';

const CurrencySymbol = () => {
  const { state } = useContext(AppContext);
  const { ecard } = state;
  if (ecard && ecard.currency) {
    return getSymbolFromCurrency(ecard.currency);
  } else {
    return '';
  }
};

export default CurrencySymbol;
