import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
// import GA4React from "ga-4-react";
// import { App as CapApp } from '@capacitor/app';

// const ga4react = new GA4React("G-4MYPYSYSE8");
import { flattenObj } from './helper/FlattenObject';

(async () => {
  // sentry
  let enableSentry = true;
  let enableSentryReplays = false;
  try {
    const respStrapi = await fetch(
      process.env.REACT_APP_STRAPI +
        '/giftano-card-redemption' +
        '?populate[0]=about_giftano_card.image.*&populate[1]=steps.steps.*&populate[2]=voucher_type.*&populate[3]=about_giftano&populate[4]=how_it_work.*&populate[5]=faq_banner.*&populate[6]=my_gift_banner.*&populate[7]=about_gift_box.image.*&populate[8]=homepage_category.image.*&populate[9]=how_to_use_giftano_box.*'
    );
    let flattenedData = flattenObj(await respStrapi.json());
    const respJson = flattenedData;
    if (respJson) {
      enableSentry =
        respJson.enable_sentry !== undefined ? respJson.enable_sentry : true;
      enableSentryReplays =
        respJson.enable_sentry_replays !== undefined
          ? respJson.enable_sentry_replays
          : false;
    }
  } catch {}

  const mode = process.env.REACT_APP_BUILD_MODE || 'SANDBOX';
  if (enableSentry && window.location.hostname !== 'localhost') {
    let integrations = [new Sentry.BrowserTracing()];
    if (enableSentryReplays) {
      integrations.push(new Sentry.Replay());
    }
    Sentry.init({
      dsn: 'https://a6c5ad29eba448fab737f1b638af2f2f@o1089454.ingest.sentry.io/6104587',
      integrations: integrations,
      environment: mode.toLowerCase(),
      initialScope: {
        tags: {
          team: 'front-end',
          'customer.b2b': true,
          'customer.b2c': true,
        },
      },
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: mode === 'SANDBOX' ? 1 : 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      beforeSend(event, hint) {
        const error = hint.originalException;
        const userAgent = event.request?.headers?.['User-Agent'];
        const chromeRegex = /Chrome\/(\d+)/;

        if (userAgent && chromeRegex.test(userAgent)) {
          const version = Number(userAgent.match(chromeRegex)[1]);
          if (version <= 110) {
            if (
              error &&
              error.message &&
              error.message.match(/Illegal invocation/i)
            ) {
              return null;
            }
          }
        }
        return event;
      },
    });
  }

  // google analytics
  // try {
  // await ga4react.initialize();
  // } catch {}

  const url = window.location.hostname;
  const containsGiftsDomain = url.includes('.gifts');

  if (containsGiftsDomain) {
    const weglotScript = document.createElement('script');
    weglotScript.src = 'https://cdn.weglot.com/weglot.min.js';
    weglotScript.id = 'weglot-script';
    document.head.appendChild(weglotScript);
    weglotScript.onload = function () {
      const weglotScript2 = document.createElement('script');
      weglotScript2.innerHTML = `
    Weglot.initialize({
      api_key: 'wg_28254fb87d1a9398fea62072647636664',
    });
  `;
      document.head.appendChild(weglotScript2);
    };
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
