import React from 'react';
import ContentLoader from 'react-content-loader';

const ListingSkeleton = (props) => (
  <ContentLoader
    width='100%'
    height={400}
    viewBox='0 0 1200 400'
    backgroundColor='var(--gf-neutral-300)'
    foregroundColor='var(--gf-neutral-300)'
    {...props}
  >
    <rect x='1%' y='0' rx='4' ry='4' width='23%' height='211' />
    <rect x='26%' y='0' rx='4' ry='4' width='23%' height='211' />
    <rect x='51%' y='0' rx='4' ry='4' width='23%' height='211' />
    <rect x='76%' y='0' rx='4' ry='4' width='23%' height='211' />
    <rect x='1%' y='230' rx='4' ry='4' width='23%' height='211' />
    <rect x='26%' y='230' rx='4' ry='4' width='23%' height='211' />
    <rect x='51%' y='230' rx='4' ry='4' width='23%' height='211' />
    <rect x='76%' y='230' rx='4' ry='4' width='23%' height='211' />
  </ContentLoader>
);

export default ListingSkeleton;
