import React, { useContext } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Clamp from 'react-multiline-clamp';
import { Link, useHistory } from 'react-router-dom';
// import LabelGiftTypeChip from '../../../components/LabelGiftTypeChip';
import MyImg from '../../../components/MyImg';
import { AppContext } from '../../../states';
import { CurrencyFormat } from '../../../helper/CurrencyFormat';

const ViewByGift = () => {
  const { state } = useContext(AppContext);
  const { giftItems } = state.searchPage;
  const history = useHistory();
  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;

  // console.log('list gifdt', giftItems);
  return giftItems.map((gift, idx) => {
    if (
      hidePrice &&
      parseFloat(state.ecard.total_usage) === 0 &&
      parseFloat(gift['min-price']) > parseFloat(state.ecard.balance)
    ) {
      return '';
    }
    return (
      <Col
        className='page-search__item'
        key={'col_' + idx}
        xs='6'
        lg='3'
        md='4'
        // sm='6'
      >
        {/* <Link title={gift.name} to={'/gift/' + gift.id}> */}
        <LazyLoad
          classNamePrefix={
            gift.is_cash_voucher === 1 ? 'is-cash lazyload' : 'lazyload'
          }
          placeholder={<Spinner />}
        >
          <div className='merchant-image-wrapper'>
            <Link title='View gift' to={'/gift/' + gift.id}>
              <MyImg
                style={{ borderRadius: '12px' }}
                height='80px'
                bgcolor={gift.is_cash_voucher === 1 ? '#ffffff' : '#02afa1'}
                spinnervariant='light'
                price={gift.price}
                alt={gift.name}
                src={
                  gift.file
                    .replace(
                      'https://web-sandbox.giftano.io/fls',
                      'https://giftano.imgix.net/fls'
                    )
                    .replace(
                      'http://web-sandbox.giftano.io/fls',
                      'https://giftano.imgix.net/fls'
                    )
                    .replace(
                      'https://static-cdn.giftano.com/fls',
                      'https://giftano.imgix.net/fls'
                    ) + '?auto=format&width=400'
                }
                rounded
                fluid
                className={
                  'merchant-images top-rad ' +
                  (gift.is_cash_voucher === 1 ? 'cash-voucher-images' : '')
                }
              />
            </Link>

            <div className='placeholder-gift'>
              <div
                className='placeholder-merchant'
                onClick={() => {
                  history.push('/merchant/' + gift.merchant.id);
                }}
                style={{ cursor: 'pointer' }}
              >
                {gift.merchant && gift.merchant.name}
              </div>
              <Clamp withTooltip lines={1}>
                <div className='placeholder-merchant'>
                  <div className='placeholder-coupon'>{gift.name}</div>
                </div>
              </Clamp>
              <div className='placeholder-cta-container'>
                {hidePrice ? (
                  <div className='placeholder-price'></div>
                ) : // eslint-disable-next-line eqeqeq
                gift.is_cash_voucher == 1 ? (
                  <div className='placeholder-price'>
                    From <CurrencyFormat value={gift['min-price']} />
                  </div>
                ) : (
                  <div className='placeholder-price'>
                    <CurrencyFormat value={gift.price} />
                  </div>
                )}
                <Link
                  className='btn btn-primary placeholder-cta-detail'
                  title='View gift'
                  to={'/gift/' + gift.id}
                >
                  View gift
                </Link>
              </div>

              {/* <div className="placeholder-brand" title={gift.merchant.name}>
                      {gift.merchant.name}
                    </div> */}
              {/* <LabelGiftTypeChip tags={gift.tags_v2} /> */}
            </div>
          </div>
        </LazyLoad>
        {/* </Link> */}
      </Col>
    );
  });
};
export default ViewByGift;
