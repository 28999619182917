import React from 'react';
import ContentLoader from 'react-content-loader';

function ListingSkeletonTablet(props) {
  return (
    <ContentLoader
      backgroundColor='var(--gf-neutral-300)'
      foregroundColor='var(--gf-neutral-300)'
      {...props}
    >
      <rect x='1%' y='0' rx='4' ry='4' width='31%' height='211' />
      <rect x='34%' y='0' rx='4' ry='4' width='31%' height='211' />
      <rect x='67%' y='0' rx='4' ry='4' width='31%' height='211' />
      <rect x='1%' y='230' rx='4' ry='4' width='31%' height='211' />
      <rect x='34%' y='230' rx='4' ry='4' width='31%' height='211' />
      <rect x='67%' y='230' rx='4' ry='4' width='31%' height='211' />
      <rect x='1%' y='460' rx='4' ry='4' width='31%' height='211' />
      <rect x='34%' y='460' rx='4' ry='4' width='31%' height='211' />
      <rect x='67%' y='460' rx='4' ry='4' width='31%' height='211' />
    </ContentLoader>
  );
}

export default ListingSkeletonTablet;
