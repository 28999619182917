/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Connection, PostPublic } from '../services/Connection';
import { AppContext } from '../states';
import { ErrorMessages } from './ErrorMessages';
import { SHA1 } from 'crypto-js';
import { FaSpinner } from 'react-icons/fa';
import ModalAddToAccount from '../pages/ModalAddToAccount';
// import moment from 'moment';
import { isLoginRequired, isMembershipActive } from '../helper';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Button, Card } from 'react-bootstrap';
import { App } from '@capacitor/app';

const params = new URLSearchParams(window.location.search);

// const startTime = moment();
// let pagesOpenCnt = 0;

const isProduction =
  process.env['REACT_APP_BUILD_MODE'] === 'PRODUCTION' ? true : false;

const LoadECard = ({ children }) => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [loginUrl, setLoginUrl] = useState('');

  const [modalAddToAccount, setModalAddToAccount] = useState({ show: false });
  const modalAddToAccountToggle = () => setModalAddToAccount({ show: false });

  const membershipActive = isMembershipActive(state.ecard, state.strapiData);

  const redirectToLogin = () => {
    const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH;
    let locationWindow =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?';
    if (Capacitor.isNativePlatform()) {
      locationWindow = locationWindow
        .replace(
          'capacitor://',
          isProduction
            ? 'com.giftano.redemptionapp://'
            : 'com.giftano.redemptionappsandbox://'
        )
        .replace(':////', '://');
    }
    const redirect_url =
      redirect_auth +
      '?redirect=' +
      btoa(locationWindow + '|member') +
      '&rb=' +
      btoa(window.location.origin + '/getstarted') +
      '&mode3';
    // alert('redirect to ' + redirect_url)
    if (Capacitor.isNativePlatform()) {
      // window.open(redirect_url);
      setLoginUrl(redirect_url);
      Browser.open({ url: redirect_url });
      localStorage.setItem('redirect_after_login', location.pathname);
    } else {
      window.location = redirect_url;
    }
  };

  // useEffect(() => {
  //   const now = moment();
  //   let minute = now.subtract(startTime).get('minute');
  //   if (
  //     history.location.pathname !== '/mygifts' &&
  //     history.location.pathname !== '/account'
  //   ) {
  //     pagesOpenCnt++;
  //   }
  //   if (
  //     state.ecard &&
  //     !state.ecard.memberid &&
  //     state.strapiData &&
  //     membershipActive &&
  //     minute >= 5 &&
  //     pagesOpenCnt > 3 &&
  //     !localStorage.getItem('invitedMember')
  //   ) {
  //     setModalAddToAccount({ show: true });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [history]);

  useEffect(() => {
    if (history.action === 'PUSH') {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 200);
    }
  }, [history]);

  useEffect(() => {
    if (state.gcardinfo && !state.ecard && state.strapiData.id) {
      const loadData = async () => {
        let payload = {
          ecard: state.gcardinfo.number,
          key: state.key,
          details: '1',
        };
        if (Capacitor.isNativePlatform) {
          await App.getInfo()
            .then((res) => {
              if (Capacitor.getPlatform() === 'android') {
                payload = { ...payload, android_build: res.build };
              } else if (Capacitor.getPlatform() === 'ios') {
                payload = { ...payload, ios_build: res.build };
              }
            })
            .catch((err) => {});
        }
        try {
          // alert('load ecard');
          let resp = await PostPublic('ecard/', payload);
          // console.log('resp ecard/', resp);

          if (resp.status === 1) {
            dispatch({ type: 'SET_ECARD', data: resp.data });

            const loginRequired = isLoginRequired(resp.data, state.strapiData);
            if (loginRequired && !state.session_id) {
              // toast.warning(ErrorMessages.error_need_login, {
              //   onClose: redirectToLogin,
              // });
              redirectToLogin();
              return;
            }

            if (
              !resp.data.memberid &&
              (resp.data.required_otp_sms == 1 ||
                resp.data.required_otp_email == 1) &&
              !(state.otp && state.otp.code)
            ) {
              // console.log('move back to passcode : ', state.otp, resp.data);
              // alert('move back to passcode');
              dispatch({ type: 'SET_GCARD_INFO', data: null });
              dispatch({ type: 'SET_KEY', data: null });
              history.push('/passcode');
              return;
            }

            if (membershipActive && !resp.data.memberid) {
              let savecard = false;
              if (params.has('savecard')) {
                savecard = params.get('savecard') === 'true' ? true : false;
              }
              if (savecard) {
                const number = resp.data.number;
                const session_id = state.session_id;
                const ipaddress = state.ipaddress;
                try {
                  let res = await PostPublic('gcard/set-member/', {
                    ecard: SHA1(number).toString(),
                    session_id: session_id,
                    ipaddress: ipaddress,
                  });
                  if (res.status === 1) {
                    toast.success('Success add giftano card into your account');
                    resp.data.memberid = state.member.id;
                    window.history.pushState(
                      null,
                      'Get Started',
                      window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname
                    );
                  } else {
                    toast.warning(
                      res.message || ErrorMessages.error_set_member
                    );
                  }
                } catch (err) {
                  toast.error(
                    'Failed to add card into your account. Please reload the page and try again!'
                  );
                }
              }
            }
          } else {
            toast.warning(
              resp.message ||
                'Giftano card info not found. Please re-enter your giftano card number!',
              {
                onClose: () => {
                  localStorage.clear();
                  window.location.reload();
                },
              }
            );
          }
        } catch (err) {
          console.log('error load ecard', err);
          toast.error('Fail to load your card data, please reload the page!');
        }
      };
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.gcardinfo, state.ecard, state.strapiData]);

  useEffect(() => {
    if (state.ecard && state.ecard.memberid && !state.redacted_email) {
      Connection({
        url: 'auth/email-redacted/' + state.ecard.memberid,
        method: 'GET',
      })
        .then((res) => {
          if (res.code === 200) {
            dispatch({ type: 'SET_REDACTED_EMAIL', data: res.data.email });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'SET_REDACTED_EMAIL', data: '' });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ecard]);

  let memberNotSame =
    membershipActive &&
    state.ecard.memberid &&
    state.session_id &&
    state.member &&
    state.member.id !== state.ecard.memberid
      ? true
      : false;

  return (
    <React.Fragment>
      {loginUrl ? (
        <div className='page-member-not-same'>
          <Card>
            <Card.Body>
              {state.whitelabel && (
                <img
                  src={
                    state.whitelabel.settings[
                      'giftano-card-redemption-site-logo'
                    ]
                      ? state.whitelabel.settings[
                          'giftano-card-redemption-site-logo'
                        ].value
                      : ''
                  }
                  className='logo-not-same'
                  alt='Logo'
                />
              )}
              <h3 className='title-not-same'>Authenticating</h3>
              <p className='desc-not-same'>
                Please login, click login button bellow if authenticating window
                not opened!
              </p>

              <Button
                type='button'
                block
                onClick={() => {
                  if (Capacitor.isNativePlatform()) {
                    Browser.open({ url: loginUrl });
                  } else {
                    window.location = loginUrl;
                  }
                }}
              >
                LOGIN
              </Button>
              <Button
                type='button'
                className='btn-outline-primary'
                block
                onClick={async () => {
                  try {
                    await Connection({
                      url: 'auth/logout',
                      method: 'POST',
                      headers: { session_id: state.session_id },
                    });
                  } catch {}
                  localStorage.clear();
                  await dispatch({ type: 'SET_LOGOUT' });
                  history.push('/');
                }}
              >
                Back to Homepage
              </Button>
            </Card.Body>
          </Card>
        </div>
      ) : memberNotSame ? (
        <div className='page-member-not-same'>
          <Card>
            <Card.Body>
              {state.whitelabel && (
                <img
                  src={
                    state.whitelabel.settings[
                      'giftano-card-redemption-site-logo'
                    ]
                      ? state.whitelabel.settings[
                          'giftano-card-redemption-site-logo'
                        ].value
                      : ''
                  }
                  className='logo-not-same'
                  alt='Logo'
                />
              )}
              <h3 className='title-not-same'>Oops!</h3>
              <p className='desc-not-same'>
                Sorry, to access this Giftano Card you have to login with
                correct member account.{' '}
                {state.redacted_email ? (
                  'This Giftano Card belong to member with email address ' +
                  state.redacted_email
                ) : (
                  <>
                    <br />
                    <FaSpinner className='icon-spin' /> loading card owner info
                  </>
                )}
              </p>

              <Button
                type='button'
                block
                onClick={async () => {
                  try {
                    await Connection({
                      url: 'auth/logout',
                      method: 'POST',
                      headers: { session_id: state.session_id },
                    });
                  } catch {}
                  redirectToLogin();
                }}
              >
                TRY AGAIN
              </Button>
              <Button
                type='button'
                className='btn-outline-primary'
                block
                onClick={async () => {
                  try {
                    await Connection({
                      url: 'auth/logout',
                      method: 'POST',
                      headers: { session_id: state.session_id },
                    });
                  } catch {}
                  localStorage.clear();
                  await dispatch({ type: 'SET_LOGOUT' });
                  history.push('/');
                }}
              >
                Back to Homepage
              </Button>
            </Card.Body>
          </Card>
        </div>
      ) : state.ecard ? (
        <div>
          {modalAddToAccount.show && (
            <ModalAddToAccount
              show={true}
              toggle={modalAddToAccountToggle}
              mode={'purchase'}
            />
          )}
          {children}
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            height: 'calc(95vh - env(safe-area-inset-top))',
            width: '100%',
            color: 'gray',
            fontSize: '28px',
          }}
        >
          <div className='text-center'>
            <FaSpinner className='icon-spin mr-2' /> Almost Ready...
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoadECard;
