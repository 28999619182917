import React, { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import LoadECard from '../../components/LoadECard';
import MyNavbar from '../../components/MyNavbar';
import TabBarBottom from '../../components/TabBarBottom';
import { AppContext } from '../../states';

const AboutGiftanoPage = () => {
  const { state } = useContext(AppContext);
  const history = useHistory();
  const data = state.strapiData.about_giftano || {};
  console.log('strapi data', state.strapiData);
  return (
    <div className='about-giftano-page'>
      <LoadECard>
        {state.ecard && (
          <>
            <MyNavbar />
            <Container className='pt-4'>
              <Button
                variant='link'
                style={{
                  color: 'var(--gf-dark-gray-300)',
                  marginBottom: '20px',
                }}
                size={'sm'}
                onClick={() => history.push('/info')}
              >
                <FaArrowLeft /> Back
              </Button>

              <div className='about px-4'>
                <h4 className='about-heading'>{data.title}</h4>
                <p className='about-content'>
                  Having to travel all the way to a brick and mortar store just
                  to buy gift vouchers can be a chore; and having to wait in
                  long queue lines just makes it worse. So why go through all
                  that trouble when you could have bought them online - in the
                  comfort of your own homes or on the go?
                </p>

                <p className='about-content'>
                  Giftano is an online gift shop with a wide selection of
                  premium handpicked gifts such as unique experiences, beautiful
                  flowers, your favourite gift cards, luxurious hampers and many
                  other thoughtful gifts perfect for any occasion. All gifts
                  come with your own personalised greeting card and free
                  shipping.
                </p>

                <p className='about-content'>
                  Giftano offers a super easy to use search tool to help you
                  find gifts for that special someone. Users can find gifts
                  based on occasion, category or based on whom they are buying
                  for. It also offers one of the most universal gifting options
                  out there with its own Giftano Card which allows the recipient
                  to choose from any of the long list of brands and gifts listed
                  on its website. Gifting made easy!
                </p>

                <p className='about-content'>
                  You then have the option to either receive the gift as an
                  e-voucher, which will be delivered to you instantly, or to
                  have the vouchers printed and sent to you by post in an
                  exclusive envelope. You may also opt to have them delivered by
                  courier in a luxurious gift box.
                </p>
              </div>
            </Container>
            <TabBarBottom />
          </>
        )}
      </LoadECard>
    </div>
  );
};

export default AboutGiftanoPage;
