import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { SHA1 } from 'crypto-js';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FaSpinner, FaUserCircle } from 'react-icons/fa';
// import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorMessages } from '../../../components/ErrorMessages';
import { PostPublic } from '../../../services/Connection';
import { AppContext } from '../../../states';

const isProduction =
  process.env['REACT_APP_BUILD_MODE'] === 'PRODUCTION' ? true : false;

const CardAddAccount = () => {
  const { state, dispatch } = useContext(AppContext);
  const [progress, setProgress] = useState('pending');
  // const location = useLocation();
  if (progress === 'success') return null;
  return (
    <div
      className={`card-add-account ${
        progress === 'success' ? 'card-add-account-success' : ''
      }`}
    >
      <FaUserCircle />
      {state.ecard.is_gift_box === 0 ? (
        <h5>Protect My Giftano Card</h5>
      ) : (
        <h5>Protect My Ultimate Gift</h5>
      )}

      {state.session_id ? (
        <p>
          Protect and manage all of your{' '}
          {state.ecard.is_gift_box === 0
            ? 'Giftano Card gift '
            : 'Ultimate Gift '}
          purchases more easily.
        </p>
      ) : (
        <p>
          Add {state.ecard.is_gift_box === 0 ? 'Giftano Card' : 'Ultimate Gift'}{' '}
          to your Giftano Member Account to easily protect and manage all of
          your{' '}
          {state.ecard.is_gift_box === 0
            ? 'Giftano Card gift'
            : 'Ultimate Gift'}{' '}
          purchases.
        </p>
      )}

      <Button
        className='btn-connect'
        onClick={async () => {
          if (state.session_id) {
            const number = state.ecard.number;
            const session_id = state.session_id;
            const ipaddress = state.ipaddress;
            setProgress('submitting');
            try {
              let res = await PostPublic('gcard/set-member/', {
                ecard: SHA1(number).toString(),
                session_id: session_id,
                ipaddress: ipaddress,
              });
              // console.log(res)
              if (res.status === 1) {
                toast.success('Success add giftano card into your account');
                setProgress('success');
                dispatch({
                  type: 'SET_ECARD_MEMBER',
                  memberid: state.member.id,
                });
              } else {
                alert(res.message || ErrorMessages.error_set_member);
                setProgress('pending');
              }
            } catch {
              // toast.warning(ErrorMessages.error_set_member)
              alert(ErrorMessages.error_set_member);
              setProgress('pending');
            }
          } else {
            const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH;
            let locationWindow =
              window.location.protocol +
              '//' +
              window.location.host +
              window.location.pathname +
              '?savecard=true';
            if (Capacitor.isNativePlatform()) {
              locationWindow = locationWindow
                .replace(
                  'capacitor://',
                  isProduction
                    ? 'com.giftano.redemptionapp://'
                    : 'com.giftano.redemptionappsandbox://'
                )
                .replace(':////', '://');
            }
            const redirect_url =
              redirect_auth +
              '?redirect=' +
              btoa(locationWindow + '|member') +
              '&rb=' +
              btoa(window.location.origin + '/getstarted') +
              // eslint-disable-next-line eqeqeq
              (state.ecard && state.ecard.is_gift_box == 1
                ? '&mode=6'
                : '&mode=3');

            if (Capacitor.isNativePlatform()) {
              // window.open(redirect_url);
              Browser.open({ url: redirect_url });
              // localStorage.setItem('redirect_after_login', location.pathname);
            } else {
              window.location = redirect_url;
            }
          }
        }}
        disabled={progress === 'submitting'}
      >
        {progress === 'submitting' && <FaSpinner className='mr-2 icon-spin' />}
        {state.session_id ? 'Connect to My Giftano Account' : 'Protect My Gift'}
      </Button>
    </div>
  );
};

export default CardAddAccount;
