import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppContext } from '../states';
import { MdMailOutline } from 'react-icons/md';
import { BiPhone } from 'react-icons/bi';
import Moment from 'react-moment';

const MyFooter = (props) => {
  const { state } = useContext(AppContext);
  const { about_giftano } = state.strapiData;
  const whitelabel = state.whitelabel;
  // console.log('whitelabel', whitelabel);
  let hide_footer = false;
  if (
    whitelabel &&
    whitelabel['settings']['hide-giftano-logo'] &&
    whitelabel['settings']['hide-giftano-logo']['value'] === '1'
  )
    hide_footer = true;
  if (about_giftano && !hide_footer) {
    return (
      <div className='footer'>
        <Container>
          <Row>
            <Col
              md='4'
              className='d-flex align-items-center justify-content-center'
            >
              <div>
                <img
                  className='footer-logo'
                  src={
                    'https://static-cdn.giftano.com/assets/2022-giftano-logo.png'
                  }
                  alt='Logo Giftano'
                />
                <p className='footer-address'>
                  1 George St, Level 10
                  <br />
                  Singapore 049145
                </p>
              </div>
            </Col>
            <Col
              md='4'
              className='d-flex align-items-center justify-content-center'
            >
              <div>
                <div
                  className='footer-text'
                  dangerouslySetInnerHTML={{
                    __html: about_giftano.text.replace(/\n/g, '<br/>'),
                  }}
                ></div>
                <p
                  className='text-primary'
                  style={{ fontSize: '18px !important' }}
                >
                  Gifting made easy
                </p>
              </div>
            </Col>
            <Col
              md='4'
              className='d-flex align-items-center justify-content-center'
            >
              <div className='footer-contact'>
                <p className='text-primary'>Support Contact Information</p>
                <p>
                  <MdMailOutline className='mr-2' /> support@giftano.com
                </p>
                <p>
                  <BiPhone className='mr-2' /> +65 6908 5495
                </p>
                <p className='footer-right'>
                  <Moment format='YYYY' /> © Giftano. All Rights Reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return '';
  }
};

export default MyFooter;
