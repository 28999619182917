import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import Clamp from 'react-multiline-clamp';
import { useParams } from 'react-router-dom';
import ModalGift from '../../../components/ModalGift';
import { AppContext } from '../../../states';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';

const OtherGifts = () => {
  const { state, dispatch } = useContext(AppContext);
  const { gifts } = state.merchantPage;
  const [modalGiftShow, setModalGiftShow] = useState(false);
  const toggleModalGift = () => setModalGiftShow(false);
  const [modalGiftSelected, setModalGiftSelected] = useState(0);
  const { giftId } = useParams();

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    setModalGiftShow(false);
  }, [giftId]);
  return (
    <div className='other-gifts'>
      {modalGiftShow && (
        <ModalGift
          show={modalGiftShow}
          toggle={toggleModalGift}
          gifts={gifts}
          activeIndex={modalGiftSelected}
          setIndex={setModalGiftSelected}
        />
      )}
      <Container>
        <h2 className='title'>Other Gifts</h2>
        <div className='gift-scroll-controller'>
          <button
            className='btn-horizontal-scroll left'
            onClick={() => scroll(-600)}
          >
            <BsChevronLeft />
          </button>
          <button
            className='btn-horizontal-scroll right'
            onClick={() => scroll(600)}
          >
            <BsChevronRight />
          </button>
        </div>
        <div className='gift-container' ref={ref}>
          <Row className='gift-scroller'>
            {gifts.map((gift, idx) => (
              <div className='gift-box' key={'otherGiftItem' + idx}>
                <Card>
                  <img
                    src={gift.file}
                    alt={gift.name}
                    className='img-fluid gift-image'
                  />
                  <Card.Body>
                    <Clamp withTooltips lines={1}>
                      <p className='merchant-name'>{gift.merchant.name}</p>
                    </Clamp>
                    <Clamp withTooltips lines={2}>
                      <h4 className='gift-name'>{gift.name}</h4>
                    </Clamp>
                    <Clamp withTooltips lines={3}>
                      <p className='gift-desc'>{gift.description}</p>
                    </Clamp>
                    <Button
                      className='btn-view mb-2'
                      onClick={() => {
                        // setModalGiftSelected(idx)
                        // setModalGiftShow(true)
                        dispatch({
                          type: 'MERCHANT_PAGE',
                          payload: {
                            activeGift: idx,
                          },
                        });
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                    >
                      View gift
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default OtherGifts;
