import React, { useContext, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { AppContext } from '../../../states';
import { Button, Col, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { BiMap } from 'react-icons/bi';

const GiftBoxCarousel = () => {
  const { state, dispatch } = useContext(AppContext);
  const { gifts, isRedeem, activeGift } = state.merchantPage;
  const [selectedIdx, setSelectedIdx] = useState();
  return (
    <Carousel
      autoPlay={false}
      selectedItem={activeGift}
      showArrows={false}
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
      swipeable={false}
      // dynamicHeight={true}
    >
      {gifts.map((gift, idx) => {
        return (
          <div
            key={'carousel_item_gift_' + idx}
            className={'gift-carousel-item'}
            id={'carousel_item_gift_' + idx}
          >
            <Row>
              <Col md={5} xs={12}>
                <img
                  className='img-fluid gift-image'
                  src={gift.file}
                  alt={gift.name}
                />
              </Col>
              <Col md={7} xs={12} className='carousel__content'>
                <div
                  className='gift-merchant-name carousel__subtitle'
                  // variant='outline'
                  // onClick={() => {
                  // setMerchantInfoData(gift.merchant)
                  // setShowMerchantInfo(true)
                  // }}
                >
                  {gift.merchant.name}
                </div>
                <h4 className='gift-name carousel__title'>{gift.name}</h4>
                <hr className='mb-4' />
                <p
                  className='carousel__description'
                  dangerouslySetInnerHTML={{ __html: gift.description }}
                ></p>

                <Button
                  block
                  className='btn-select-gift'
                  onClick={async () => {
                    setSelectedIdx(idx);
                    // let newGifts = [...gifts]
                    // newGifts[idx].quantity = 1
                    // newGifts[idx].primary_extrafirst =
                    // await GetShippingCost(newGifts[idx])
                    dispatch({
                      type: 'MERCHANT_PAGE',
                      payload: {
                        // gifts: newGifts,
                        activeGift: idx,
                        showConfirm: true,
                      },
                    });
                  }}
                  disabled={isRedeem}
                >
                  {isRedeem && selectedIdx === idx && (
                    <FaSpinner className='mr-2 icon-spin' />
                  )}
                  Select
                </Button>
              </Col>
            </Row>
            <Row className='ultimate-gift-merchant'>
              <Col>
                <div className='text-left mt-4'>
                  <h4>About {gift.merchant.name}</h4>
                  {gift.merchant.desc ? (
                    <div className='merchant-about'>{gift.merchant.desc}</div>
                  ) : (
                    <div
                      className='merchant-about'
                      dangerouslySetInnerHTML={{
                        __html: gift.merchant['website-desc'].replace(
                          /\n/g,
                          '<br/>'
                        ),
                      }}
                    ></div>
                  )}
                  {gift.merchant.contacts.length > 0 && (
                    <div className='mb-4'>
                      <h4>Contact</h4>
                      {gift.merchant.contacts.map((contact, idx) => (
                        <div key={'contact_item_' + idx}>
                          <p>
                            <strong>{contact.name}</strong>
                          </p>
                          {contact.phone && <p>Phone : {contact.phone}</p>}
                          {contact.email && <p>Email : {contact.email}</p>}
                        </div>
                      ))}
                    </div>
                  )}
                  {gift.merchant.address && (
                    <>
                      <h4>Address</h4>
                      <div className='d-flex'>
                        <BiMap className='mr-2' />{' '}
                        <p
                          className='font-weight-bold'
                          style={{
                            marginBottom: '35px',
                          }}
                        >
                          {gift.merchant.address.address && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: gift.merchant.address.address.replace(
                                  /\n/g,
                                  '<br/>'
                                ),
                              }}
                            ></span>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </Carousel>
  );
};

export default GiftBoxCarousel;
