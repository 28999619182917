export const isMembershipActive = (ecard, strapiData) => {
  let loginRequired = false;
  let membershipFeature = false;
  if (!ecard || !strapiData) return false;
  // eslint-disable-next-line eqeqeq
  if (ecard.is_test_gift == 1) return false;
  const cardNeedMemberAccount = ecard.need_member_account;
  const strapiNeedMemberAccount = strapiData.member_account_required;
  const cardMembership = ecard.membership;
  const strapiMembership = strapiData.membership;
  // eslint-disable-next-line eqeqeq
  if (cardNeedMemberAccount == 9) {
    // use info from strapi
    loginRequired = strapiNeedMemberAccount;
  } else {
    // eslint-disable-next-line eqeqeq
    loginRequired = cardNeedMemberAccount == 1 ? true : false;
  }
  // check if login required ignore all membership setting and set to true
  if (loginRequired) {
    membershipFeature = true;
  } else {
    // check card info first then strapi
    // eslint-disable-next-line eqeqeq
    if (cardMembership == 9) {
      membershipFeature = strapiMembership;
    } else {
      // eslint-disable-next-line eqeqeq
      membershipFeature = cardMembership == 1 ? true : false;
    }
  }
  return membershipFeature;
};

export const isLoginRequired = (ecard, strapiData) => {
  let loginRequired = false;
  if (!ecard || !strapiData) return false;
  const cardNeedMemberAccount = ecard.need_member_account;
  const strapiNeedMemberAccount = strapiData.member_account_required;
  // eslint-disable-next-line eqeqeq
  if (cardNeedMemberAccount == 9) {
    // use info from strapi
    loginRequired = strapiNeedMemberAccount;
  } else {
    // eslint-disable-next-line eqeqeq
    loginRequired = cardNeedMemberAccount == 1 ? true : false;
  }
  return loginRequired;
};

export const strapiImageUrl = (url) => {
  if (url) {
    if (url.substr(0, 4) === 'http') {
      return url;
    } else {
      return 'https://cms.giftano.com' + url;
    }
  }
  return url;
};

export const replaceString = (stringValue, stringReplace, replaceWith) => {
  if (!stringValue) return '';
  let res = stringValue.split(stringReplace).join(replaceWith);
  return res;
};

export const hideEmail = (email) => {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
};
