export const ErrorMessages = {
  error_network:
    'Oops, something went wrong with your internet connection, please try again!',
  error_get_whitelabel:
    'Unable to retrieve company settings, please try to reload this page or contact us if the problem persists',
  error_giftano_card_not_found:
    'Giftano Card Not Found, please recheck your card number!',
  error_verify_passcode:
    'Failed to verify your passcode, please re check your passcode!',
  error_activate_card: 'Failed to activate the Giftano Card, please try again!',
  error_get_gifts:
    'Error get newest gift catalog, please check your internet connection and try again!',
  error_refresh_balance: 'Error refreshing new balance, please reload the page',
  error_get_otp: 'Failed to send OTP to phone number, please try again!',
  error_top_up:
    'Failed topup your card, please try again or contact us if problem persist!',
  error_redeem_url:
    'Sorry, something went wrong. Please try again and make sure you enter the correct  URL/Giftano Card number.',
  error_member_not_match:
    'Giftano Card you tried to access is not belong to your account, please login with account associated with this Giftano Card.',
  error_need_login: 'You need to login to access this giftano card!',
  error_set_member:
    'Oops something wrong while add giftano card to your account',
};
