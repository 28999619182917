/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react';
import { Nav, Navbar, Button, Container } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../states';
import { AiOutlineHome } from 'react-icons/ai';
import { FiGift, FiUser, FiAward } from 'react-icons/fi';
import { BiHelpCircle, BiMenu } from 'react-icons/bi';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { Connection } from '../services/Connection';
import { toast } from 'react-toastify';
import ModalHowToRedeem from './ModalHowToRedeem';
import { BsSearch } from 'react-icons/bs';
import { isMembershipActive } from '../helper';
import { Capacitor } from '@capacitor/core';
import { CurrencyFormat } from '../helper/CurrencyFormat';
import moment from 'moment';

const MyNavbar = () => {
  const { state, dispatch } = useContext(AppContext);
  const [showModalHowToRedeem, setShowModalHowToRedeem] = useState(false);

  const location = useLocation();

  const history = useHistory();

  const logout = async () => {
    if (state.session_id) {
      await Connection({
        url: 'auth/logout',
        method: 'POST',
        headers: { session_id: state.session_id },
      });
    }

    window.localStorage.clear();

    if (Capacitor.isNativePlatform()) {
      history.push('/');
      await dispatch({ type: 'SET_LOGOUT' });
    } else {
      window.location = 'https://giftano.com';
    }
  };

  if (!state.ecard || !state.whitelabel) return '';

  // eslint-disable-next-line eqeqeq
  const hideBalance = state.ecard['whitelabel-theme'].settings['hide-balance-topup-on-navigation']
    ? state.ecard['whitelabel-theme'].settings['hide-balance-topup-on-navigation'].value === '1'
      ? true
      : false
    : false;

  // eslint-disable-next-line eqeqeq
  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  const expiredState = state.ecard && state.ecard.status === 'expired';

  let totalVouchers = state.ecard.transactions.reduce(
    (total, t) =>
      (total += t.vouchers.filter(
        (vc) => !moment(`${vc['expiry-date']} 23:59:59`).isSameOrBefore(moment())
      ).length),
    0
  );

  let maxTopup = 1;
  if (state.ecard && state.ecard.company && state.ecard.company.id) {
    maxTopup = 2;
  }

  return (
    <div
      className={`${
        state.ecard && state.ecard.is_gift_box == 1 ? 'gift-box-nav' : 'gift-card-nav'
      } redemption-navbar`}
    >
      <Navbar className='my-navbar' variant='light' expand='lg'>
        <Container>
          <Navbar.Brand className='text-white'>
            <Link to='/getstarted'>
              <img
                src={
                  state.whitelabel.settings['giftano-card-redemption-site-logo']
                    ? state.whitelabel.settings['giftano-card-redemption-site-logo'].value
                    : ''
                }
                className='d-inline-block align-top'
                alt={state.whitelabel.name}
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='basic-navbar-nav'>
            <BiMenu size='27px' color='var(--primary)' />
          </Navbar.Toggle>
          <div className='mobile-balance d-xs-block d-lg-none'>
            <Button
              variant={'outline-primary'}
              className={
                'btn-how-it-work' + (location.pathname === '/how-to-redeem' ? ' active' : '')
              }
              onClick={() => setShowModalHowToRedeem(true)}
            >
              How it works
            </Button>
            {!(hidePrice || hideBalance) && (
              <>
                <div className={`'nav-balance mx-2' + ${expiredState && 'd-none'}  `}>
                  <div className='nav-balance-title'>
                    <CurrencyFormat value={state.ecard.balance} locale={state.ecard.country} />
                  </div>
                </div>
                {state.strapiData.allow_topup &&
                  state.ecard.status === 'valid' &&
                  state.ecard.country === 'SG' &&
                  isMembershipActive(state.ecard, state.strapiData) &&
                  !(state.ecard.total_topup && state.ecard.total_topup >= maxTopup) && (
                    <Button
                      variant='white'
                      size='sm'
                      className='btn-topup-mobile ml-2'
                      onClick={() => {
                        if (!state.ecard.total_topup || state.ecard.total_topup < maxTopup) {
                          dispatch({ type: 'SHOW_TOPUP' });
                        } else {
                          toast.warning('You have exceeding maximum top-up limit.', {
                            autoClose: false,
                          });
                        }
                      }}
                    >
                      <strong>+</strong> Credit
                    </Button>
                  )}
              </>
            )}
          </div>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me'>
              <div className='navbar__left'>
                <Nav.Link
                  as={Link}
                  to={'/getstarted'}
                  href={'/getstarted'}
                  alt='Home'
                  title='Home'
                  className={
                    'font-weight-bold navbar-btn ' +
                    (location.pathname === '/getstarted' ? ' active' : '')
                  }
                >
                  <AiOutlineHome size='20px' style={{ marginBottom: '2px', marginRight: '16px' }} />

                  <span className='navbar-btn__text'>Home</span>
                </Nav.Link>
                {state.ecard.is_gift_box == 0 && (
                  <Nav.Link
                    disabled={expiredState}
                    as={Link}
                    to='/search'
                    href={'/search'}
                    alt='Search'
                    title='Search'
                    className={
                      'font-weight-bold navbar-btn ' +
                      (location.pathname === '/search' ? ' active' : '')
                    }
                  >
                    <BsSearch size='20px' style={{ marginBottom: '2px', marginRight: '16px' }} />
                    <span className='navbar-btn__text'>Search</span>
                  </Nav.Link>
                )}
                <Nav.Link
                  as={Link}
                  to='/mygifts'
                  href={'/mygifts'}
                  alt='My Gifts'
                  title='My Gifts'
                  className={
                    'font-weight-bold navbar-btn ' +
                    (location.pathname === '/mygifts' ? ' active' : '')
                  }
                >
                  <FiGift size='20px' style={{ marginBottom: '2px', marginRight: '16px' }} />
                  {totalVouchers > 0 ? <span className='gift-counter'>{totalVouchers}</span> : ``}
                  <span className='navbar-btn__text'>My Gifts</span>
                </Nav.Link>
                {state.ecard?.country === 'SG' && state.strapiData?.enable_perks && (
                  <Nav.Link
                    as={Link}
                    to='/perks'
                    href={'/perks'}
                    alt='Perks'
                    title='Perks'
                    className={
                      'font-weight-bold navbar-btn ' +
                      (location.pathname === '/perks' ? ' active' : '')
                    }
                  >
                    <FiAward size='20px' style={{ marginBottom: '2px', marginRight: '16px' }} />
                    <span className='navbar-btn__text'>Perks</span>
                  </Nav.Link>
                )}
                {isMembershipActive(state.ecard, state.strapiData) && (
                  <Nav.Link
                    as={Link}
                    to='/account'
                    href={'/account'}
                    alt='Account'
                    title='Account'
                    className={
                      'font-weight-bold navbar-btn' +
                      (location.pathname === '/account' ? ' active' : '')
                    }
                  >
                    <FiUser size='20px' style={{ marginBottom: '2px', marginRight: '16px' }} />
                    <span className='navbar-btn__text'>Account</span>
                  </Nav.Link>
                )}
                <Nav.Link
                  // onClick={() => dispatch({ type: 'SHOW_HELP' })}
                  as={Link}
                  to='/faqs'
                  href={'/faqs'}
                  alt='Need Help?'
                  title='Need Help?'
                  className={
                    'font-weight-bold navbar-btn' + (location.pathname === '/faqs' ? ' active' : '')
                  }
                >
                  <BiHelpCircle size='20px' style={{ marginBottom: '2px', marginRight: '16px' }} />
                  <span className='navbar-btn__text'>FAQ</span>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to='#'
                  alt='Logout'
                  onClick={logout}
                  title='Logout'
                  className='font-weight-bold navbar-btn d-md-none'
                >
                  <RiLogoutBoxRLine
                    size='20px'
                    style={{ marginBottom: '2px', marginRight: '16px' }}
                  />
                  <span className='navbar-btn__text'>Sign Out</span>
                </Nav.Link>
              </div>
              <div className='navbar__right'>
                {!(hidePrice || hideBalance) && (
                  <>
                    <div className={`nav-balance mx-4 ${expiredState && 'd-none'}`}>
                      <div className='nav-balance-title'>
                        Balance
                        <span className='nav-balance-count' style={{ textTransform: 'none' }}>
                          <CurrencyFormat
                            value={state.ecard.balance}
                            locale={state.ecard.country}
                          />
                        </span>
                      </div>
                    </div>
                    {state.strapiData.allow_topup &&
                      state.ecard.status === 'valid' &&
                      state.ecard.country === 'SG' &&
                      isMembershipActive(state.ecard, state.strapiData) &&
                      !(state.ecard.total_topup && state.ecard.total_topup >= maxTopup) && (
                        <Button
                          variant='white'
                          size='sm'
                          className='btn-topup mr-2'
                          onClick={() => {
                            if (!state.ecard.total_topup || state.ecard.total_topup < maxTopup) {
                              dispatch({ type: 'SHOW_TOPUP' });
                            } else {
                              toast.warning('You have exceeding maximum top-up limit.', {
                                autoClose: false,
                              });
                            }
                          }}
                        >
                          <strong>+</strong> Credit
                        </Button>
                      )}
                  </>
                )}
                {state.ecard.is_gift_box == 1 && (
                  <>
                    <ModalHowToRedeem
                      show={showModalHowToRedeem}
                      toggle={() => setShowModalHowToRedeem(false)}
                    />
                    <Button
                      variant={'outline-primary'}
                      className={
                        'btn-how-it-work' +
                        (location.pathname === '/how-to-redeem' ? ' active' : '')
                      }
                      onClick={() => setShowModalHowToRedeem(true)}
                    >
                      How it works
                    </Button>
                  </>
                )}
                <Nav.Link
                  as={Link}
                  to='#'
                  alt='Logout'
                  onClick={logout}
                  title='Logout'
                  className='text-white'
                >
                  <span className='mr-1'>
                    <RiLogoutBoxRLine size='20px' style={{ marginBottom: '0' }} />
                  </span>
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MyNavbar;
