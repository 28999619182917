import React, { useContext, useMemo } from 'react';
import { AppContext } from '../../../states';

const HowGiftWork = ({ gift }) => {
  const { state } = useContext(AppContext);

  const howItWork = useMemo(() => {
    let result = '';
    const howSlug = gift && gift.is_physical ? 'physical' : 'default';

    state.strapiData.how_it_work
      .filter((how) => how.slug === howSlug)
      .forEach((how) => {
        result = how.text;
      });
    return result;
  }, [state.strapiData, gift]);

  return howItWork ? (
    <div className='merchant-how-it-work'>
      <h4 className='desc font-weight-bold'>How it works</h4>
      <p>{howItWork}</p>
    </div>
  ) : (
    ''
  );
};

export default HowGiftWork;
