import React, { useContext } from 'react';
import { AiOutlineGift, AiOutlineHome, AiOutlineInfoCircle, AiOutlineSearch } from 'react-icons/ai';
import { FiAward, FiUser } from 'react-icons/fi';
import { NavLink, useLocation } from 'react-router-dom';
import { isMembershipActive } from '../helper';
import { AppContext } from '../states';

const TabBarBottom = () => {
  const { state } = useContext(AppContext);
  const location = useLocation();

  let totalVouchers =
    state.ecard && state.ecard.transactions
      ? state.ecard.transactions.reduce((total, t) => (total += t.vouchers.length), 0)
      : 0;

  return (
    <div className='tab-bar-bottom'>
      <div className='d-flex justify-content-between'>
        <NavLink
          to={'/getstarted'}
          className={'tab-bar-button ' + (location.pathname === '/getstarted' ? 'active' : '')}
        >
          <AiOutlineHome />
          <div className='label'>Home</div>
        </NavLink>
        {state.ecard.is_gift_box === 0 && (
          <NavLink
            to={'/search'}
            className={
              state.ecard && state.ecard.status === 'expired'
                ? 'd-none '
                : 'tab-bar-button ' + (location.pathname === '/search' ? 'active' : '')
            }
          >
            <AiOutlineSearch />
            <div className='label'>Search</div>
          </NavLink>
        )}
        <NavLink
          to={'/mygifts'}
          className={'tab-bar-button ' + (location.pathname === '/mygifts' ? 'active' : '')}
        >
          <AiOutlineGift />
          {totalVouchers > 0 ? <span className='gift-counter'>{totalVouchers}</span> : ``}
          <div className='label'>My Gifts</div>
        </NavLink>
        {state.ecard?.country === 'SG' && state.strapiData?.enable_perks && (
          <NavLink
            to={'/perks'}
            className={'tab-bar-button ' + (location.pathname === '/perks' ? 'active' : '')}
          >
            <FiAward />

            <div className='label'>Perks</div>
          </NavLink>
        )}
        {isMembershipActive(state.ecard, state.strapiData) && (
          <NavLink
            to={'/account'}
            className={'tab-bar-button ' + (location.pathname === '/account' ? 'active' : '')}
          >
            <FiUser />
            <div className='label'>Account</div>
          </NavLink>
        )}
        <NavLink
          to={'/info'}
          className={'tab-bar-button ' + (location.pathname === '/info' ? 'active' : '')}
        >
          <AiOutlineInfoCircle />
          <div className='label'>Info</div>
        </NavLink>
      </div>
      <div className='d-flex justify-content-center mt-4'>
        <div className='indicator'></div>
      </div>
    </div>
  );
};
export default TabBarBottom;
