/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../states';
import MyNavbar from '../../components/MyNavbar';
import MyFooter from '../../components/MyFooter';
import { Helmet } from 'react-helmet';
import { AppConfig } from '../../config';
import LoadECard from '../../components/LoadECard';
import GetInspired from './components/GetInspired';
import Hero from './components/Hero';
import About from './components/About';
import GiftBoxAnimation from './components/GiftboxAnimation';
import { Animated } from 'react-animated-css';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

import HowItWork from './components/HowItWork';

import TabBarBottom from '../../components/TabBarBottom';
import { loadCatalog } from '../../services/GiftCatalogServices';
import ModalDisableGiftanoApps from '../../components/ModalDisableGiftanoApps';

const GetStarted = () => {
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (state.ecard && state.giftsStatus === 'pending') {
      loadCatalog({ state, dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.giftsStatus, state.ecard]);

  if (!state.whitelabel || !state.gcardinfo) return <Redirect to='/' />;

  const hidePrice = state.ecard && state.ecard.hide_price == 1 ? true : false;
  // console.log('state', state);

  return (
    <div
      className={`${
        state.ecard && state.ecard.is_gift_box == 1 ? 'gift-box' : 'gift-card'
      } page-getstarted`}
    >
      <ModalDisableGiftanoApps />
      <Helmet>
        <title>{AppConfig.title}</title>
      </Helmet>
      <LoadECard>
        {state.ecard && state.ecard.is_gift_box == 1 && state.getStartedPage.giftBoxAnimation && (
          <Animated
            animationIn='fadeIn'
            animationOut='fadeOut'
            isVisible={state.getStartedPage.giftBoxAnimation}
          >
            <GiftBoxAnimation
              onComplete={() => {
                dispatch({
                  type: 'GETSTARTED_PAGE',
                  payload: { giftBoxAnimation: false },
                });
              }}
            />
          </Animated>
        )}
        {state.ecard &&
          (state.ecard.is_gift_box == 0 || !state.getStartedPage.giftBoxAnimation) && (
            <React.Fragment>
              <MyNavbar />

              {hidePrice && parseFloat(state.ecard.total_usage) > 0 && (
                <Container>
                  <Row className='row-alert'>
                    <Col>
                      <Alert variant='success'>
                        <FaCheckCircle className='mr-2' /> <strong>Fully redeemed:</strong> Looks
                        like you have fully redeemed all your gifts.
                      </Alert>
                    </Col>
                  </Row>
                </Container>
              )}

              <Hero />
              {state.ecard.is_gift_box == '1' && <GetInspired />}
              {state.ecard.is_gift_box == '0' && <HowItWork />}

              <About />

              <MyFooter />
              <TabBarBottom />
            </React.Fragment>
          )}
      </LoadECard>
    </div>
  );
};

export default GetStarted;
