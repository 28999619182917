/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { strapiImageUrl } from '../../../helper';
// import { AppContext } from '../../../states';

const HowItWork = () => {
  //   const { state } = useContext(AppContext);
  //   const { about_giftano_card, about_gift_box } = state.strapiData;

  return (
    <div className='how-it-work '>
      {/*d-none d-md-block */}
      <Container>
        <div className='bordered'>
          <h3 className='text-center'>How Does it Work</h3>
          <p className='text-center how-it-work-mobileinfo d-md-none'>
            With these simple steps to start using your Giftano Gift Card.
          </p>
          <Row>
            <Col sm={3} className='text-center relative'>
              <img
                src='https://giftano.imgix.net/assets/redemption/getstarted-v2.png?auto=compress&auto=enhance&auto=format'
                alt='choose'
              />
              <img
                src='https://giftano.imgix.net/assets/redemption/arrow.png?auto=compress&auto=enhance&auto=format'
                alt='arrow indicator'
                className='arrow-indicator'
              />
              <p>Click the button to Get Started.</p>
            </Col>
            <Col sm={3} className='text-center relative'>
              <img
                src='https://giftano.imgix.net/assets/redemption/choose-v2.png?auto=compress&auto=enhance&auto=format'
                alt='choose'
              />
              <img
                src='https://giftano.imgix.net/assets/redemption/arrow.png?auto=compress&auto=enhance&auto=format'
                alt='arrow indicator'
                className='arrow-indicator'
              />
              <p>Choose a gift out of the many choices offered.</p>
            </Col>
            <Col sm={3} className='text-center relative'>
              <img
                src='https://giftano.imgix.net/assets/redemption/quantity-v2.png?auto=compress&auto=enhance&auto=format'
                alt='choose'
              />
              <img
                src='https://giftano.imgix.net/assets/redemption/arrow.png?auto=compress&auto=enhance&auto=format'
                alt='arrow indicator'
                className='arrow-indicator'
              />
              <p>Select the quantity and press buy button to purchase.</p>
            </Col>
            <Col sm={3} className='text-center relative'>
              <img
                src='https://giftano.imgix.net/assets/redemption/redeem-gift-v2.png?auto=compress&auto=enhance&auto=format'
                alt='choose'
                // style={{ marginTop: '-20px', marginBottom: '20px' }}
              />
              <p>Slide Redeem to enjoy your gift!</p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default HowItWork;
