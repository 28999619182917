import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Button, Col, Modal, ModalFooter, Row } from 'react-bootstrap';
import { FiCopy } from 'react-icons/fi';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { SHA1 } from 'crypto-js';
import { AppContext } from '../../../states';
import { FaPrint } from 'react-icons/fa';

const ModalShowCode = ({ onHide }) => {
  const { state, dispatch } = useContext(AppContext);
  const { showCode, dataConfirm, dataCode } = state.myGiftPage;
  const [showConfirmPrint, setShowConfirmPrint] = useState(false);

  const handleCopy = (dataCode) => {
    navigator.clipboard
      .writeText(dataCode.code)
      .then(() => {
        toast('Your Code copied to clipboard', { type: 'success' });
      })
      .catch((err) => {
        toast('Error in copying text: ', err, { type: 'warning' });
      });
  };

  return (
    <Modal
      className='modal-showcode'
      show={showCode}
      onHide={() => {
        dispatch({
          type: 'MYGIFT_PAGE',
          payload: {
            showCode: false,
          },
        });
        onHide();
      }}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>&nbsp;</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* <BarCode margin={12} value={dataCode.code} format={dataCode.barcode_type ? dataCode.barcode_type : 'CODE128'} /> */}
        <Row>
          <Col md='12' className='text-center'>
            {
              // eslint-disable-next-line eqeqeq
              dataConfirm.is_physical == '1' && dataConfirm.file ? (
                <img width='200' src={dataConfirm.file} alt='Product' />
              ) : dataConfirm.merchant &&
                dataConfirm.merchant['e-voucher-bg'] ? (
                <giftano-evoucher
                  vcImage={dataConfirm.file}
                  logo={dataConfirm.merchant ? dataConfirm.merchant.logo : ''}
                  name={dataConfirm.value}
                  expDate={
                    dataConfirm['expiry-date']
                      ? moment(dataConfirm['expiry-date']).format('DD-MMM-YYYY')
                      : ''
                  }
                  bgImage={dataConfirm.merchant['e-voucher-bg']}
                  style={{
                    boxShadow: '2px 2px 5px',
                    display: 'Block',
                    marginBottom: '16px',
                  }}
                />
              ) : dataConfirm.merchant && dataConfirm.merchant.logo ? (
                <img width='100' src={dataConfirm.merchant.logo} alt='Logo' />
              ) : null
            }
          </Col>
          <Col md='12'>
            <h4 className='font-weight-bold text-left text-md-center mt-3'>
              {dataConfirm.value}
            </h4>
          </Col>
        </Row>

        {dataCode.code &&
        (dataCode.code.substr(0, 7) === 'http://' ||
          dataCode.code.substr(0, 8) === 'https://') ? (
          <Row className='show-code-url'>
            <Col md='12'>
              <p>
                A new tab with your voucher code will be opened in a moment.
              </p>
              <p>
                If new tab doesn't opened in a few second,{' '}
                <a
                  href={dataCode.code}
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  click here
                </a>
                .
              </p>
              <p>OR</p>
              <p>You can scan QR Code below with your mobile device:</p>
              <QRCode value={dataCode.code} />
              <div className='mt-2 text-center'>
                <Button
                  variant='primary'
                  onClick={() => {
                    dispatch({
                      type: 'MYGIFT_PAGE',
                      payload: {
                        showCode: false,
                      },
                    });
                    onHide();
                  }}
                  className='modal-footer-submit mt-4'
                >
                  Done
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className='showcode-text'>
            <Col md='6'>
              <div className='showcode-additional'>
                <h4>
                  How to use this voucher?
                  <br />
                </h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: dataCode.how_to_use_code,
                  }}
                ></p>
              </div>
              <Row>
                <Col
                  md='12'
                  className='btn-done d-block d-sm-none d-md-none d-lg-none'
                >
                  <Button
                    variant='primary'
                    onClick={() => {
                      dispatch({
                        type: 'MYGIFT_PAGE',
                        payload: {
                          showCode: false,
                        },
                      });
                      onHide();
                    }}
                    className='modal-footer-submit w-100 '
                  >
                    Done
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md='6'>
              <div className='showcode-detail'>
                <img
                  className='showcode-barcode'
                  src={
                    'https://giftano.com/gtools/barcode/?text=' +
                    dataCode.code +
                    '&type=' +
                    dataCode.barcode_type +
                    '&height=60'
                  }
                  alt='barcode'
                />
                <span>{dataCode.code}</span>
                {dataCode.pin && <span>PIN : {dataCode.pin}</span>}
              </div>
              <Row>
                <Col md='6' xs='6'>
                  <div className='showcode-print'>
                    <Button onClick={() => handleCopy(dataCode)}>
                      <FiCopy size='20px' className='mr-1' />
                      Copy Code
                    </Button>
                  </div>
                </Col>
                {state.gcardinfo &&
                  state.gcardinfo.company &&
                  state.gcardinfo.company.id &&
                  // skip for shensiong
                  state.gcardinfo.company.id !== 'MCX3V9DM3J' &&
                  state.gcardinfo.company.id !== 'MCGJV49QNT' && (
                    <Col md='6' xs='6'>
                      <div className='showcode-print'>
                        <Button
                          onClick={() => {
                            setShowConfirmPrint(true);
                          }}
                        >
                          <FaPrint size='20px' className='mr-1' /> Print
                        </Button>
                        <Modal
                          show={showConfirmPrint}
                          onHide={() => setShowConfirmPrint(false)}
                          className={'modal-confirm-print'}
                          backdrop={'static'}
                          backdropClassName={'modal-confirm-print-backdrop'}
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title>
                              You do not need to print this digital voucher
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p>
                              Just present it on your mobile device when making
                              payment at the merchant.
                              <br />
                              For online store/merchant, please read how to use
                              instruction.
                            </p>
                          </Modal.Body>
                          <ModalFooter style={{ justifyContent: 'right' }}>
                            <Button
                              variant={'primary'}
                              onClick={() => setShowConfirmPrint(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant={'light'}
                              onClick={() => {
                                let print_url =
                                  'https://giftano.com/print-gift-selfprint_v2?hgl=0&f=' +
                                  SHA1(dataConfirm.receiptnumber) +
                                  '&rgp=&s=&su=dd5c07036f2975ff4bce568b6511d3bc&v=' +
                                  SHA1(dataConfirm.code) +
                                  '&co=' +
                                  state.gcardinfo.company.id;
                                window.open(print_url);
                                setShowConfirmPrint(false);
                              }}
                            >
                              Print
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </Col>
                  )}
                <Col
                  md='12'
                  className='btn-done d-none d-sm-block d-md-block d-lg-block'
                >
                  <Button
                    variant='primary'
                    onClick={() => {
                      dispatch({
                        type: 'MYGIFT_PAGE',
                        payload: {
                          showCode: false,
                        },
                      });
                      onHide();
                    }}
                    className='modal-footer-submit w-100 '
                  >
                    Done
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalShowCode;
