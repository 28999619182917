/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorMessages } from '../components/ErrorMessages';
import { Connection, PostPublic } from '../services/Connection';
import { AppContext } from '../states';
import PouchDB from 'pouchdb';
import { Button, Card } from 'react-bootstrap';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import Axios from 'axios';

// const params = new URLSearchParams(window.location.search);

const isProduction = process.env['REACT_APP_BUILD_MODE'] === 'PRODUCTION' ? true : false;

const Loading = () => {
  const { state, dispatch } = useContext(AppContext);
  const { gcard_number, gcard_pin } = useParams();
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const [loginUrl, setLoginUrl] = useState('');
  const params = new URLSearchParams(window.location.search);

  let forceShowAnimation = true;
  // if (params.has('animation')) {
  //   forceShowAnimation = params.get('animation') === 'show';
  // }

  useEffect(() => {
    const loadWhitelabel = async () => {
      try {
        let resp = await PostPublic('whitelabel-theme/', {
          slug: 'giftano',
        });
        if (!resp.error && resp.status === 1) {
          // console.log('set global whitelabel');
          dispatch({ type: 'SET_WHITELABEL', data: resp.data });
          history.push('/passcode');
        } else {
          toast.error(ErrorMessages.error_get_whitelabel);
          // history.push('/passcode');
          setShowError(true);
        }
      } catch (err) {
        toast.error(ErrorMessages.error_network);
        // RecordLog('Error load whitelabel on loading fn loadWhitelabel - ' + err.message);
        setShowError(true);
      }
    };
    const loadGCardInfo = async () => {
      try {
        // remove old local storage
        // localStorage.clear();
        localStorage.removeItem('invitedMember');
        localStorage.removeItem('redemption-data-key');
        localStorage.removeItem('redemption-data-whitelabel');
        localStorage.removeItem('redemption-data-gcardinfo');
        localStorage.removeItem('redemption-data-otp-code');
        let resCardInfo = await PostPublic('gcard/info/', {
          ecard: gcard_number,
        });
        if (resCardInfo.status === 1) {
          let login_required = false;
          const cardNeedMemberAccount = resCardInfo.data.need_member_account;
          const strapiNeedMemberAccount = state.strapiData.member_account_required;

          // eslint-disable-next-line eqeqeq
          if (cardNeedMemberAccount == 9) {
            // use strapi
            login_required = strapiNeedMemberAccount;
          } else {
            // eslint-disable-next-line eqeqeq
            login_required = cardNeedMemberAccount == 1 ? true : false;
          }

          if (login_required && !state.session_id) {
            //no session id
            const redirect_auth = process.env.REACT_APP_REDIRECT_AUTH;
            let locationWindow =
              window.location.protocol +
              '//' +
              window.location.host +
              window.location.pathname +
              '?';
            if (Capacitor.isNativePlatform()) {
              locationWindow = locationWindow
                .replace(
                  'capacitor://',
                  isProduction
                    ? 'com.giftano.redemptionapp://'
                    : 'com.giftano.redemptionappsandbox://'
                )
                .replace(':////', '://');
            }
            const redirect_url =
              redirect_auth +
              '?redirect=' +
              btoa(locationWindow + '|member') +
              (state.ecard && state.ecard.is_gift_box == 1 ? '&mode=6' : '&mode=3') +
              '&rb=' +
              btoa('https://giftano.com');
            if (Capacitor.isNativePlatform()) {
              // window.open(redirect_url);
              // alert('open : ' + redirect_url);
              setLoginUrl(redirect_url);
              Browser.open({ url: redirect_url });
              // localStorage.setItem('redirect_after_login', location.pathname);
              return;
            } else {
              window.location = redirect_url;
            }
            return;
          }

          // destroy prev search gifts result
          try {
            let db = new PouchDB('redemption-gift-result-' + resCardInfo.data.company.id);
            await db.destroy();
          } catch (err) {}

          dispatch({ type: 'SET_GCARD_INFO', data: resCardInfo.data });

          const sendOTP = async (withAnimation = false) => {
            let method;
            if (resCardInfo.data.required_otp_sms == 1) {
              method = 'sms';
              if (!resCardInfo.data.phonenumber) {
                toast.error('Send SMS OTP failed, because invalid phone number.', {
                  autoClose: false,
                });
                history.push('/passcode' + (withAnimation ? '?animation=show' : ''));
                return;
              }
            } else {
              method = 'email';
              if (!resCardInfo.data.email) {
                toast.error('Send email OTP failed, because invalid email address.', {
                  autoClose: false,
                });
                history.push('/passcode' + (withAnimation ? '?animation=show' : ''));
                return;
              }
            }
            try {
              let config = {
                url:
                  method === 'sms'
                    ? process.env.REACT_APP_PATH_SMS + '/otp'
                    : process.env.REACT_APP_PATH_EMAIL + '/otp',
                method: 'POST',
                data:
                  method === 'sms'
                    ? {
                        action: 'send',
                        to: resCardInfo.data.phonenumber,
                      }
                    : {
                        action: 'send',
                        email: resCardInfo.data.email,
                      },
              };
              const resultOTP = await Connection(config);
              // console.log('response otp', resultOTP);
              if (resultOTP.code == 200) {
                history.push('/passcode' + (withAnimation ? '?animation=show' : ''));
              } else {
                toast.warning(resultOTP.error || ErrorMessages.error_otp, {
                  autoClose: false,
                });
                history.push('/passcode' + (withAnimation ? '?animation=show' : ''));
              }
            } catch (err) {
              console.log('error', err);
            }
          };

          //cek pin available
          if (gcard_pin) {
            let resVerify;
            try {
              resVerify = await PostPublic('gcard/verify_pin/', {
                ecard: resCardInfo.data.number,
                pin: gcard_pin,
              });
            } catch (err) {
              toast('Failed to verify url, please enter your passcode manually!', {
                type: 'error',
              });
              history.push('/passcode');
              return;
            }
            // console.log('res verify', resVerify)
            if (resVerify.status === 1) {
              dispatch({ type: 'SET_KEY', data: resVerify.data.key });

              //add activate
              if (resCardInfo.data.status === 'inactive') {
                let resActivate = await PostPublic('gcard/activate/', {
                  ecard: resCardInfo.data.number,
                  key: resVerify.data.key,
                });
                if (resActivate.status == 1) {
                  dispatch({
                    type: 'GETSTARTED_PAGE',
                    payload: { giftBoxAnimation: true },
                  });

                  if (
                    !resCardInfo.data.memberid &&
                    (resCardInfo.data.required_otp_sms == 1 ||
                      resCardInfo.data.required_otp_email == 1)
                  ) {
                    // send otp with animation
                    await sendOTP(true);
                  } else {
                    // if has query ?sa=1, then skip animation
                    if (params.get('sa') == '1') {
                      history.push('/getstarted');
                    } else {
                      history.push('/start');
                    }
                  }
                } else {
                  toast.error(resActivate.message || ErrorMessages.error_activate_card);
                }
              } else {
                if (
                  !resCardInfo.data.memberid &&
                  (resCardInfo.data.required_otp_sms == 1 ||
                    resCardInfo.data.required_otp_email == 1)
                ) {
                  if (forceShowAnimation) {
                    dispatch({
                      type: 'GETSTARTED_PAGE',
                      payload: { giftBoxAnimation: true },
                    });
                    sendOTP(true);
                  } else {
                    sendOTP();
                  }
                } else {
                  if (forceShowAnimation && params.get('sa') != '1') {
                    dispatch({
                      type: 'GETSTARTED_PAGE',
                      payload: { giftBoxAnimation: true },
                    });
                    history.push('/start');
                  } else {
                    history.push('/getstarted');
                  }
                }
              }
            } else {
              toast.error(resVerify.message || ErrorMessages.error_redeem_url);
              history.push('/passcode');
              dispatch({ type: 'SET_KEY', data: null });
            }
          } else {
            //else
            history.push('/passcode');
            dispatch({ type: 'SET_KEY', data: null });
          }
        } else {
          // toast.error(ErrorMessages.error_giftano_card_not_found);
          toast.error(resCardInfo.message || ErrorMessages.error_redeem_url);
          dispatch({ type: 'SET_GCARD_INFO', data: null });
          dispatch({ type: 'SET_KEY', data: null });
          loadWhitelabel();
          history.push('/passcode');
        }
      } catch (err) {
        toast.error(ErrorMessages.error_network);
        // RecordLog('Error load gcard info on loading fn loadGCardInfo - ' + err.message, gcard_number )
        setShowError(true);
      }
    };
    if (gcard_number) {
      //load gcard number only if strapi loaded
      if (state.strapiData.id) {
        loadGCardInfo();
      }
    } else if (state.gcardinfo && state.key) {
      history.push('/getstarted');
    } else {
      //load whitelabel
      loadWhitelabel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gcard_number, gcard_pin, state.strapiData.id, state.session_id]);
  useEffect(() => {
    // const host = window.location.hostname;
    // let url = '';
    // if(host === 'cards.giftano.com'){
    //     url = 'https://cards.giftano.com/ipinfo';
    // }else{
    //     url = 'https://cards-sandbox.giftano.io/ipinfo';
    // }
    let url = 'https://giftano-tools.pages.dev/api/ipinfo';
    Axios(url)
      .then((respIp) => {
        if (respIp.status === 200) {
          //    alert(respIp.data);
          dispatch({ type: 'SET_IPADDRESS', data: respIp.data });
        }
      })
      .catch((err) => {});
  }, [dispatch]);
  // console.log(state);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        fontSize: showError ? '18px' : '28px',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'gray',
        }}
      >
        {showError ? (
          <Card style={{ background: '#DAF3F1' }} text='dark'>
            <Card.Body>
              {state.strapiData && state.strapiData.error_message_before_start ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: state.strapiData.error_message_before_start,
                  }}
                ></div>
              ) : (
                <>
                  <h2>Unable to open Giftano Card?</h2>
                  Please try to following steps:
                  <ul>
                    <li>Make sure your are not using VPN</li>
                    <li>Please try to access it using another browser or on another device</li>
                  </ul>
                </>
              )}
            </Card.Body>
          </Card>
        ) : loginUrl ? (
          <>
            <Card style={{ width: '350px' }}>
              <Card.Body>
                <h3>Authenticating</h3>
                <p style={{ fontSize: '18px' }}>
                  Please log in. If the authentication window doesn’t appear, click the login button
                  below.
                </p>
                <Button
                  onClick={() => {
                    if (Capacitor.isNativePlatform()) {
                      Browser.open({ url: loginUrl });
                    } else {
                      window.location = loginUrl;
                    }
                  }}
                  block
                >
                  LOGIN
                </Button>
                <Button
                  block
                  variant={'transparent'}
                  className='mt-2'
                  onClick={async () => {
                    localStorage.clear();
                    await dispatch({ type: 'SET_LOGOUT' });
                    history.push('/');
                  }}
                >
                  BACK
                </Button>
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <FaSpinner className='icon-spin mr-2' /> Loading...
          </>
        )}
      </div>
    </div>
  );
};

export default Loading;
