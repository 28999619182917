import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../../states';
import { CurrencyFormat } from '../../../helper/CurrencyFormat';

const MyCart = () => {
  const { state } = useContext(AppContext);
  const { gifts } = state.merchantPage;

  const grand_total = gifts
    .map(
      (gift) =>
        gift.quantity * parseFloat(gift.price) +
        (gift.quantity ? parseFloat(gift.primary_extrafirst) : 0)
    )
    .reduce((total, price) => total + price);

  return (
    <>
      <Card className='card-total card-has-cart'>
        <Card.Body>
          {gifts
            .filter((i) => i.quantity > 0)
            .map((item, idx) => (
              <Row key={'sel_item_' + idx} className='my-2 cart-total__listing'>
                <Col xs='10' md='9'>
                  <p className='listing-name' style={{ margin: '0' }}>
                    {item.name}
                  </p>
                  {/* <p
                    style={{
                      margin: '0',
                      color: '#E3A25E',
                    }}
                  >
                    <CurrencySymbol/>{item['price']}
                  </p> */}
                </Col>
                <Col xs='2' md='3' className='text-right listing-counter'>
                  x {item.quantity}
                </Col>
                {/* <Col md='3' className='text-right d-none d-md-block'>
                  <CurrencySymbol/>
                  {(item.quantity * item.price).toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col> */}
              </Row>
            ))}
        </Card.Body>
      </Card>

      <Card className='card-total card-has-order'>
        <Card.Body>
          <div className='d-flex align-items-center card-total__subtotal'>
            <h4 className='mr-auto cart__key'>Gifts Subtotal : </h4>
            <h4 className='card-total__nominal'>
              <CurrencyFormat value={grand_total} />
            </h4>
          </div>
        </Card.Body>
      </Card>

      <Card className='card-total card-has-balance'>
        <Card.Body>
          <div className='d-flex align-items-center card-total__balance'>
            <h4 className='mr-auto cart__key'>Giftano Card Balance</h4>
            <hr />
            <h4 className='card-total__nominal--small'>
              <CurrencyFormat value={state.ecard.balance} />
            </h4>
          </div>
          {grand_total > state.ecard.balance && (
            <div className='d-flex merchant__amount-topup'>
              <h4 className='mr-auto'>Amount to Top Up</h4>
              <h4 className='card-total__nominal--small'>
                <CurrencyFormat
                  value={grand_total - parseFloat(state.ecard.balance)}
                />
              </h4>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default MyCart;
